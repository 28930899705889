<template>
    <div>
        <div class="card" v-if="$canIf('users.index')">
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getUsers" >
                    <div class="row">
                        <div class="col-8 form-group">
                            <input type="text" class="form-control" id="input-source" name="word" placeholder="Procure por nome ou email" v-model="pesquisa">
                        </div>
                    
                        <div class="col-md-4">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Administradores</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('users.store')" :to="{ name: 'Admin.Create' }" class="btn btn-success btn-sm mr-2">Novo Usuário</router-link>
            </div>
        </div>
        <br>
        <div>
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id" :class="user.deleted_at ? 'alert alert-primary' : ''">
                        <td data-title="Id">{{user.id}}</td>
                        <td data-title="Nome">{{user.name}}</td>
                        <td data-title="Email">{{user.email}}</td>
                        <td v-if="user.id == 1"></td>
                        <td v-else-if="user.deleted_at == null" class="acoes">
                            <router-link :to="{ name: 'Admin.Edit', params: {user_id: user.id}}" class="m-1 btn btn-sm bg-info" title="Editar Usuário"><i class="fas fa-edit"></i></router-link>
                            <a v-if="$canIf('users.disabled')" title="Desativar Usuário" class="m-1 btn btn-sm btn-danger" @click.prevent="deactivateUser(user.id)"><i class="fas fa-trash-alt"></i></a>
                            <router-link v-if="$canIf('users.roleStore')" :to="{ name: 'Users.Roles', params: {user_id: user.id}}" class="m-1 btn btn-sm bg-primary" title="Gerenciar Cargos"><i class="fas fa-user-tag"></i></router-link>
                     
                        </td>
                        <td v-else class="acoes">
                            <a v-if="$canIf('users.enable') && user.deleted_at != null" title="Ativar Usuário" class="m-1 btn btn-sm bg-warning" @click.prevent="activateUser(user.id)"><i class="fas fa-plus"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
export default {
    created() {
        this.getUsers();
    },

    data: function(){
        return{
            users: [],
            pesquisa: ''
        }
    },
    methods: {

        getUsers() {
            this.$httpApi.post('users/admin/list', {word: this.pesquisa}, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.users = res.data.item
            }).catch(res => {
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        activateUser(id) {
            this.$httpApi.put('users/enable/'+id,'',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then(res => {
                this.getUsers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getUsers()
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        deactivateUser(id) {
            this.$httpApi.delete('users/'+id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then(res => {
                this.getUsers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getUsers()
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        deleteUser(id) {
            this.$httpApi.delete('users/destroy/'+id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then( res => {
                this.getUsers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getUsers()
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        clearSearch(){
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = "";
            })
            this.getUsers();
        }
        
    },
}
</script>

<style scoped>

.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;

    animation-name: modal;
    animation-duration: 700ms;

}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

@keyframes modal {
    from {
        top: 30%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
    }
}
    /* .hide {
        display: none;
    }

    @media all and (max-width: 825px) {
        td {
            display: block;
            text-align: right;
        }

        td:before {
            content: attr(data-title);
            position: absolute;
            left: 10px;
            display: block;
        }

        thead {
            display: none;
        }

        tbody tr {
            border: 1px solid #ccc;
        }
        
        .acoes {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    } */
</style>