<template>
    <div v-if="$canIf('trunk.index')">
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Troncos</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('trunk.store')" :to="{ name: 'Trunks.Create' }" class="btn btn-success btn-sm mr-2">Cadastrar Tronco</router-link>
            </div>
        </div>
        <br>
        <div>
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Servidor</th>
                        <th>Tipo de Conexão</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <tr v-for="trunk in trunks" :key="trunk.idTrunk" :class="trunk.deleted_at != null ? 'troncos alert alert-primary' : 'troncos'">

                        <td data-title="Id">{{trunk.idTrunk}}</td>
                        <td data-title="Nome">{{trunk.trunkName}}</td>
                        <td data-title="Servidor">{{trunk.trunkHost}}</td>
                        <td v-if="trunk.trunkRegister == 'Y'" data-title="Tipo de Conexão">Registro</td>
                        <td v-else data-title="Tipo de Conexão">TechPrefix</td>
                        <td class="acoes">

                            <router-link v-if="trunk.deleted_at == null" :to="{ name: 'RateCosts.Edit', params: {trunk_id: trunk.idTrunk}}" class="m-1 btn btn-sm bg-primary" title="Visualizar Tarifas"><i class="fas fa-dollar-sign"></i></router-link>
                            <router-link v-if="trunk.deleted_at == null" :to="{ name: 'Trunks.Edit', params: {trunk_id: trunk.idTrunk}}" class="m-1 btn btn-sm bg-info" title="Editar Tronco"><i class="fas fa-edit"></i></router-link>
                            <button v-if="trunk.deleted_at == null" title="Desativar Tronco" class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(trunk.idTrunk)"><i class="fas fa-trash-alt"></i></button>
                            <button v-if="trunk.deleted_at != null" title="Ativar Tronco" class="m-1 btn btn-sm bg-warning" @click.prevent="restoreTrunk(trunk.idTrunk)"><i class="fas fa-plus"></i></button>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

         <confirm-delete
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Tronco?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>

    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {

    components:{
        ConfirmDelete
    },

    created() {
        this.getTrunks();
    },

    data() {
        return {
            trunks: [],
            isConfirmDeleteModalVisible: false,
            trunk: "",
        }
    },

    methods: {
        getTrunks() {
            this.$httpApi.get('troncos',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.trunks = res.data.items;
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteTrunk(id) {
            this.$httpApi.delete('troncos/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getTrunks()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        restoreTrunk(id) {
            this.$httpApi.put('troncos/restore/'+id,'',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getTrunks()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        showConfirmDeleteModal(id) {
            this.trunk = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.trunk = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteTrunk(this.trunk);
            this.closeConfirmDeleteModal();
        },
    }

}
</script>

<style>
</style>