<template>
    <div class="card p-0 m-2" style="height: fit-content" v-if="$canIf('users.rolestore') && $canIf('users.roleremove')">
        <form action="" method="post" v-on:submit.prevent="attRoles" id="form-roles">
            <div class="card-header  pb-3">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5 class="m-0 pr-2 titulo" ><strong>{{user.name}}</strong></h5>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column">
                    <div class="form-group" v-for="role in roles" :key="role.name">

                        <label class="switch">
                            <input v-if="user.roles.includes(role.id)" checked type="checkbox" class="role" :id="role.id" :name="role.id">
                            <input v-else type="checkbox" :id="role.id" :name="role.id" class="role">
                            <span class="slider round"></span>
                        </label>

                        <label class="ml-2 mr-1 role-name" :for="role.name">
                            {{role.name}}
                        </label>
                        <p>{{role.description}}</p>
                        <!-- <label class="ml-2 mr-1 role-name" :for="role.name">
                            {{role.name}} 
                        </label> -->

                        <!-- <a @click.prevent="" style="cursor: default" href="#" class="red-tooltip" :title="role.description" data-toggle="tooltip" data-placement="right" ><i class="fa fa-question-circle" style="color: #777777;"></i></a> -->
                    
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <input type="submit" class="btn btn-success mr-2" value="Atualizar Cargos">
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
export default {

    created() {
        this.$httpApi.get('users/show/'+this.user_id, {
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.user.name = res.data.usuário;
            this.user.email = res.data.email;
            res.data.cargos.forEach(role => {
                this.user.roles.push(role.id)
            });
        })

        this.$httpApi.get('role', {
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.roles = res.data.items
        })
    },

    props: ['user_id'],

    data() {
        return {
            errors: {},
            user: {
                name: String,
                email: String,
                roles: [],
            },
            roles: [],
        }
    },

    methods: {
        attRoles() {
            var roles = document.querySelectorAll('input.role')
            var newRoles = [];
            roles.forEach(role => {
                if (role.checked) {
                    newRoles.push(role.id);
                }
            });
            this.$httpApi.post('users/addrole/'+this.user_id, newRoles, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                // this.$router.push({ name: 'Users.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        }
    }

}
</script>

<style scoped>
    .titulo{
        text-transform: capitalize;
        font-family:sans-serif
    }

    .role-name {
        font-size: 0.9rem;
        font-weight: bold;
    }

/* ////////////////////////////////////////////// */

    .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 17px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .3s;
        transition: .3s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .3s;
        transition: .3s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

/* ////////////////////////////////////////////// */
</style>