<template>
    <div>
        <div class="card" v-if="$canIf('reports.cdr')">
            <div class="card-header">
                <h4>Relatório por Operadora</h4>
                <br>
                <div class="pb-3">
                    <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getReport">
                        <div class="row">
                            <div class="col-4 form-group">
                                <label for="input-source">Origem</label>
                                <input type="text" class="form-control" id="input-source" name="src">
                            </div>
                            <div class="col-4 form-group">
                                <label for="input-destiny">Destino</label>
                                <input type="text" class="form-control" id="input-destiny" name="dst">
                            </div>
                            <div class="col-4 form-group">
                                <label for="input-disposition">Status da chamada</label>
                                <select name="disposition" id="input-disposition" class="form-control">
                                    <option value="">Selecione um status</option>
                                    <option value="ANSWERED">Atendida</option>
                                    <option value="NO ANSWER">Não Atendida</option>
                                    <option value="BUSY">Ocupada</option>
                                    <option value="CONGESTION">Congestionada</option>
                                    <option value="FAILED">Falhou</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3 form-group">
                                <label for="input-operadora">Operadora</label>
                                <select name="operadora" id="input-operadora" class="form-control" v-model="idCarrier" @change="getTroncos(idCarrier)">
                                    <option value="">Selecione uma operadora</option>
                                    <option v-for="operadora in operadoras" :key="operadora.idCarrier" :value="operadora.idCarrier">{{operadora.carrierName}}</option>
                                </select>
                            </div>

                             <div class="col-3 form-group">
                                <label for="input-tronco">Tronco</label>
                                <select name="tronco" id="input-tronco" class="form-control">
                                    <option value="">Selecione um tronco</option>
                                    <option v-for="tronco in troncos" :key="tronco.idTrunk" :value="tronco.idTrunk">{{tronco.trunkName}}</option>
                                </select>
                            </div>
                            
                            
                            <div class="col-3 form-group">
                                <label for="input-dateStart">Data Inicial</label>
                                <input type="date" class="form-control" name="date_start" id="input-dateStart" required>
                            </div>
                            <div class="col-3 form-group">
                                <label for="input-dateEnd">Data Final</label>
                                <input type="date" class="form-control" name="date_end" id="input-dateEnd" required>
                            </div>
                        </div>
                        <div class="d-flex align-self-end justify-content-center align-items-center mr-4">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                            <button class="btn bg-primary btn-sm" @click.prevent="exportar">Exportar</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-body" v-if="report.length">
                <div class="row">
                
                <div class="card">
                    <div class="card-header">
                    <h5>Resultados</h5>
                </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Chamadas</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="customer in customersResults" :key="customer.idCustomer" :value="customer.idCustomer">
                                        <td>{{ customer.customerName }}</td>
                                        <td>{{ customer.qtdChamadas }}</td>
                                        <td>{{ customer.total | currency("R$ ", 2, { decimalSeparator: "," }) }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td><b>{{ this.totais.totalChamadas }}</b></td>
                                        <td><b>{{ this.totais.totalVenda | currency("R$ ", 2, { decimalSeparator: "," }) }}</b></td>  
                                    </tr>
                                </tbody>
                            </table>
                        </div>                 
                    </div>
                </div>
            </div>

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Inicio</th>
                                <th>Fim</th>
                                <th>Origem</th>
                                <th>Destino</th>
                                <th>Duração(s)</th>                               
                                <th>Status</th>
                                <th>Cliente</th>
                                <th>Assinante</th>
                                <th>Tronco</th>
                                <th>Custo</th>
                                <th>Venda</th>
                                <th>IP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report" :key="item.idbilling_cdr">
                                <td>{{item.starttime | formatDateHour}}</td>
                                <td>{{item.endtime | formatDateHour}}</td>
                                <td>{{item.src}}</td>
                                <td>{{item.dst}}</td>
                                <td>{{item.duration}}</td>
                                <td>{{item.disposition}}</td>
                                <td>{{item.customersName}}</td>
                                <td>{{item.idSubscriber}}</td>
                                <td>{{item.trunkName}}
                                <td>{{item.cost | currency("R$ ", 4, { decimalSeparator: "," })}}</td>
                                <td>{{item.sell | currency("R$ ", 2, { decimalSeparator: "," })}}</td>
                                <!-- <td v-else>R$ 0,00</td> -->
                                <!-- <td> <span v-if="item.disposition === 'ANSWERED'">{{item.sell | currency("R$ ", 2, { decimalSeparator: "," })}}</span><span v-else></span></td> -->
                                <td>{{item.src_ip}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between">

                    <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>

                    <!-- <pagination :data="customers" v-on:pagination-change-page="getCustomers" v-if="!searching"/> -->

                </div>
            </div>
        </div>
        <a href="" class="d-none" id="export-link" target="_blank"></a>
    </div>
</template>

<script>
export default {
    created() {
        this.getOperadoras();
},

    data() {
        return {
            errors: {},
            customers: [],
            subscribers: [],
            report: [],
            operadoras: [],
            troncos: [], 
            results: 0,
            customer_id: '',
            idCarrier: '',
            customersResults: [],
            total: 0, 
            tarifa: 0,
            totais: [], 
            // searching: false,
        }
    },

    methods: {
   
        getOperadoras(){ 
            this.$httpApi.get('operadoras', {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                }).then(res => {
                     this.operadoras = res.data.items;
            })
        },

        getTroncos(idCarrier){ 
            this.$httpApi.get('troncos/byCarrier/'+idCarrier, {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                }).then(res => {
                     this.troncos = res.data.items;
            })
        },

        getReport() {
            var dados = new FormData(document.getElementById('form-search'))
            this.$httpApi.post('reports/cdr/byoperadora',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.results = res.data.results;
                this.report = res.data.items;
                this.customersResults = res.data.customersResults;
                this.totais = res.data.totais;
                if (res.data.results == 0) {
                    this.$store.dispatch('criarMensagem',[
                        'Nenhum resultado foi encontrado',
                        'info'
                    ]);
                } else {
                    this.$store.dispatch('criarMensagem',[
                        'Consulta realizada com sucesso!',
                        'success'
                    ]);
                }
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        exportar() {
            var dados = new FormData(document.getElementById('form-search'));
            dados.append('exportar', 'csv');

            this.$httpApi.post('reports/cdr/byoperadora',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.results = res.data.results;
                this.report = res.data.items;
                if (res.data.results == 0) {
                    this.$store.dispatch('criarMensagem',[
                        'Nenhum resultado foi encontrado',
                        'info'
                    ]);
                }
                var link = document.getElementById('export-link');
                link.setAttribute('href', res.data.data.download);
                link.click();
                // this.searching = true;
            }).catch(res => {
                console.log(res.response);
            })
        },

        clearSearch() {
            this.results = 0;
            this.report = [];
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = '';
            })
        }
    }
}
</script>

<style>
</style>