<template>
<footer class="footer bg-brd text-white" id="basic-footer">
    <div class="container-fluid">
        <nav class="w-100 d-flex justify-content-between">
            <!-- <div class="text-center  text-lg-left  text-muted d-flex align-items-center">
                &copy; {{ date('Y', strtotime(now()))}} 
                <p class="font-italic ml-2 mb-0 d-flex align-items-center">Brasil Desenvolvimento de Sistemas</p>
            </div> -->
            <div class="copyright" id="copyright">
                <p>© 2021, <router-link :to="{ name:'Home' } " class="text-white">{{$store.state.config.name}}</router-link></p>
            </div>
            <ul class="d-flex justify-content-between">
                <li>
                    <router-link :to=" {name:'Home'} ">Home</router-link>
                </li>
            </ul>
        </nav>
    </div>
</footer>
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer {
        height: 53px;
        padding: 10px 0;
        /* background-image: linear-gradient( 250deg, #213e62 , #213e62aa); */
    }

    @media all and (max-width: 600px) {
        nav {
            flex-direction: column-reverse;
        }
        
        .copyright {
            /* align-self: center;
            margin-top: 2vh; */
            display: none;
        }
    }
</style>