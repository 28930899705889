<template>
<div style="" class="border" id="basic-sidebar">
    <div class="sidebar" data-color="brd">

        <div class="logo">
            <router-link :to=" {name: 'Home'} ">
                <img :src="this.$domain + 'logo/' + $store.state.config.logo" class="img-fluid" alt="">
            </router-link>
        </div>

        <div class="sidebar-wrapper" id="sidebar-wrapper">
            <div class="account2">
                <p>Seja Bem Vindo: </p>
                <h5 class="text-center"><i class="fas fa-user"></i> {{$store.state.user_name}}</h5>
            </div>
            <ul class="nav">
                 <li v-if="$canIf('users.allindex')" id="menu-admin" class="menu-dropdown">
                    <a @click.prevent="toggleDropdown('menu-admin')" class="btn-dropdown"><i class="fas fa-users design_app"></i>Administrador<i class="fas fa-caret-down float-right"></i></a>
                    <ul :class="'sub-menu-sidebar '+
                    ($store.state.rotaAtual.split('.')[0] == 'Admin'
                    ? 'showDropDown' : '')
                    ">
                        <li>
                            <router-link :to="{ name:'Admin.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Admin' ? 'ativo' : ''"><i class="fas fa-id-card design_app"></i>Usuarios</router-link>
                        </li>
                    </ul>
                </li>

                <li v-if="$canIf('users.index') || $canIf('roles.index') || $canIf('config.index')"  id="menu-config" class="menu-dropdown principal">
                    <a @click.prevent="toggleDropdown('menu-config')" class="btn-dropdown"><i class="fas fa-cog design_app"></i>Configurações<i class="fas fa-caret-down float-right"></i></a>
                    <ul :class="'sub-menu-sidebar '+
                    ($store.state.rotaAtual.split('.')[0] == 'Users' ||
                    $store.state.rotaAtual.split('.')[0] == 'Roles' ||
                    $store.state.rotaAtual.split('.')[0] == 'Config'
                    ? 'showDropDown' : '')
                    ">
                        <li>
                            <router-link v-if="$canIf('users.index')" :to="{ name:'Users.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Users' ? 'ativo' : ''"><i class="fas fa-id-card design_app"></i>Usuarios</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('roles.index')" :to="{ name:'Roles.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Roles' ? 'ativo' : ''"><i class="fas fa-user-tag design_app"></i>Cargos</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('config.index')" :to="{ name:'Config.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Config' ? 'ativo' : ''"><i class="fas fa-cogs design_app"></i>Configurações do Sistema</router-link>
                        </li>
                    </ul>
                </li>

                <li id="menu-clients" class="menu-dropdown principal" v-if="$canIf('customers.index') || $canIf('customergroups.index')">
                    <a @click.prevent="toggleDropdown('menu-clients')" class="btn-dropdown"><i class="fas fa-user"></i>Gerenciar Clientes<i class="fas fa-caret-down float-right"></i></a>
                    <ul :class="'sub-menu-sidebar '+
                    ($store.state.rotaAtual.split('.')[0] == 'Customers' ||
                    $store.state.rotaAtual.split('.')[0] == 'CustomerGroups' ||
                    $store.state.rotaAtual.split('.')[0] == 'Subscribers'
                    ? 'showDropDown' : '')
                    ">
                        <li>
                            <router-link v-if="$canIf('customers.index')" :to="{ name:'Customers.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Customers' || $store.state.rotaAtual.split('.')[0] == 'Subscribers' ? 'ativo' : ''"><i class="fas fa-user"></i>Clientes</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('customergroups.index')" :to="{ name:'CustomerGroups.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'CustomerGroups' ? 'ativo' : ''"><i class="fas fa-users design_app"></i>Grupos de Clientes</router-link>
                        </li>
                    </ul>
                </li>

                <li id="menu-costs" class="menu-dropdown principal" v-if="$canIf('operadoras.index') || $canIf('groupcostintl.index') || $canIf('trunk.index')">
                    <a @click.prevent="toggleDropdown('menu-costs')" class="btn-dropdown"><i class="fas fa-dollar-sign"></i>Planos de Custos<i class="fas fa-caret-down float-right"></i></a>
                    <ul :class="'sub-menu-sidebar '+
                    ($store.state.rotaAtual.split('.')[0] == 'Carriers' ||
                    $store.state.rotaAtual.split('.')[0] == 'Trunks' ||
                    $store.state.rotaAtual.split('.')[0] == 'IntTrunks' ||
                    $store.state.rotaAtual.split('.')[0] == 'IntCostGroups' ||
                    $store.state.rotaAtual.split('.')[0] == 'IntCostCosts' 
                    ? 'showDropDown' : '')
                    ">
                        <li>
                            <router-link v-if="$canIf('operadoras.index')" :to="{ name:'Carriers.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Carriers' ? 'ativo' : ''"><i class="fas fa-phone design_app"></i>Operadoras</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('groupcostintl.index')" :to="{ name:'IntCostGroups.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'IntCostGroups' || $store.state.rotaAtual.split('.')[0] == 'IntCostCosts' ? 'ativo' : ''"><i class="fas fa-receipt"></i>Tarifas Internacionais</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('trunk.index')" :to="{ name:'Trunks.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Trunks' ? 'ativo' : ''"><i class="fas fa-server"></i>Troncos</router-link>
                        </li>
                    </ul>
                </li>

                <li id="menu-sells" class="menu-dropdown principal" v-if="$canIf('nplans.index') || $canIf('iplans.index')">
                    <a @click.prevent="toggleDropdown('menu-sells')" class="btn-dropdown"><i class="fas fa-hand-holding-usd"></i>Planos de Vendas<i class="fas fa-caret-down float-right"></i></a>
                    <ul :class="'sub-menu-sidebar '+
                    ($store.state.rotaAtual.split('.')[0] == 'Routes' ||
                    $store.state.rotaAtual.split('.')[0] == 'IntRoutes' ||
                    $store.state.rotaAtual.split('.')[0] == 'RatePlans' ||
                    $store.state.rotaAtual.split('.')[0] == 'IntRatePlans'
                    ? 'showDropDown' : '')
                    ">
                        <li>
                            <router-link v-if="$canIf('nplans.index')" :to="{ name:'RatePlans.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'RatePlans' ? 'ativo' : '' || $store.state.rotaAtual.split('.')[0] == 'Trunks'  ? 'ativo' : ''"><i class="fas fa-file-alt"></i>Planos nacionais</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('iplans.index')" :to="{ name:'IntRoutes.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'IntRoutes' || $store.state.rotaAtual.split('.')[0] == 'IntRatePlans' || $store.state.rotaAtual.split('.')[0] == 'IntTrunks'  ? 'ativo' : ''"><i class="fas fa-plane"></i>Planos Internacionais</router-link>
                        </li>
                    </ul>
                </li>

                <li id="menu-services" class="menu-dropdown principal" v-if="$canIf('dids.index') || $canIf('reports.cdr') || $canIf('reports.cdrdetails')">
                    <a @click.prevent="toggleDropdown('menu-services')" class="btn-dropdown"><i class="fas fa-network-wired"></i>Serviços<i class="fas fa-caret-down float-right"></i></a>
                    <ul :class="'sub-menu-sidebar '+
                    ($store.state.rotaAtual.split('.')[0] == 'Dids' ||
                    $store.state.rotaAtual.split('.')[0] == 'Reports' ||
                    $store.state.rotaAtual.split('.')[0] == 'Relatorio' ||
                    $store.state.rotaAtual.split('.')[0] == 'Call'
                    ? 'showDropDown' : '')
                    ">
                        <li>
                            <router-link v-if="$canIf('dids.index')"  :to="{ name:'Dids.Index' }" :class="$store.state.rotaAtual.split('.')[0] == 'Dids' ? 'ativo' : ''"><i class="fas fa-phone design_app"></i>Dids</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('reports.cdr')" :to="{ name:'Reports.CDR' }" :class="$store.state.rotaAtual.split('.')[0] == 'Reports' ? 'ativo' : ''"><i class="fas fa-list design_app"></i>Relatório por Cliente</router-link>
                        </li>
                         <li>
                            <router-link v-if="$canIf('reports.cdrByOperadora')" :to="{ name:'Relatorio.CDRByOperadora' }" :class="$store.state.rotaAtual.split('.')[0] == 'Relatorio' ? 'ativo' : ''"><i class="fas fa-list design_app"></i>Relatório Por Operadora</router-link>
                        </li>
                        <li>
                            <router-link v-if="$canIf('services.callSimulation')" :to="{ name: 'Call.Simulator' }" :class="$store.state.rotaAtual.split('.')[0] == 'Call' ? 'ativo' : ''"  ><i class="fas fa-phone-square"></i>Simulador de Chamadas</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
    created() { 
    },
    data() {
        return {
            
        }
    },
    methods: {

        toggleDropdown(id) {
            var aberto = document.querySelector('.showDropDown');
            var menu = document.getElementById(id);
            var submenu = menu.childNodes[1];
            var icon = menu.firstChild.lastChild;
            if (aberto != submenu && aberto != null) {
                aberto.parentElement.firstChild.lastChild.classList.remove('ativo');
                aberto.classList.remove('showDropDown');
            }
            if (submenu.classList.contains('showDropDown')) {
                icon.classList.remove('ativo');
                submenu.classList.remove('showDropDown');
            } else {
                icon.classList.add('ativo');
                submenu.classList.add('showDropDown');
            }
        }
    }
}
</script>

<style>

.menu-dropdown {
    overflow: hidden;
}

.menu-dropdown:hover .ativo{
    background-color: transparent;
}

.sidebar .nav li > a, .off-canvas-sidebar .nav li > a{
  color: #213e62 !important;
  width: 100%;
  border-radius: 0;
  /* border: 2px solid red; */
}

.sidebar .nav li > a.ativo i, .off-canvas-sidebar .nav li > a.ativo i,
.sidebar .nav li > a:hover, .off-canvas-sidebar .nav li > a:hover,
.sidebar .nav li > a:hover i, .off-canvas-sidebar .nav li > a:hover i{
    background: #213e62 !important;
    color: #f9f9f9;
}

.sidebar .nav li > a i {
    color: #213e62;
}

.sidebar .nav li > a.ativo, .sidebar .nav li > a:hover { 
    background-color: #213e62;
    color: #fff !important;
}

.sidebar .nav li:first-child > a, .off-canvas-sidebar .nav li:first-child > a {
    margin: 0px;
}
.sidebar .nav .menu-dropdown.principal > a:first-child{
    border-left: 5px solid #213e62;
    margin-bottom: 5px;
}

.sidebar .nav .menu-dropdown {
    padding: 0px !important;
    margin: 0px !important;
}

.sidebar .nav .menu-dropdown a{
    width: 100%;
    margin: 0px;
}

.sidebar .nav .menu-dropdown ul {
    padding: 0px !important;
    width: 100%;
}

.sidebar .nav .menu-dropdown ul li {
    width: 100%;
}

.sidebar .nav .menu-dropdown ul li a {
    /* border: 2px solid blue; */
    margin-left: 5px;
}

i.ativo {
    transform: rotate(-180deg);
    transition: 500ms;
}

.sidebar-wrapper nav li .btn-dropdown {
    color: #213e62 !important;
}


/* Animação do Sidebar */
.sub-menu-sidebar {
    display: none;
    list-style: none;
}

.showDropDown {
    display: block;
    animation-name: openDropdown;
    animation-duration: 600ms;
}
@keyframes openDropdown {
    0% {
        height: 0;
        transform: translateX(-100%);
    }
    60% {
        height: 100%;
        transform: translateX(4%);
    }
    80% {
        height: 100%;
        transform: translateX(-1%);
    }
    100% {
        transform: translateX(0);
    }
}

.account2 {
    background: #e2e7e9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border-bottom: 5px solid #213e62;
}

.account2 p {
    color: #213e62;
    padding: 5px;
}

.account2 h5 {
    text-align: left !important;
    /* padding-top: 15px; */
    padding-left: 5px;
    font-size: 1rem;
    color: #213e62;
}

</style>