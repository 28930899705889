<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Troncos</h4>
            <div class="d-flex">
                <router-link :to="{ name: 'IntTrunks.Index' }" class="btn btn-secondary btn-sm mr-2">Voltar</router-link>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Servidor</th>
                        <th>Tipo de Conexão</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <tr v-for="trunk in trunks" :key="trunk.idTrunk">

                        <td data-title="Id">{{trunk.idTrunk}}</td>
                        <td data-title="Nome">{{trunk.trunkName}}</td>
                        <td data-title="Servidor">{{trunk.trunkHost}}</td>
                        <td v-if="trunk.trunkRegister == 'Y'" data-title="Tipo de Conexão">Registro</td>
                        <td v-else data-title="Tipo de Conexão">TechPrefix</td>
                        <td class="acoes">

                            <router-link v-if="$canIf('ritrunk.update')" :to="{ name: 'IntTrunks.Edit', params: {trunk_id: trunk.idTrunk}}" title="Editar" class="m-1 btn btn-sm bg-info">Editar</router-link>
                            <button v-if="$canIf('ritrunk.store')" title="Adicionar" class="m-1 btn btn-sm bg-success" @click.prevent="attachTrunk(trunk.idTrunk)">Adicionar</button>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'route_id'
    ],

    data() {
        return {
            trunks: [],
            validator: [],
        }
    },

    created() {
        this.getTrunks();
    },

    methods: {
        getTrunks() {
            this.validator = [];
            this.trunks = [];
            this.$httpApi.get('routesinthavetrunk/show/'+this.route_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                if (res.data.items) {
                    res.data.items.forEach(tronco => {
                        this.validator.push(
                            tronco.idTrunk,
                        )
                    });
                }
                this.$httpApi.get('troncos',{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                }).then(res => {
                    res.data.dados.forEach(tronco => {
                        if (!this.validator.includes(tronco.idTrunk)) {
                            this.trunks.push(tronco);
                        }
                    });
                    // this.trunks = res.data.dados
                }).catch(res => {
                    if(res.response.data.errors){
                        this.errors = res.response.data.errors
                    }
                    this.$store.dispatch('exibeErro', [
                        res.response
                    ])
                });
            })
        },

        attachTrunk(id) {
            var dados = {
                idRoute: this.route_id,
                idTrunk: id,
            }
            this.$httpApi.post('routesinthavetrunk/store', dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getTrunks()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getTrunks()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    }
}
</script>

<style>

</style>