<template>
    <div>
        <div class="card" v-if="$canIf('customers.index')">
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getReport">
                    <div class="row">
                        <div class="col-8 form-group">
                            <input type="text" class="form-control" id="input-source" name="word" placeholder="Procure pelo nome ou parte dele">
                        </div>
                    
                        <div class="col-md-4">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-end" v-if="$canIf('customergroups.index')">
            <h4>Lista de Grupos de Clientes</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('customergroups.store')" :to="{ name: 'CustomerGroups.Create' }" class="btn btn-success btn-sm mr-2">Cadastrar Grupo</router-link>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Descrição</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="group in groups" :key="group.idCustomerGroup">
                        <td data-title="Id">{{group.idCustomerGroup}}</td>
                        <td data-title="Descrição">{{group.customerGroupDescription}}</td>
                        <td class="acoes">
                            <router-link v-if="$canIf('customergroups.update')" :to="{ name: 'CustomerGroups.Edit', params: {group_id: group.idCustomerGroup}}" class="m-1 btn btn-sm bg-info" title="Editar Grupo"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('customergroups.delete')"  class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(group.idCustomerGroup)" title="Deletar Grupo"><i class="fas fa-trash-alt"></i></button>
                            <router-link v-if="$canIf('customergroups.show')" :to="{ name: 'CustomerGroups.Customers', params: {group_id: group.idCustomerGroup}}" class="m-1 btn btn-sm bg-primary" title="Visualizar Clientes"><i class="fas fa-users"></i></router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <confirm-delete v-if="$canIf('customergroups.delete')"
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Grupo?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>

    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: {
        ConfirmDelete,
    },

    created() {
        this.getGroups();
    },

    data() {
        return {
            groups: [],
            isConfirmDeleteModalVisible: false,
            group: "",
        }
    },

    methods: {
        getGroups() {
            this.$httpApi.get('customergroups',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then(res => {
                this.groups = res.data.items;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        getReport(){
            var dados = new FormData(document.getElementById('form-search'))
            this.$httpApi.post('customergroups/search', dados, {
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then(res => {
                this.groups = res.data.items;
            }).catch (res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        deleteGroup(id) {
            this.$httpApi.delete('customergroups/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getGroups()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getGroups()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })

            this.closeConfirmDeleteModal();
        },

        clearSearch(){
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = '';
            })
            this.getGroups();
        },

        showConfirmDeleteModal(id) {
            this.group = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.group = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteGroup(this.group);
        },

    }
}
</script>

<style>

</style>