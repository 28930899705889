<template>
    <div>
        <div class="card" v-if="$canIf('operadoras.index')" >
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getReport">
                    <div class="row">
                        <div class="col-8 form-group">
                            <input type="text" class="form-control" id="input-source" name="word" placeholder="Procure por nome, descricão ou nome de contato">
                        </div>
                    
                        <div class="col-md-4">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <h4>Lista de Operadoras</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('operadoras.store')" :to="{ name: 'Carriers.Create' }" class="btn btn-success btn-sm d-flex align-items-center pt-3 pb-3 h-50">Cadastrar Operadora</router-link>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light" v-if="$canIf('operadoras.index')">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Nome</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Descrição</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="carrier in carriers" :key="carrier.idCarrier" :class="carrier.deleted_at ? 'alert alert-primary' : '' ">

                        <td data-title="Id">{{carrier.idCarrier}}</td>
                        <td data-title="Nome">{{carrier.carrierName}}</td>
                        <td data-title="Descrição">{{carrier.carrierDescription}}</td>
                        <td v-if="carrier.deleted_at == null" class="acoes">
                            <router-link v-if="$canIf('operadoras.destroy')" :to="{ name: 'Carriers.Edit', params: {carrier_id: carrier.idCarrier}}" class="m-1 btn btn-sm bg-info" title="Editar Operadora"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('operadoras.show')" title="Deletar Operadora" class="m-1 btn btn-sm bg-danger" @click="showConfirmDeleteModal(carrier.idCarrier)"><i class="fas fa-trash-alt"></i></button>
                        </td>
                        <td v-else class="acoes">
                            <a  v-if="carrier.deleted_at != null && $canIf('operadoras.destroy')" title="Ativar Operadora" class="m-1 btn btn-sm bg-warning" @click.prevent="activateCarrier(carrier.idCarrier)"><i class="fas fa-plus"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <confirm-delete
                v-show="isConfirmDeleteModalVisible"
                modalHeadline="Excluir Operadora?"
                deleteMessage="Você Tem Certeza?"
                @deleteRecordEvent="deleteItem"
                @close="closeConfirmDeleteModal"
            ></confirm-delete>
        </div>
    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: { ConfirmDelete },

    created() {
        this.getCarriers();
    },

    data() {
        return {
            carriers: [],
            isConfirmDeleteModalVisible: false,
            carrier: "",
        }
    },

    methods: {
        getCarriers() {
            this.$httpApi.get('operadoras',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.carriers = res.data.items;

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        getReport(){
            var dados = new FormData(document.getElementById('form-search'));
            this.$httpApi.post('operadoras/search', dados, {
                headers: {
                    'Authorization' : "Bearer "+sessionStorage.getItem('Auth_Token'),
                }
            }).then( res => {
                this.carriers = res.data.items;
            }).catch(res => {
                this.$store.dispatch('criarMensagem', [
                    res.data.message,
                    'warning',
                ])
            })
        },

        deleteCarrier(id) {
            this.$httpApi.delete('operadoras/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCarriers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getCarriers()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
            this.closeConfirmDeleteModal();
        },
        activateCarrier(id){
            this.$httpApi.put('operadoras/restore/'+id, '', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                this.getCarriers();
                this.$store.dispatch('criarMensagem', [
                    res.data.message,
                    "success"
                ])
            }).catch(res => {
                this.$store.dispatch('criarMensagem', [
                res.data.message,
                "warning"
                ])
            })
            this.closeConfirmDeleteModal();
        },

        clearSearch(){
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = "";
            })
            this.getCarriers();
        },

        showConfirmDeleteModal(id) {
            this.carrier = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.carrier = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteCarrier(this.carrier);
        },
    }

}
</script>

<style>

</style>