<template>
    <div class="card" v-if="$canIf('customergroups.update')">
        <form action="" method="post" @submit.prevent="editGroup" id="customerGroup-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Grupo</h4>
                </div>
            </div>
            <div class="card-body">
                <customer-group-form
                :errors="errors"
                :customer_group="group"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'CustomerGroups.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  CustomerGroupForm from "@/components/views/customerGroups/_Form";
export default {

    components: {
        'customer-group-form': CustomerGroupForm,
    },

    props: ['group_id'],

    created() {
        this.$httpApi.get('customergroups/show/'+this.group_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.group = res.data.item;
        })
    },

    data(){
        return {
            errors: {},
            group: {},
        }
    },

    methods: {
        editGroup() {
            var dados = new FormData(document.getElementById('customerGroup-form'));
            dados.append('_method', 'PUT');
            // var dados = {
            //     customerGroupDescription: document.getElementById('input-description').value
            // };
            this.$httpApi.post('customergroups/update/'+this.group_id,dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'CustomerGroups.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                // this.$store.state.redirect = true
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>