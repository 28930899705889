<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Grupos de Tarifas</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('groupcostintl.store')" :to="{ name: 'IntCostGroups.Create' }" class="btn btn-success btn-sm mr-2">Cadastrar Grupo</router-link>
            </div>
        </div>
        <br>
        <div>
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Descrição</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <tr v-for="group in groups" :key="group.id">

                        <td data-title="Id">{{group.id}}</td>
                        <td data-title="Descrição">{{group.description}}</td>
                        <td class="acoes">
                            <router-link v-if="$canIf('ratecostinternational.showByGroup')" :to="{ name: 'IntCostCosts.Index', params: {group_id: group.id}}" class="m-1 btn btn-sm bg-primary" title="Lista de Tarifas"><i class="fas fa-dollar-sign"></i></router-link>
                            <router-link v-if="$canIf('groupcostintl.edit')" :to="{ name: 'IntCostGroups.Edit', params: {group_id: group.id}}" class="m-1 btn btn-sm bg-info" title="Editar Grupo"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('groupcostintl.delete')" title="Deletar Grupo" class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(group.id)"><i class="fas fa-trash-alt"></i></button>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <confirm-delete
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Grupo?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>

    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";

export default {
    components: {
        ConfirmDelete
    },

    created() {
        this.getGroups();
    },

    data() {
        return {
            groups: [],
            isConfirmDeleteModalVisible: false,
            group: "",
        }
    },

    methods: {
        getGroups() {
            this.$httpApi.get('groupcostintl',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.groups = res.data.items;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteGroups(id) {
            this.$httpApi.delete('groupcostintl/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getGroups()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        showConfirmDeleteModal(id) {
            this.group = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.group = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteGroups(this.group);
            this.closeConfirmDeleteModal();
        },
    }
}
</script>

<style>
</style>