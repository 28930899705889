<template>
    <div class="wrapper">
        <layout-sidebar id="layout-sidebar"/>
        <div class="col p-0 main-panel" id="main-panel" style="">
            <layout-header id="layout-header"/>
                <div id="main" class="d-flex flex-column" style="">
                    <div v-if="$store.state.showAlert" :class="'alert alert-'+$store.state.mensagemAlert.type" role="alert">
                        <strong>{{$store.state.mensagemAlert.text}}</strong>
                    </div>
                    <div class="col mt-2" v-show="$store.state.readyToLoad">
                        <router-view/>
                    </div>
                    <div v-show="!$store.state.readyToLoad" class="m-auto align-self-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                </div>
            <layout-footer id="layout-footer"/>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
#main {
    min-height: calc(100vh - 11vh);
}
</style>