<template>
    <div>
        <div class="row">
            <div class="form-group col-5">
                <label for="input-name">Nome</label>
                <input class="form-control" type="text" id="input-name" name="name" v-model="role.name">
                <p v-for="erro in errors.name" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-7">
                <label for="input-description">Descrição</label>
                <input class="form-control" type="text" id="input-description" name="description" v-model="role.description">
                <p v-for="erro in errors.description" :key="erro" class="text-danger">{{erro}}</p>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: ['role', 'errors'],
}
</script>

<style>

</style>