<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Troncos</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('ritrunk.store')" :to="{ name: 'IntTrunks.Create' }" class="btn btn-success btn-sm mr-2">Cadastrar Tronco</router-link>
                <router-link v-if="$canIf('rtrunk.update')" :to="{ name: 'IntTrunks.Add' }" class="btn btn-warning btn-sm">Adicionar tronco já existente</router-link>
                <!-- <button class="btn bg-warning btn-sm ml-2" @click="openModalCSV">Importar CSV</button> -->
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th></th>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Servidor</th>
                        <th>Tipo de Conexão</th>
                        <th>Prioridade</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <tr draggable="true" v-for="trunk in trunks" :key="trunk.idTrunk" class="troncos">

                        <td><i class="fas fa-arrows-alt text-muted"></i></td>
                        <td data-title="Id">{{trunk.idTrunk}}</td>
                        <td data-title="Nome">{{trunk.trunkName}}</td>
                        <td data-title="Servidor">{{trunk.trunkHost}}</td>
                        <td v-if="trunk.trunkRegister == 'Y'" data-title="Tipo de Conexão">Registro</td>
                        <td v-else data-title="Tipo de Conexão">TechPrefix</td>
                        <td data-title="Prioridade" class="order">{{trunk.priority}}</td>
                        <td class="acoes">

                            <router-link :to="{ name: 'Trunks.Edit', params: {trunk_id: trunk.idTrunk}}" class="m-1 btn btn-sm bg-info">Editar</router-link>
                            <button title="Deletar" class="m-1 btn btn-sm bg-warning" @click.prevent="detachTrunk(trunk)">Desvincular</button>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <form id="form" method="post">
            <div id="elementos"></div>
        </form> -->
    </div>
</template>

<script>
export default {
    props: [
        'route_id'
    ],

    created() {
        this.getTrunks();
    },

    data() {
        return {
            trunks: [],
        }
    },

    methods: {
        getTrunks() {
            this.$httpApi.get('routesinthavetrunk/show/'+this.route_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.trunks = [];
                res.data.items.forEach(tronco => {
                    this.trunks.push({
                        id: tronco.id,
                        priority: tronco.priority,
                        idTrunk: tronco.idTrunk,
                        trunkName: tronco.trunkName,
                        trunkHost: tronco.trunkHost,
                        trunkRegister: tronco.trunkRegister,
                    })
                });

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        detachTrunk(trunk) {
            
            this.$httpApi.delete('routesinthavetrunk/delete/'+trunk.id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getTrunks()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getTrunks()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    }

}
</script>

<style>
</style>