<template>
    <div class="card" v-if="$canIf('customers.edit')">
        <form action="" method="post" @submit.prevent="editCustomer" id="customers-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Cliente</h4>
                </div>
            </div>
            <div class="card-body">
                <customer-form
                :errors="errors"
                :customer="customer"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'Customers.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  CustomerForm from "@/components/views/customers/_Form";
export default {

    components: {
        'customer-form': CustomerForm,
    },

    props: ['customer_id'],

    created() {
        this.$httpApi.get('customers/ushow/'+this.customer_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.customer = res.data.dados
            this.customer.customersDateInvoice = this.customer.customersDateInvoice.substring(0,10);
            // this.customer.name = res.data.dados.customersName;
            // this.customer.date = res.data.dados.customersDateInvoice.substring(0,10);
        })
    },

    data(){
        return {
            errors: {},
            customer: {
                name: String,
                date: Date,
            },
        }
    },

    methods: {
        editCustomer() {
            var dados = new FormData(document.getElementById('customers-form'))
            dados.append('_method', 'PUT');
            
            this.$httpApi.post('customers/update/'+this.customer_id,dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Customers.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                // this.$store.state.redirect = true
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>