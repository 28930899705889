<template>
    <div class="card" v-if="$canIf('subscribers.edit')">
        <form action="" method="post" @submit.prevent="editSubscriber" id="subscriber-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Assinante</h4>
                </div>
            </div>
            <div class="card-body">
                <subscriber-form
                :errors="errors"
                :subscriber="subscriber"
                :ip_config="ip_config"
                :edit = "true"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'Subscribers.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  SubscriberForm from "@/components/views/subscribers/_Form";
export default {

    components: {
        'subscriber-form': SubscriberForm,
    },

    props: [
        'subscriber_id',
    ],

    created() {
        this.$httpApi.get('subscribers/'+this.subscriber_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.subscriber = res.data.items;
            if (res.data.items.ips) {
                this.ip_config = res.data.items.ips;
            } else {
                this.ip_config = {};
            }
        }).catch(res => {
            if (res.response.data.message == 'Subscriber not exists') {
                this.$router.push({ name: 'Subscribers.Index' })
                this.$store.dispatch('criarMensagem',[
                    'Não é possível editar esse assinante',
                    'danger'
                ])
            }
        })
    },

    data(){
        return {
            errors: {},
            subscriber: {},
            ip_config: {},
        }
    },

    methods: {
        editSubscriber() {
            var dados = new FormData(document.getElementById('subscriber-form'));
            dados.append('_method', 'PUT');
            dados.append('accountcode', this.subscriber.accountcode);

            this.$httpApi.post('subscribers/'+this.subscriber_id, dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Subscribers.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>