<template>
    <div class="card">
        <div class="d-flex justify-content-between pt-2 pb-2 align-items-end card-header" style="height: fit-content">
            <h4>Permissões Sem Grupo</h4>
        </div>
        <div class="card-body">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Nome</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(2)">Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="permission in permissions" :key="permission.id">
                        <td data-title="Id">{{permission.id}}</td>
                        <td data-title="Nome">{{permission.name}}</td>
                        <td data-title="Descrição">{{permission.description}}</td>
                    </tr>
                </tbody>
                
            </table>
        </div>
        <div class="card-footer">
            <router-link :to="{ name: 'Roles.PermissionGroups' }" class="btn btn-danger">Voltar</router-link>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.$httpApi.get('permissiongroups/others',{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
        },
        }).then(res => {
            this.permissions = res.data.permissoes;
        }).catch(res => {
            if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
        });
    },

    data() {
        return {
            permissions: [],
        }
    },
}
</script>

<style>

</style>