import Vue from 'vue'
import store from '@/store/index'
import axios from 'axios'

Vue.use({
    install(Vue) {
        // criação da instancia do axios
        const httpApi = axios.create({
            // baseURL: 'http://astbilling.brdsoft.com.br/api/'
            baseURL: this.$domain + '/api/'
        })
        
        httpApi.interceptors.request.use(function(request) {
            store.state.readyToLoad = false;
            store.dispatch('criarMensagem',[
                'Aguarde um momento, estamos extendendo o período da sua sessão',
                'warning'
            ]);
            return request;
        })
        httpApi.interceptors.response.use(function(response) {
            store.dispatch('limparMensagem');
            store.state.readyToLoad = true;
            return response;
        })
        
        // Função para bloquear blocos de código no template
        Vue.prototype.$canIf = function(permission) {
            return (store.state.admin || store.state.permissions.includes(permission))
        }
        
        // // Função para ativar o refresh do token através de um intervalo de tempo determinado
        // Vue.prototype.$activateRefresh = function() {
        //     if (sessionStorage.getItem('Auth_Token')) {
        //         let intervalo = setInterval(function() {
        //             clearInterval(intervalo)
        //             if (sessionStorage.getItem('Auth_Token')) {
        //                 httpApi.get('auth/refresh',{
        //                     headers: {
        //                         'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
        //                     },
        //                 }).then(res => {
        //                     sessionStorage.setItem('Auth_Token', res.data.token);
        //                     sessionStorage.setItem('TimeRefresh', Date.now())
        //                     Vue.prototype.$activateRefresh()
        //                 }).catch(res => {
        //                     switch (res.response.status) {
        //                         case 401:
        //                         console.log(res.response);
        //                         store.dispatch('logout');
        //                         store.dispatch('criarMensagem',[
        //                             'A sessão já expirou',
        //                             'danger'
        //                         ]);
        //                         break;
                                
        //                         default:
        //                         break;
        //                     }
        //                 })
        //             }
        //         }, (50 /* tempo em minutos */ * 60000) - (sessionStorage.getItem('Auth_Token') ? (Date.now() - sessionStorage.getItem('TimeRefresh')) : 0))
        //     }
        // }
        
        // // Função para ativar o refresh do token ao atualizar a página
        // if (sessionStorage.getItem('Auth_Token')) {
        //     Vue.prototype.$activateRefresh()
        // }
    }
})