<template>
    <div>
        <div class="card">
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getReport">
                    <div class="row">
                        <div class="col-8 form-group">
                            <input type="text" class="form-control" id="input-source" name="word" placeholder="Usuário">
                        </div>
                    
                        <div class="col-md-4">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Assinantes</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('subscribers.create')" :to="{ name: 'Subscribers.Create' }" class="btn btn-success btn-sm">Cadastrar Assinante</router-link>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Username</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :class="subscriber.deleted_at ? 'alert alert-primary' : ''" v-for="subscriber in subscribers" :key="subscriber.id">

                        <td data-title="Id">{{subscriber.id}}</td>
                        <td data-title="Descrição">{{subscriber.username}}</td>
                        <td class="acoes" v-if="subscriber.deleted_at == null">
                            <router-link v-if="$canIf('subscribers.haveDid')" title="Dids" :to="{ name: 'Subscribers.Dids', params: {subscriber_id: subscriber.id}}" class="m-1 btn btn-sm bg-primary"><i class="far fa-address-book"></i></router-link>
                            <router-link v-if="$canIf('subscribers.edit')" title="Editar" :to="{ name: 'Subscribers.Edit', params: {subscriber_id: subscriber.id}}" class="m-1 btn btn-sm bg-info"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('subscribers.delete')" title="Desativar" class="m-1 btn btn-sm bg-danger" @click.prevent="deleteSubscriber(subscriber.id)"><i class="fas fa-ban"></i></button>
                            <button v-if="$canIf('subscribers.resetPass')" title="Redefinir Senha" class="m-1 btn btn-sm bg-info" @click.prevent="resetPass(subscriber)"><i class="fas fa-unlock-alt"></i></button>
                            <router-link v-if="$canIf('subscribers.ratePlan')" title="Planos" :to="{ name: 'Subscribers.Plans', params: {subscriber_id: subscriber.id}}" class="m-1 btn btn-sm bg-warning"><i class="fas fa-list-ul"></i></router-link>
                            <button v-if="$canIf('subscribers.edit')" title="Ver Dados" class="m-1 btn btn-sm bg-info" @click.prevent="showData(subscriber)"><i class="far fa-eye"></i></button>
                        </td>
                        <td class="acoes" v-else>
                            <button v-if="$canIf('subscribers.activated')" title="Ativar" class="m-1 btn btn-sm bg-secondary" @click.prevent="activateSubscriber(subscriber.id)">Ativar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div id="modalPass" class="modal-fundo" @click.self="closeModalPass">
            <div class="card modal-conteudo">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-end pb-3">
                        <span class="btn-fechar" @click="closeModalPass">x</span>
                    </div>
                    <h5>A senha do assinante <strong>{{novaSenha.subscriber}}</strong> foi redefinida</h5>
                    <h5>A nova senha é: <strong>{{novaSenha.senha}}</strong></h5>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between">
                        <button class="btn bg-danger btn-sm" @click.prevent="closeModalPass">Fechar</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="modalData" class="modal-fundo" @click.self="closeModalData">
            <div class="card modal-conteudo">
                <div class="card-header">
                    <h4>Dados do assinante</h4>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-end pb-3">
                        <span class="btn-fechar" @click="closeModalData">x</span>
                    </div>
                    <h5>Usuário: {{dados.username}}</h5>
                    <h5>Senha: {{dados.password}}</h5>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between">
                        <button class="btn bg-danger btn-sm" @click.prevent="closeModalData">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'customer_id'
    ],
    created() {
        this.getSubscribers();
        this.$httpApi.get('nplans',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.ratePlans = res.data.items
        })
        this.$httpApi.get('iplans',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.intRatePlans = res.data.dados
        })
    },

    data() {
        return {
            subscribers: [],
            novaSenha: {
                subscriber: '',
                senha: '',
            },
            idSubscriber: '',
            ratePlans: [],
            intRatePlans: [],
            dados: {
                username: '',
                password: '',
            },
        }
    },

    methods: {
        getSubscribers() {
            this.$httpApi.get('subscribers/'+this.customer_id+'/byCustomer',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.subscribers = res.data.items

            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

         getReport() {
            var dados = new FormData(document.getElementById('form-search'));
            dados.append('accountcode', this.customer_id);
            this.$httpApi.post('subscribers/search', dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.subscribers = res.data.items

            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteSubscriber(id) {
            this.$httpApi.delete('subscribers/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getSubscribers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getSubscribers()
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        activateSubscriber(id) {
            this.$httpApi.put('subscribers/'+id+'/restore','',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getSubscribers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getSubscribers()
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        resetPass(subscriber) {
            this.$httpApi.put('subscribers/'+subscriber.id+'/resetPass','',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getSubscribers();
                
                this.novaSenha.subscriber = subscriber.username;
                this.novaSenha.senha = res.data.senha;
                this.openModalPass();
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        showData(subscriber) {
            this.dados.username = subscriber.username;
            this.dados.password = subscriber.secret;
            this.openModalData();
        },

        addPlan(id) {
            var dados = new FormData(document.getElementById('ratePlan-form'));
            this.$httpApi.post('subscribers/'+id+'/addRatePlan',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getSubscribers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getSubscribers()
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
            this.closeModalPlan();
        },

        openModalPass() {
            document.getElementById('modalPass').style = "display: block;"
        },

        closeModalPass() {
            document.getElementById('modalPass').style = "display: none;"
        },

        openModalData() {
            document.getElementById('modalData').style = "display: block;"
        },

        closeModalData() {
            document.getElementById('modalData').style = "display: none;"
        },

        clearSearch() {
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = "";
            })
            this.getSubscribers();
        },
    }

}
</script>

<style scoped>

.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;

    animation-name: modal;
    animation-duration: 700ms;

}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

@keyframes modal {
    from {
        top: 30%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
    }
}


</style>