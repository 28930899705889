<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Troncos</h4>
            <div class="d-flex">
                <!-- <router-link :to="{ name: 'Trunks.Add' }" class="btn btn-warning btn-sm">Adicionar tronco já existente</router-link> -->
                <!-- <button class="btn bg-warning btn-sm ml-2" @click="openModalCSV">Importar CSV</button> -->
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-center" id="form-trunks">
            <div class="col-5">
                <div class="card">
                    <div class="card-header">
                        <h5>Não Vínculados</h5>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Tronco</th>
                                </tr>
                            </thead>
                            <tbody class="caixa" id="not-bound-box">
                                <tr v-for="tronco in allTrunks" :key="tronco.idTrunk" class="not-bounds" :id="tronco.idTrunk">
                                    <td>
                                        <input type="checkbox" class="form-control not-bound-checkbox" :id="tronco.idTrunk+'_checkbox'">
                                    </td>
                                    <td>{{tronco.trunkName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="card-footer"></div> -->
                </div>
            </div>
            <div class="col-1 d-flex flex-column align-self-center justify-content-center">
                <div class="btn bg-brd btn-sm" @click="goRight">
                    <h5 class="m-0">
                        <i class="fas fa-chevron-right text-white"></i>
                    </h5>
                </div>
                <br>
                <div class="btn bg-brd btn-sm" @click="goLeft">
                    <h5 class="m-0">
                        <i class="fas fa-chevron-left text-white"></i>
                    </h5>
                </div>
            </div>
            <div class="col-5">
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <h5>Vínculados</h5>
                        <div class="d-flex">
                            <button class="btn bg-brd btn-sm" id="btn-subir" @click="goUp">
                                <i class="fas fa-chevron-up text-white"></i>
                            </button>
                            <button class="btn bg-brd btn-sm" id="btn-descer" @click="goDown">
                                <i class="fas fa-chevron-down text-white"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body pl-0 pr-0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Tronco</th>
                                    <th scope="col">Prioridade</th>
                                </tr>
                            </thead>
                            <tbody class="caixa" id="bound-box">
                                <tr v-for="tronco in trunks" :key="tronco.idTrunk" class="bounds trunks" :id="tronco.idTrunk">
                                    <td>
                                        <input type="checkbox" class="form-control">
                                    </td>
                                    <td>{{tronco.trunkName}}</td>
                                    <td class="prioridade">{{tronco.priority}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <button class="btn bg-brd btn-sm" @click="saveAtt">Salvar</button>
    </div>
</template>

<script>
export default {
    props: [
        'route_id'
    ],

    created() {
        this.getTrunks();
    },

    updated() {
        var vinculados = document.querySelectorAll('.bounds');
        vinculados.forEach((tronco, index) => {
           tronco.querySelector('.prioridade').innerHTML = index + 1; 
        });
    },

    data() {
        return {
            trunks: [],
            allTrunks: [],
            idTrunks: [],
            newTrunks: [],
        }
    },

    methods: {
        getAllTrunks() {
            this.$httpApi.get('troncos',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.allTrunks = [];
                res.data.dados.forEach(tronco => {
                    if (!this.idTrunks.includes(tronco.idTrunk)) {
                        this.allTrunks.push(tronco);
                    }
                })
            })
        },

        getTrunks() {
            this.$httpApi.get('rottr/show/'+this.route_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.trunks = [];
                this.idTrunks = [];
                if (res.data.status == 'info') {
                    this.getAllTrunks();
                    return;
                }
                res.data.items.forEach(tronco => {
                    this.idTrunks.push(tronco.idTrunk);
                    this.trunks.push({
                        id: tronco.id,
                        priority: this.trunks.length+1,
                        idTrunk: tronco.idTrunk,
                        trunkName: tronco.trunkName,
                    })
                });
                this.getAllTrunks();
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
                this.getAllTrunks();
            });
        },

        goRight() {
            var n_vinculados = document.querySelectorAll('.not-bounds');
            var mover = [];
            n_vinculados.forEach(item => {
                if (item.firstElementChild.firstElementChild.checked) {
                    // document.getElementById('bound-box').appendChild(item)
                    this.allTrunks.forEach(tronco => {
                        if (tronco.idTrunk == item.getAttribute('id')) {
                            mover.push(tronco);
                        }
                    });
                }
            });
            mover.forEach(item => {
                const index = this.allTrunks.indexOf(item);
                if (index > -1) {
                    this.allTrunks.splice(index, 1);
                }
                this.trunks.push({
                    priority: this.trunks.length+1,
                    idTrunk: item.idTrunk,
                    trunkName: item.trunkName,
                })
            });
        },

        goLeft() {
            var vinculados = document.querySelectorAll('.bounds');
            var mover = [];
            vinculados.forEach(item => {
                if (item.firstElementChild.firstElementChild.checked) {
                    this.trunks.forEach(tronco => {
                        if (tronco.idTrunk == item.getAttribute('id')) {
                            mover.push(tronco);
                        }
                    });
                }
            });
            mover.forEach(item => {
                const position = this.trunks.indexOf(item);
                if (position > -1) {
                    this.trunks.splice(position, 1);
                }
                this.allTrunks.push({
                    idTrunk: item.idTrunk,
                    trunkName: item.trunkName,
                })
            });
        },

        goUp() {
            var vinculados = document.querySelectorAll('.bounds');
            vinculados.forEach((item, index) => {
                if (item.firstElementChild.firstElementChild.checked && index > 0) {
                    item.querySelector('.prioridade').innerHTML = index;
                    vinculados[index - 1].querySelector('.prioridade').innerHTML = index + 1;
                    document.getElementById('bound-box').insertBefore(item,vinculados[index - 1])
                    vinculados = document.querySelectorAll('.bounds');
                }
            });
        },

        goDown() {
            var vinculados = document.querySelectorAll('.bounds');

            for (let index = vinculados.length - 1; index >= 0; index--) {
                const item = vinculados[index];

                if (item.firstElementChild.firstElementChild.checked && index < vinculados.length - 1) {
                    if (typeof vinculados[index + 2] != undefined) {
                        document.getElementById('bound-box').insertBefore(item,vinculados[index + 2])
                    } else {
                        document.getElementById('bound-box').appendChild(item)
                    }
                    item.querySelector('.prioridade').innerHTML = index + 2;
                    vinculados[index + 1].querySelector('.prioridade').innerHTML = index + 1;
                    vinculados = document.querySelectorAll('.bounds');
                }
            }
        },

        saveAtt() {
            var trunks = document.querySelectorAll('.bounds');
            var update = [];
            trunks.forEach(tronco => {
                update.push(tronco.getAttribute('id'));
            })
            this.$httpApi.post('rottr/addAll/'+this.route_id, update, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(() => {
                this.getTrunks();
            })
        },

        detachTrunk(trunk) {
            this.$httpApi.delete('rottr/delete/'+trunk.id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getTrunks()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getTrunks()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    }

}
</script>

<style scoped>

.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;

    animation-name: modal;
    animation-duration: 700ms;

}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

@keyframes modal {
    from {
        top: 30%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
    }
}


</style>