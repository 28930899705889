<template>
        <div class="d-flex flex-wrap justify-content-center">

            <div class="form-group col-8">
                <label for="input-description">Descrição *</label>
                <input type="text" name="ratePlanInternationalDescription" id="input-description" class="form-control" v-model="int_plan.ratePlanInternationalDescription">
                <p v-for="erro in errors.ratePlanInternationalDescription" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-prefix">Prefixo *</label>
                <input type="text" name="ratePlanInternationalPrefix" id="input-prefix" class="form-control" v-model="int_plan.ratePlanInternationalPrefix">
                <p v-for="erro in errors.ratePlanInternationalPrefix" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            
            <div class="form-group col-3">
                <label for="input-rate">Valor *</label>
                <input type="text" name="rate" id="input-rate" class="form-control" v-model="int_plan.rate" v-money="int_plan.rate">
                <p v-for="erro in errors.rate" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-ratePlanDelay">Descarte *</label>
                <input type="text" name="ratePlanDelay" id="input-ratePlanDelay" class="form-control" v-model="int_plan.ratePlanDelay">
                <p v-for="erro in errors.ratePlanDelay" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-ratePlanInitial">Ciclo Inicial *</label>
                <input type="text" name="ratePlanInitial" id="input-ratePlanInitial" class="form-control" v-model="int_plan.ratePlanInitial">
                <p v-for="erro in errors.ratePlanInitial" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-ratePlanIncrement">Ciclo de Incremento *</label>
                <input type="text" name="ratePlanIncrement" id="input-ratePlanIncrement" class="form-control" v-model="int_plan.ratePlanIncrement">
                <p v-for="erro in errors.ratePlanIncrement" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>
import {VMoney} from 'v-money'
export default {
    props: ['int_plan', 'errors'],
 
    data () {
      return {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false
        }
      }
    },  
    
    directives: {money: VMoney},
}
</script>

<style>

</style>