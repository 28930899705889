<template>
    <div class="card" v-if="$canIf('iplans.update')">
        <form action="" method="post" @submit.prevent="editIntRatePlan" id="int-plan-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Plano Internacional</h4>
                </div>
            </div>
            <div class="card-body">
                <int-plan-form
                :errors="errors"
                :int_plan="int_plan"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'IntRatePlans.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  IntRatePlanForm from "@/components/views/intRatePlans/_Form";
export default {

    components: {
        'int-plan-form': IntRatePlanForm,
    },

    props: [
        'int_plan_id',
    ],

    created() {
        this.$httpApi.get('iplans/show/'+this.int_plan_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.int_plan = res.data.items
        })
    },

    data(){
        return {
            errors: {},
            int_plan: {},
        }
    },

    methods: {
        editIntRatePlan() {
            var dados = new FormData(document.getElementById('int-plan-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('iplans/update/'+this.int_plan_id, dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'IntRatePlans.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>