<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    props: [
        'customer_id'
    ],
}
</script>

<style>

</style>