<template>
        <div class="d-flex flex-wrap justify-content-start">

            <!-- <div class="form-group col-4">
                <label for="route-select" class="d-block">Rota *</label>
                <select name="idRoutes" id="route-select" class="form-control">
                    <option value="">Selecione uma opção</option>
                    <option v-for="route in routes" :value="route.idRoute" :key="route.idRoute" :selected="route.idRoute == plan.idRoutes ? true : false">{{route.routeDescription}}</option>
                </select>
            </div> -->

            <div class="form-group col-12">
                <label for="input-description">Descrição *</label>
                <input type="text" name="ratePlanDescription" id="input-description" class="form-control" v-model="plan.ratePlanDescription">
                <p v-for="erro in errors.ratePlanDescription" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-ratePlanVC1">VC1 *</label>
                <input type="text"  name="ratePlanVC1" id="input-ratePlanVC1" class="form-control" v-model="plan.ratePlanVC1" v-money="plan.ratePlanVC1">
                <p v-for="erro in errors.ratePlanVC1" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-ratePlanVC2">VC2 *</label>
                <input type="text" name="ratePlanVC2" id="input-ratePlanVC2" class="form-control" v-model="plan.ratePlanVC2" v-money="plan.ratePlanVC2">
                <p v-for="erro in errors.ratePlanVC2" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-ratePlanVC3">VC3 *</label>
                <input type="text" name="ratePlanVC3" id="input-ratePlanVC3" class="form-control" v-model="plan.ratePlanVC3" v-money="plan.ratePlanVC2" >
                <p v-for="erro in errors.ratePlanVC3" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-6">
                <label for="input-ratePlanLC">Local *</label>
                <input type="text" name="ratePlanLC" id="input-ratePlanLC" class="form-control" v-model="plan.ratePlanLC" v-money="plan.ratePlanLC">
                <p v-for="erro in errors.ratePlanLC" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-6">
                <label for="input-ratePlanLDN">Longa Distância *</label>
                <input type="text" name="ratePlanLDN" id="input-ratePlanLDN" class="form-control" v-model="plan.ratePlanLDN" v-money="plan.ratePlanLDN">
                <p v-for="erro in errors.ratePlanLDN" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-ratePlanDelay">Rate Start *</label>
                <input type="text" name="ratePlanDelay" id="input-ratePlanDelay" class="form-control" v-model="plan.ratePlanDelay">
                <p v-for="erro in errors.ratePlanDelay" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-ratePlanInitial">Rate Mínimo *</label>
                <input type="text" name="ratePlanInitial" id="input-ratePlanInitial" class="form-control" v-model="plan.ratePlanInitial">
                <p v-for="erro in errors.ratePlanInitial" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-ratePlanIncrement">Rate Cycle *</label>
                <input type="text" name="ratePlanIncrement" id="input-ratePlanIncrement" class="form-control" v-model="plan.ratePlanIncrement">
                <p v-for="erro in errors.ratePlanIncrement" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>
import {VMoney} from 'v-money'
export default {
    props: ['plan', 'errors'],
    
    data () {
      return {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false
        }
      }
    },  
    directives: {money: VMoney},
}
</script>

<style>

</style>