<template>
        <div class="form-group">
            <label for="input-description">Descrição *</label>
            <input type="message" name="customerGroupDescription" id="input-description" class="form-control" v-model="customer_group.customerGroupDescription">
            <p v-for="erro in errors.customerGroupDescription" :key="erro" class="text-danger">{{erro}}</p>
        </div>
</template>

<script>
export default {
    props: ['customer_group', 'errors'],
}
</script>

<style>

</style>