<template>
    <div>
         <div class="card">
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getReport">
                    <div class="row">
                        <div class="col-5 form-group">
                            <input type="text" class="form-control" id="input-source" name="word" v-model="pesquisa" placeholder="Procure por um trecho do número ou o número inteiro">
                        </div>
                         <div class="col-2 form-group" v-if="$store.state.admin">
                            <select name="searchBy" id="input-searchBy" class="form-control" v-model="searchBy" @change="getReport">
                                <option value="all" selected>Todos</option>
                                <option value="free">Livres</option>
                                <option value="alocated">Alocados</option>
                            </select>
                        </div>      
                         <div class="col-2 form-group" v-if="$store.state.admin">
                          <select name="carrier" id="input-carrier" class="form-control" @change="getReport" >
                                  <option value="" selected>Selecione uma Operadora</option>
                                <option  v-for="carrier in carriers" :key="carrier.idCarrier" :value="carrier.idCarrier" >{{ carrier.carrierName }}</option>
                            </select>
                            </div>        
                        <div class="col-md-3">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-end" v-if="$canIf('dids.index')">
            <h4>Lista de Dids</h4>
            <div class="d-flex">
                <router-link :to="{ name: 'Dids.Create' }" class="btn btn-success btn-sm mr-2">Cadastrar Did</router-link>
            </div>
        </div>
        <br>
        <div>
            <table class="table" v-if="$canIf('dids.index')">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Número</th>
                        <th v-if="$store.state.admin">Operadora</th>
                        <th>Cliente</th>
                        <th>Assinante</th>
                        <th>Chamada Reversa</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <tr v-for="(did, index) in dids" :key="did.idDid">

                        <td data-title="Id">{{did.idDid}}</td>
                        <td data-title="Número">{{did.did}}</td>
                        <td data-title="Operadora" v-if="$store.state.admin">{{did.carrierName}}</td>
                        <td data-title="Cliente" v-if="did.alocated != null">{{did.customersName}}</td>
                        <td v-else class="text-danger">N/A</td>
                        <td v-if="did.username">{{ did.username }}</td>
                        <td v-else class="text-danger">N/A</td>
                        <td v-if="did.reverseCall == 'y'" class="text-success" >Sim</td>
                        <td v-if="did.reverseCall == 'n'"></td>
                        <td class="acoes">
                            <router-link v-if="$canIf('dids.edit')" :to="{ name: 'Dids.Edit', params: {did_id: did.idDid}}" class="m-1 btn btn-sm bg-info" title="Editar Did"><i class="fas fa-edit"></i></router-link>
                            <!-- <button title="Deletar" class="m-1 btn btn-sm bg-danger" @click.prevent="deleteDid(did.idDid)">Deletar</button> -->
                            <button v-if="$canIf('dids.delete')" title="Deletar Did" class="m-1 btn btn-sm bg-danger" @click="showConfirmDeleteModal(did.idDid), getCustomers" ><i class="fas fa-trash-alt"></i></button>
                            <button v-if="did.alocated == null && $canIf('dids.alocated')" @click="getCustomers" title="Vincular Cliente" class="m-1 btn btn-sm bg-success" @click.prevent="openModalCustomerSearch(index), getCustomers"><i class="fas fa-user-plus"></i></button>
                            <button v-if="did.alocated != null && $canIf('dids.dealocated')" title="Desvincular Cliente"  class="m-1 btn btn-sm bg-warning" @click.prevent="deAlocate(did)"><i class="fas fa-user-minus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <confirm-delete v-if="$canIf('dids.delete')"
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Did?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>

        <div id="modalCustomerSearch" class="modal-fundo" @click.self="closeModalCustomerSearch" v-if="$canIf('dids.alocated')">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="getCustomers" enctype="multipart/form-data" id="form-search-customer">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Vinculação de cliente ao did</h4>
                            <span class="btn-fechar" @click="closeModalCustomerSearch">x</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="customer-search" class="form-label">Digite o nome do cliente:</label> 
                            <input type="text" id="customer-search" class="form-control" placeholder="Digite o nome do cliente para iniciar a pesquisa" name="nome" v-model="pesquisa" v-on:keyup="searchCustomerByName">
                        </div>
                    </div>
                    <div class="card-footer" >
                        <div class="scroll-modal" >
                         <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>E-Mail</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela" >
                    <tr v-for="customer in customersList" :key="customer.idCustomers">
                        <td data-title="Id">{{customer.idCustomers}}</td>
                        <td data-title="Nome">{{customer.customersName}}</td>
                        <td data-title="Contato">{{customer.customersEmail}}</td>
                        <td class="acoes">
                              <form action="" method="put" @submit.prevent="alocate(customer.idCustomers, did_customer.idDid)" enctype="multipart/form-data" id="form-alocate">      
                                <button title="Vincular Did ao Cliente" class="m-1 btn btn-sm bg-success" @click="closeModalCustomerSearch"><i class="fas fa-plus-circle"></i></button>
                             </form>
                         </td>
                        </tr>
                     </tbody>
                 </table>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {

    components: { ConfirmDelete},
    created() {
        this.getCustomers(); 
        this.getReport();
        this.getCarriers();
    },

    data() {
        return {
            dids: [],
            carriers: [],
            did_customer: '',
            customers: [],
            customersList: [], 
            pesquisa: '', 
            searchBy: 'all', 
            carrier: '', 
            isConfirmDeleteModalVisible: false,
            did: "",
        }
    },

    methods: {
        getDids() {
            this.$httpApi.get('dids',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.dids = res.data.items;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        getReport(){
            this.$httpApi.post('dids/search', {word: this.pesquisa, searchBy: this.searchBy, carrier: this.carrier}, {
                headers: {
                    "Authorization" : "Bearer "+sessionStorage.getItem('Auth_Token'),
                }
            }).then(res => {
                this.dids = res.data.items;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            }) 
        },

        getCarriers(){ 
               this.$httpApi.get('operadoras',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.carriers = res.data.items
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        }, 

        deleteDid(id) {
            this.$httpApi.delete('dids/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getDids()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        alocate(idCustomers, idDid) {
            this.$httpApi.put('dids/alocated/'+ idDid,{idCustomer: idCustomers},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getDids()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getDids()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        deAlocate(did) {
            this.$httpApi.put('dids/deAlocated/'+did.idDid,{idCustomer: did.alocated},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getDids()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getDids()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        getCustomers(){ 
           this.$httpApi.get('customers/withoutPagination',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.customers = res.data.items;      
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        searchCustomerByName(){ 
            var convertToLowerCase = ''; 
            var i; 
            var result = []; 
            result.pop(); 
            for(i = 0; i < this.customers.length; i++){ 
            convertToLowerCase =  this.customers[i].customersName.toLowerCase(); 
               if(convertToLowerCase.includes(this.pesquisa)){ 
                    result.push(this.customers[i]); 
               }               
            }                           
            this.customersList = result; 
        }, 

        openModalCustomerSearch(index) {
            document.getElementById('modalCustomerSearch').style = "display: block;"
            if (typeof index == 'number') {
                this.did_customer = this.dids[index];
            }
        },

        closeModalCustomerSearch() {
            document.getElementById('modalCustomerSearch').style = "display: none;"
        },

        openModalCustomer() {
            document.getElementById('modalCustomer').style = "display: block;"
        },

        closeModalCustomer() {
            document.getElementById('modalCustomer').style = "display: none;"
        },

        backModal() {
            this.closeModalCustomer();
            this.openModalCustomerSearch();
        },

        clearSearch(){
            var values = document.querySelectorAll('.form-control');
            values.forEach(input => {
                input.value = "";
            })
            this.getDids();
        },

        showConfirmDeleteModal(id) {
            this.did = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.did = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteDid(this.did);
            this.closeConfirmDeleteModal();
        },
    }

}
</script>

<style>

</style>