<template>
        <div class="d-flex flex-wrap justify-content-start">

            <div class="form-group col-4">
                <label for="input-VC1">VC1 *</label>
                <input type="text" name="rateCostPriceVC1" id="input-VC1" class="form-control" v-model="rateCost.rateCostPriceVC1" v-money="rateCost.rateCostPriceVC1">
                <p v-for="erro in errors.rateCostVC1" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-VC2">VC2 *</label>
                <input type="text" name="rateCostPriceVC2" id="input-VC2" class="form-control" v-model="rateCost.rateCostPriceVC2" v-money="rateCost.rateCostPriceVC2">
                <p v-for="erro in errors.rateCostPriceVC2" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-VC3">VC3 *</label>
                <input type="text" name="rateCostPriceVC3" id="input-VC3" class="form-control" v-model="rateCost.rateCostPriceVC3" v-money="rateCost.rateCostPriceVC3">
                <p v-for="erro in errors.rateCostPriceVC3" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-6">
                <label for="input-LC">Local *</label>
                <input type="text" name="rateCostPriceLC" id="input-LC" class="form-control" v-model="rateCost.rateCostPriceLC" v-money="rateCost.rateCostPriceLC">
                <p v-for="erro in errors.rateCostPriceLC" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-6">
                <label for="input-LDN">Longa Distância *</label>
                <input type="text" name="rateCostPriceLDN" id="input-LDN" class="form-control" v-model="rateCost.rateCostPriceLDN" v-money="rateCost.rateCostPriceLDN">
                <p v-for="erro in errors.rateCostPriceLDN" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-Delay">Rate Start *</label>
                <input type="text" name="rateCostDelay" id="input-Delay" class="form-control" v-model="rateCost.rateCostDelay">
                <p v-for="erro in errors.rateCostDelay" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-Initial">Rate Mínimo *</label>
                <input type="text" name="rateCostInitial" id="input-Initial" class="form-control" v-model="rateCost.rateCostInitial">
                <p v-for="erro in errors.rateCostInitial" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-Increment">Rate Cycle *</label>
                <input type="text" name="rateCostIncrement" id="input-Increment" class="form-control" v-model="rateCost.rateCostIncrement">
                <p v-for="erro in errors.rateCostIncrement" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>
import {VMoney} from 'v-money'
export default {
    props: ['rateCost', 'errors'],

     data () {
      return {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false
        }
      }
    },  

    directives: {money: VMoney},
}
</script>

<style>

</style>