<template>
    <div>
        <div class="card" v-if="$canIf('customers.index')">
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getReport">
                    <div class="row">
                        <div class="col-8 form-group">
                            <input type="text" class="form-control" id="input-source" name="word" placeholder="Procure por nome ou email">
                        </div>
                    
                        <div class="col-md-4">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                            <button class="btn bg-secondary btn-sm mr-2 ml-2" @click.prevent="clearSearch">Limpar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-end" v-if="$canIf('customers.index')">
            <h4>Lista de Clientes</h4>
            <div class="d-flex" v-if="$canIf('customers.store')" >
                <router-link :to="{ name: 'Users.Create' }" class="btn btn-success btn-sm">Cadastrar Cliente</router-link>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Vencimento da Fatura</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="customer in customers.data" :key="customer.idCustomers" :class="customer.deleted_at ? 'alert alert-primary' : '' ">

                        <td data-title="Id">{{customer.idCustomers}}</td>
                        <td data-title="Nome">{{customer.customersName}}</td>
                        <td data-title="Email">{{ customer.customersDateInvoice | formatDate }}</td>
                        <td class="acoes" v-if="customer.deleted_at == null">
                            <router-link v-if="$canIf('subscribers.index')" :to="{ name: 'Subscribers.Index', params: {customer_id: customer.idCustomers}}" class="m-1 btn btn-sm bg-primary" title="Assinantes"><i class="fas fa-users"></i></router-link>
                            <router-link v-if="$canIf('customers.edit')" :to="{ name: 'Customers.Edit', params: {customer_id: customer.idCustomers}}" class="m-1 btn btn-sm bg-info" title="Editar Cliente"><i class="fas fa-edit"></i></router-link>
                            <!-- <button title="Deletar" class="m-1 btn btn-sm bg-danger" @click.prevent="deleteCustomer(customer.idCustomers)"><i></i>Deletar</button> -->
                            <button v-if="$canIf('customers.delete')" title="Deletar Cliente" class="m-1 btn btn-sm bg-danger" @click="showConfirmDeleteModal(customer.idCustomers)"><i class="fas fa-trash-alt"></i></button>
                            <router-link v-if="$canIf('dids.index')" title="DIDs" :to="{ name: 'Customers.Dids', params: {customer_id: customer.idCustomers} }" class="m-1 btn btn-sm bg-warning"><i class="fas fa-phone"></i></router-link>
                        </td>
                        <td class="acoes" v-else>
                            <a title="Ativar Cliente" class="m-1 btn btn-sm bg-warning" @click.prevent="activateCustomer(customer.idCustomers)"><i class="fas fa-plus"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <confirm-delete v-if="$canIf('customers.delete')"
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Cliente?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>

        <div id="modalDid" class="modal-fundo" @click.self="closeModalDid" v-if="$canIf('dids.alocated')">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="alocate(did_customer.idCustomers)" enctype="multipart/form-data" id="form-alocate">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Vinculação de cliente ao did</h4>
                            <span class="btn-fechar" @click="closeModalDid">x</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <p>Esses são os clientes compatíveis com a sua pesquisa</p> -->
                        <div class="form-group">
                            <label for="did" class="form-label">Dids não cadastrados ao cliente:</label>
                            <select name="idDid" id="did" class="form-control">
                                <option value="">Selecione um número</option>
                                <option v-for="did in dids" :key="did.idDid" :value="did.idDid">{{did.did}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <button class="btn bg-success btn-sm" @click="closeModalDid">Cadastrar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <hr>
        <div>
            <pagination :data="customers" v-on:pagination-change-page="getCustomers"/>
        </div>
    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: {
        ConfirmDelete
    },
    created() {
        this.getCustomers();
    },

    data() {
        return {
            customers: {},
            dids: [],
            did_customer: {},
            isConfirmDeleteModalVisible: false,
            customer: "",
        }
    },

    methods: {
        getCustomers(page) {
            this.$httpApi.get('customers',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
                params: {
                    page: page
                }
            }).then(res => {
                this.customers = res.data.items;

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        getReport(page) {
            var dados = new FormData(document.getElementById('form-search'))
            this.$httpApi.post('customers/search', dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
                params: {
                    page: page
                }
            }).then(res => {
                this.customers = res.data.items;

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteCustomer(id) {
            this.$httpApi.delete('customers/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getCustomers()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
            this.closeConfirmDeleteModal();
        },

        activateCustomer(id){
            this.$httpApi.put('/customers/restore/'+id, '', {
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomers()
                this.$store.dispatch('criarMensagem', [
                    res.data.message,
                    "success"
                ])
            }).catch(res => {
                this.getCustomers()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
            this.closeConfirmDeleteModal();
        },

        alocate(id) {
            var dados = new FormData(document.getElementById('form-alocate'));

            this.$httpApi.put('dids/alocated/'+dados.get('idDid'),{idCustomer: id},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomers()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getDids()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        openModalDid(index) {
            this.dids = [];
            if (typeof index == 'number') {
                this.did_customer = this.customers.data[index];
            }
            this.$httpApi.get('dids',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                res.data.items.forEach(did => {
                    if (did.alocated != this.did_customer.idCustomers) {
                        this.dids.push(did);
                    }
                });
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
            document.getElementById('modalDid').style = "display: block;"
        },

        closeModalDid() {
            document.getElementById('modalDid').style = "display: none;"
        },

        clearSearch(){
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = '';
            })
            this.getCustomers();
        },

        showConfirmDeleteModal(id) {
            this.customer = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.customer = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteCustomer(this.customer);
        },
    }

}
</script>

<style>

</style>