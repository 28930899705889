<template>
    <div>
        <div class="card" v-if="$canIf('customergroups.show')" >
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-start pb-3">
                    <h5 class="m-0">Clientes vinculados ao grupo: {{group.customerGroupDescription}}</h5>
                    <router-link v-if="$canIf('customergroups.addcustomer')" :to="{ name: 'CustomerGroups.AddCustomers' }" class="btn bg-success btn-sm m-0">Adicionar Clientes</router-link>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="customer in group.customers">
                            <tr v-if="!index.includes(customer.idCustomers)" :key="customer.idCustomers">
                                <td>{{customer.idCustomers}}</td>
                                <td>{{customer.customersName}}</td>
                                <td>
                                    <button v-if="$canIf('customergroups.remcustomer')" class="btn bg-secondary btn-sm" @click="addIndex(customer.idCustomers)">Remover</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card" v-if="index.length > 0">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center pb-3">
                    <h5 class="m-0">Clientes que serão desvinculados</h5>
                    <button class="btn bg-info btn-sm m-0" @click="removeCustomers">Salvar</button>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="customer in group.customers">
                            <tr :key="customer.idCustomers" v-if="index.includes(customer.idCustomers)">
                                <td>{{customer.idCustomers}}</td>
                                <td>{{customer.customersName}}</td>
                                <td>
                                    <button class="btn bg-danger btn-sm" @click="removeIndex(customer.idCustomers)">Cancelar</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    created() {
        this.getCustomers();
    },

    props: ['group_id'],

    data() {
        return {
            errors: {},
            group: {},
            customers: [],
            index: [],
        }
    },

    methods: {
        getCustomers() {
            this.index = [];
            this.$httpApi.get('customergroups/show/'+this.group_id, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.group = res.data.item;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        hasCustomer(id) {
            var existe = false;
            this.group.customers.forEach(customer => {
                if (customer.idCustomers == id) {
                    existe = true;
                }
            });
            return existe
        },

        addIndex(id) {
            if (this.index.includes(id)) {
                return
            }
            this.index.push(id);
        },

        removeIndex(id) {
            if (!this.index.includes(id)) {
                return
            }
            const position = this.index.indexOf(id);
            if (position > -1) {
                this.index.splice(position, 1);
            }
        },

        removeCustomers() {
            this.$httpApi.delete('customergroups/remcustomer/'+this.group_id, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
                data: this.index,
            }).then(res => {
                this.getCustomers();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ]);
            }).catch(res => {
                this.getCustomers()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        }
    }
}
</script>

<style>

</style>