<template>
    <div class="row">
        <div class="form-group col-9">
            <label for="input-name">Nome *</label>
            <input type="text" name="customersName" id="input-name" class="form-control" :value='customer.customersName'>
            <p v-for="erro in errors.customersName" :key="erro" class="text-danger">{{erro}}</p>
        </div>
        <div class="form-group col-3">
            <label for="input-date">DateInvoice *</label>
            <input type="date" name="customersDateInvoice" id="input-date" class="form-control" :value="customer.customersDateInvoice">
            <p v-for="erro in errors.customersDateInvoice" :key="erro" class="text-danger">{{erro}}</p>
        </div>

    </div>
</template>

<script>
export default {
    props: ['customer', 'errors'],
}
</script>

<style>

</style>