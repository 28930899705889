<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="pb-3">
                    <form action="" method="post" id="form-search" class="d-flex justofy-content-between align-items-center" @submit.prevent="searchCustomers">
                    <!-- <h5 class="m-0">Clientes disponíveis</h5> -->
                        <div class="d-flex justofy-content-between align-items-end mb-3 border-bottom">
                            <p class="m-0 mr-2" style="margin-bottom: 3px !important">Pesquisar por: </p>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="select-choice" id="radio-idCustomers">
                                <label class="form-check-label p-0" for="radio-id">Id</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="select-choice" id="radio-customersName">
                                <label class="form-check-label p-0" for="radio-name">Nome</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="select-choice" id="radio-email">
                                <label class="form-check-label p-0 " for="radio-email">Email</label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center flex-grow-1">
                            <i class="fas fa-sort-numeric-down"></i>
                            <input type="text" placeholder="Id" class="ml-2 form-control" id="input-idCustomers">
                        </div>
                        <div class="d-flex align-items-center flex-grow-1 ml-3 mr-3">
                            <i class="fas fa-user"></i>
                            <input type="text" placeholder="Nome" class="form-control" id="input-customersName">
                        </div>
                        <div class="d-flex align-items-center flex-grow-1">
                            <i class="fas fa-at"></i>
                            <input type="text" placeholder="Email" class="form-control" id="input-email">
                        </div>
                        <div class="d-flex ml-2">
                            <button class="btn bg-info btn-sm m-0 mr-2" @click.prevent="cleanSearch">Limpar</button>
                            <button class="btn bg-info btn-sm m-0">Pesquisar</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th class="acoes">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="customer in customers.data">
                            <tr :key="customer.idCustomers" v-if="!group.customersId.includes(customer.idCustomers) && !hasOnIndex(customer)">
                                <td>{{customer.idCustomers}}</td>
                                <td>{{customer.customersName}}</td>
                                <td class="acoes">
                                    <button v-if="$canIf('customergroups.addcustomer')" class="btn btn-sm btn-success" @click="addIndex(customer)">Adicionar</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between">

                    <router-link class="btn bg-danger btn-sm" :to="{ name: 'CustomerGroups.Customers' }">Voltar</router-link>

                    <pagination :data="customers" v-on:pagination-change-page="getCustomers" v-if="!searching"/>

                    <button v-if="$canIf('customergroups.addcustomer')"  class="btn bg-warning btn-sm" @click="openModalCSV">Importar CSV</button>

                </div>
            </div>
        </div>
        
        <div class="card" v-if="index.length > 0">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center pb-3">
                    <h5 class="m-0">Clientes que serão vinculados</h5>
                    <button v-if="$canIf('customergroups.addcustomer')" class="btn bg-info btn-sm m-0" @click="addCustomers">Salvar</button>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="customer in index">
                            <!-- <tr :key="customer.idCustomers">
                                <td>ola mundo</td>
                            </tr> -->
                            <tr :key="customer.idCustomers" >
                                <td>{{customer.idCustomers}}</td>
                                <td>{{customer.customersName}}</td>
                                <td>
                                    <button class="btn bg-danger btn-sm" @click="removeIndex(customer)">Cancelar</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div id="modalCSV" class="modal-fundo" @click.self="closeModalCSV">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="importFile" enctype="multipart/form-data" id="formFile">
                    <div class="card-header">
                        <h4>Importação de arquivo CSV</h4>
                        <span class="btn-fechar" @click="closeModalCSV">x</span>
                    </div>
                    <div class="card-body">
                        <label for="formFile" class="form-label">Arquivo (Formato CSV apenas):</label>
                        <input class="form-control" type="file" name="file">
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <button class="btn bg-danger btn-sm" @click.prevent="closeModalCSV">Fechar</button>
                            <button class="btn bg-success btn-sm" @click="closeModalCSV">Importar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    created() {
        this.getCustomers();
    },

    props: ['group_id'],

    data() {
        return {
            errors: {},
            group: {},
            customers: {},
            index: [],
            searching: false,
        }
    },

    methods: {
        getCustomers(page) {
            this.$httpApi.get('customergroups/show/'+this.group_id, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.group = res.data.item
                this.group.customersId = [];
                res.data.item.customers.forEach(customer => {
                    this.group.customersId.push(customer.idCustomers)
                });
            })

            this.$httpApi.get('customers', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
                params: {
                    page: page
                }
            }).then(res => {
                this.customers = res.data.items
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
        hasCustomer(id) {
            var existe = false;
            this.group.customers.forEach(customer => {
                if (customer.idCustomers == id) {
                    existe = true;
                }
            });
            return existe
        },

        hasOnIndex(customer) {
            var existe = false
            this.index.forEach(element => {
                if (customer.idCustomers == element.idCustomers) {
                    existe = true;
                }
            });
            if (existe) {
                return true
            }
        },

        addIndex(customer) {
            if (this.hasOnIndex(customer)) {
                return
            }
            this.index.push(customer);
        },

        removeIndex(customer) {
            if (!this.hasOnIndex(customer)) {
                return
            }
            const position = this.index.indexOf(customer);
            if (position > -1) {
                this.index.splice(position, 1);
            }
        },

        addCustomers() {
            var indexId = [];
            this.index.forEach(customer => {
                indexId.push(customer.idCustomers);
            });
            this.$httpApi.post('customergroups/addcustomer/'+this.group_id, indexId, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomers();
                this.index = [];
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ]);
            }).catch(res => {
                this.getCustomers()
                this.index = [];
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        searchCustomers() {
            var filtro = document.querySelectorAll('input[name=select-choice]');
            filtro.forEach(input => {
                if (input.checked) {
                    filtro = input.getAttribute('id').substring(6);
                    return;
                }
            });
            var value = document.getElementById('input-'+filtro).value;
            var dados = {
                key: filtro,
                value: value,
            }
            this.$httpApi.post('customers/search', dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.searching = true
                this.customers.data = res.data.dados
            }).catch(() => {
                this.searching = false;
            });
        },

        cleanSearch() {
            var filtro = document.querySelectorAll('input[name=select-choice]');
            filtro.forEach(input => {
                if (input.checked) {
                    input.checked = false;
                    return;
                }
            });
            var values = document.querySelectorAll('.form-control')
            values.forEach(input => {
                input.value = '';
            })
            this.getCustomers();
            this.searching = false;
        },

        openModalCSV() {
            document.getElementById('modalCSV').style = "display: block;"
            // document.getElementById('modalCSV').classList.add('fade-in')
        },

        closeModalCSV() {
            document.getElementById('modalCSV').style = "display: none;"
            // document.getElementById('modalCSV').classList.add('fade-out')
        },

        importFile() {
            var inputFile = document.getElementById('formFile');
            inputFile = new FormData(inputFile);

            this.$httpApi.post('customergroups/import/'+this.group_id, inputFile, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomers();
                this.index = [];
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ]);
            }).catch(res => {
                this.getCustomers()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        }
    }
}
</script>

<style scoped>

.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

</style>