<template>
    <div class="card" v-if="$canIf('dids.edit')">
        <form action="" method="post" @submit.prevent="editDid" id="did-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Did</h4>
                </div>
            </div>
            <div class="card-body">
                <did-form
                :errors="errors"
                :did="did"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
import  DidForm from "@/components/views/dids/_Form";
export default {

    components: {
        'did-form': DidForm,
    },

    props: [
        'did_id',
    ],

    created() {
        this.$httpApi.get('dids/'+this.did_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.did = res.data.items
        })
    },

    data(){
        return {
            errors: {},
            did: {},
        }
    },

    methods: {
        editDid() {
            var dados = new FormData(document.getElementById('did-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('dids/'+this.did_id, dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Dids.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>