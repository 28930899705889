<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <h3>Simulador de Tarifas</h3>
      </div>
      <br />
      <div class="pb-3">
        <form
          action=""
          method="post"
          id="form-call-simulator"
          class="d-flex flex-column m-1"
          @submit.prevent="calcularTarifa"
        >
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="input-customer">Cliente</label>
              <select
                name="idCustomer"
                v-model="idCustomer"
                id="input-customer"
                class="form-control"
                @change="getSubscribers"
              >
                <option value="">Selecione um cliente</option>
                <option
                  v-for="customer in customers"
                  :key="customer.idCustomers"
                  :value="customer.idCustomers"
                >
                  {{ customer.customersName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="input-subscribers">Assinantes</label>
              <select
                name="subscriber"
                id="input-subscribers"
                class="form-control"
                @change="removerTabela"
              >
                <option value="">Selecione um Subscriber</option>
                <option v-for="subscriber in subscribers" :key="subscriber.id">
                  {{ subscriber.username }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label for="input-number">Número</label>
              <input
                name="number"
                type="number"
                placeholder="Número do Telefone"
                id="input-number"
                class="form-control"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center mr-4">
            <button class="btn bg-success btn-sm">Calcular</button>
          </div>
        </form>
      </div>
    </div>
    <div class="card-body" v-if="items">
      <h5>Resultados</h5>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Número</th>
              <th>Plano</th>
              <th v-if="items.items">Tarifa</th>
              <th>Custo</th>
              <th>Venda</th>
              <th v-if="items.items">Tronco</th>
            </tr>
          </thead>
          <tbody v-if="items.items">
            
            <tr v-for="item in items.items" :key="item.tronco">
              <td>{{ item.numero }}</td>
              <td>{{ item.plano }}</td>
              <td>{{ item.tarifa_aplicada }}</td>
              <td>
                {{
                  item.custo | currency("R$ ", 2, { decimalSeparator: "," })
                }}
              </td>
              <td>
                {{
                  item.venda | currency("R$ ", 2, { decimalSeparator: "," })
                }}
              </td>
              <td>{{ item.tronco }}</td>
            </tr>
          </tbody>

           <tbody v-if="items.troncos">
            <tr>
              <td>{{ items.numero }}</td>
              <td>{{ items.plano }}</td>
              <td>
                {{
                  items.custo | currency("R$ ", 2, { decimalSeparator: "," })
                }}
              </td>
              <td>
                {{
                  items.venda | currency("R$ ", 2, { decimalSeparator: "," })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card-footer" v-if="items.troncos">
      <h5>Troncos</h5>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Prioridade</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tronco in items.troncos" :key="tronco.name">
              <td>{{ tronco.name }}</td>
              <td>{{ tronco.priority }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  created() {
    this.getCustomers();
  },

  data() {
    return {
      customers: [],
      subscribers: [],
      idCustomer: "",
      items: {},
    };
  },

  methods: {
    getCustomers() {
      this.$httpApi
        .get("customers", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("Auth_Token"),
          },
        })
        .then((res) => {
          this.customers = res.data.items.data;
        })
        .catch((res) => {
          if (res.response.data.errors) {
            this.errors = res.response.data.errors;
          }
          this.$store.dispatch("exibeErro", [res.response]);
        });
    },

    getSubscribers() {
      this.$httpApi
        .get("subscribers/" + this.idCustomer + "/byCustomer", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("Auth_Token"),
          },
        })
        .then((res) => {
          this.subscribers = res.data.items;
        })
        .catch((res) => {
          if (res.response.data.errors) {
            this.errors = res.response.data.errors;
          }
          this.$store.dispatch("exibeErro", [res.response]);
        });
    },

    calcularTarifa() {
      this.items = null;
      var inputFile = document.getElementById("form-call-simulator");
      var dados = new FormData(inputFile);
      this.$httpApi
        .post("services/callSimulation", dados, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("Auth_Token"),
          },
        })
        .then((res) => {
          this.removerTabela();
          this.items = res.data;
          this.$store.dispatch("criarMensagem", [
            res.data.message,
            "success",
          ]);
        })
        .catch((res) => {
          if (res.response.data.errors) {
            this.errors = res.response.data.errors;
          }
          this.$store.dispatch("exibeErro", [res.response]);
        });
    },

    removerTabela() {
      this.items = null;
    },
  },
};
</script>

<style>
</style>