<template>
    <!-- <div id="app"> -->
        <router-view/>
    <!-- </div> -->
</template>

<script>

// import '../layout/assets/js/core/bootstrap.min.js'
import '../layout/assets/js/core/jquery.min.js'
import '../layout/assets/js/core/popper.min.js'
import '../layout/assets/js/plugins/perfect-scrollbar.jquery.min.js'
import '../layout/assets/js/core/bootstrap.min.js'

export default {
    // name: 'App',
}

</script>

<style>

@import '../layout/assets/css/bootstrap.min.css';
@import '../layout/assets/css/now-ui-dashboard.css';

.card-header {
    background-color: #f4f4f4 !important;
}

.card-footer {
    border-top: solid 1px #eee !important;
}

.card {
    border: solid 1px #ddd !important;
}

.form-control{
    border-color: #999;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #ddd;
    color: #777;
}

.form-group label{
    font-weight: bold !important;
    font-size: 100%;
}

.bg-brd {
    background-color: #213e62;
}
.text-brd {
    color: #213e62;
}
.border-brd {
    border: 1px solid #213e62;
}

/* ////////////// CORES DA SIDEBAR ////////////// */
.sidebar[data-color="brd"]:after,
.off-canvas-sidebar[data-color="brd"]:after {
  background: #fff;
  color: #213e62 !important;
}

.sidebar[data-color="brd"] .nav li.active>a,
.off-canvas-sidebar[data-color="brd"] .nav li.active>a {
  /* color: #213e62 !important; */
}

/* ///////////////////////////////////////////// */

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.acoes {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 5px;
}


.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;

    animation-name: modal;
    animation-duration: 700ms;

}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

.scroll-modal { 
   max-height: 500px; 
    overflow: scroll;
}

@keyframes modal {
    from {
        top: 30%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
    }
}

.alert.alert-success {
    background: #4eaa42 !important;
}

.alert.alert-danger {
    background: #c31c1c !important;
}

.alert.alert-warning {
    background: #e9b232 !important;
}
</style>
