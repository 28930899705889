<template>
    <div>
        <router-view></router-view>
    </div>
    <!-- <div class="card">
        <div class="card-header">
            <div class="d-flex justify-content-between">
                <h3>Planos Internacionais</h3>
                <router-link :to="{ name: 'IntRoutes.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </div>
        <div class="card-body">
            <router-view></router-view>
        </div>
    </div> -->
</template>

<script>
export default {
    // props: [
    //     'route_id'
    // ],
}
</script>

<style>

</style>