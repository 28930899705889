<template>
    <div>
        <div class="d-flex flex-wrap">

            <div class="form-group col-4">
                <label for="input-auth_type">Tipo de autenticação *</label>
                <select name="auth_type" id="input-auth_type" class="form-control" v-model="subscriber.auth_type">
                    <option value="">Escolha uma opção</option>
                    <option value="ip" :selected="subscriber.auth_type == 'ip' ? true : false">ip</option>
                    <option value="ip_tech" :selected="subscriber.auth_type == 'ip_tech' ? true : false">ip_tech</option>
                    <option value="username" :selected="subscriber.auth_type == 'username' ? true : false">username</option>
                </select>
                <p v-for="erro in errors.auth_type" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-username">Username *</label>
                <input type="text" name="username" id="input-username" class="form-control" v-model="subscriber.username" :readonly="edit == true">
                <p v-for="erro in errors.username" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-secret">Senha *</label>
                <input type="text" name="secret" id="input-secret" class="form-control" v-model="subscriber.secret">
                <p v-for="erro in errors.secret" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-channels">Canais *</label>
                <input type="text" name="channels" id="input-channels" class="form-control" v-model="subscriber.channels">
                <p v-for="erro in errors.channels" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-5">
                <label for="input-context">Contexto *</label>
                <input type="text" name="context" id="input-context" class="form-control" value="clientes" readonly>
                <p v-for="erro in errors.context" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-local_area">Area Local*</label>
                <input type="text" name="local_area" id="input-local_area" class="form-control" v-model="subscriber.local_area">
                <p v-for="erro in errors.local_area" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-outbound_cid">Identificador de Chamada <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="top" title="É necessário informar o número identificador de chamadas válido."></i></label>
                <input type="text" name="outbound_cid" id="input-outbound_cid" class="form-control" v-model="subscriber.outbound_cid">
                <p v-for="erro in errors.outbound_cid" :key="erro" class="text-danger">{{erro}}</p>
            </div>

              <div class="form-group col-4" v-if="!edit">
                <label for="input-nPlan">Plano Nacional</label>
                <select name="nPlan" id="input-nPLan" class="form-control" v-model="subscriber.nPlan">
                    <option value="">Escolha um plano</option>
                    <option :value="nPlan.idRatePlan" :selected="subscriber.nPlan == nPlan.idRatePlan ? true : false" v-for="nPlan in nPlans" :key="nPlan.idRatePlan">{{ nPlan.ratePlanDescription }}</option>
                </select>
                <p v-for="erro in errors.auth_type" :key="erro" class="text-danger">{{erro}}</p>
            </div>
              <div class="form-group col-4" v-if="!edit">
                <label for="input-intPlan">Plano Internacional</label>
                <select name="intPlan" id="input-intPlan" class="form-control" v-model="subscriber.intPlan">
                    <option value="">Escolha um plano</option>
                    <option :value="intPlan.idRoute" :selected="subscriber.intPlan == intPlan.idRoute ? true : false" v-for="intPlan in intPlans" :key="intPlan.idRoute">{{ intPlan.routeDescription }}</option>
                </select>
                <p v-for="erro in errors.auth_type" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4" v-if="subscriber.auth_type == 'ip_tech'">
                <label for="input-techprefix">Tech prefix</label>
                <input type="text" name="techprefix" id="input-techprefix" class="form-control" v-model="subscriber.techprefix">
                <p v-for="erro in errors.techprefix" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
        <div v-if="subscriber.auth_type == 'ip' || subscriber.auth_type == 'ip_tech'" class="d-flex flex-wrap">
            <div class="col-12">
                <hr>
                <h4>Configurações Ip</h4>
            </div>
            <div class="form-group col-4">
                <label for="input-ipType">Tipo de Ip</label>
                <select name="ipType" id="input-ipType" class="form-control" v-model="ip_config.ipType">
                    <option :value="undefined">Selecione uma opção</option>
                    <option value="v4" :selected="ip_config.ipType == 'v4' ? true : false">Ipv4</option>
                    <option value="v6" :selected="ip_config.ipType == 'v6' ? true : false">Ipv6</option>
                </select>
                <p v-for="erro in errors.ipType" :key="erro" class="text-danger">{{erro}}</p>
            </div>
            <template v-if="ip_config.ipType != undefined">
                <div class="form-group col-4" v-if="ip_config.ipType == 'v4'">
                    <label for="input-ipaddress">Endereço Ip (Ipv4)</label>
                    <input type="text" name="ipaddress" id="input-ipaddress" class="form-control" v-model="ip_config.ipaddress"  placeholder="000.000.000.000">
                    <p v-for="erro in errors.ipaddress" :key="erro" class="text-danger">{{erro}}</p>
                </div>
                <div class="form-group col-4" v-if="ip_config.ipType == 'v6'">
                    <label for="input-ipaddress">Endereço Ip (Ipv6)</label>
                    <input type="text" name="ipaddress" id="input-ipaddress" class="form-control" v-model="ip_config.ipaddress" placeholder="0000.0000.0000.0000.0000.0000.0000.0000">
                    <p v-for="erro in errors.ipaddress" :key="erro" class="text-danger">{{erro}}</p>
                </div>
                <div class="form-group col-4">
                    <label for="input-ipmask" title="Inserir número inteiro ex.: Para máscaras /24, inserir somente 24.">Máscara Ip</label>
                    <input type="number" name="ipmask" id="input-ipmask" class="form-control" placeholder="Ex.: Para máscaras /24, inserir somente 24." v-model="ip_config.ipmask" >
                    <p v-for="erro in errors.ipmask" :key="erro" class="text-danger">{{erro}}</p>
                </div>
                <div class="form-group col-4">
                    <label for="input-port">Porta</label>
                    <input type="text" name="port" id="input-port" class="form-control" v-model="ip_config.port">
                    <p v-for="erro in errors.port" :key="erro" class="text-danger">{{erro}}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: ['subscriber', 'ip_config' ,'errors', 'edit'],

    created(){ 
        this.getIntPlans();
        this.getNPlans();
    },

    data() { 
        return {
            intPlans: {}, 
            nPlans: {}, 
        };  
    },

    methods: { 
        getIntPlans(){ 
              this.$httpApi.get('routesIntl', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => { 
                this.intPlans = res.data.items; 
            })
        }, 

        getNPlans(){ 
            this.$httpApi.get('nplans', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => { 
                this.nPlans = res.data.items;
            })
        }, 
    }
    // mounted() {
    //     this.auth_type = this.subscriber.auth_type;
    // },

}
</script>

<style>

</style>