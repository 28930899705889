<template>
    <div class="card" v-if="$canIf('permissions.attPermission')">
        <form action="" method="post" v-on:submit.prevent="attPermissions" id="form-permissions">
            <div class="d-flex justify-content-between card-header">
                <h5>Atribuir Permissões ao Cargo: <strong>{{role.name}}</strong></h5>
                <button class="btn btn-success btn-sm justfy-content-end" data-toggle="tooltip" data-placement="top" 
                title="Atualiza os vínculos de permissões com os grupos, caso as permissões não estejam listadas abaixo ou exista alguma permissão sem vínculo."
                 @click="refreshPermissions" >Atualizar Vínculo com Grupos <i class="fas fa-sync-alt"></i></button>
            </div>
            <br>
            <div class="d-flex flex-wrap justify-content-center card-body">
                <card-permission 
                v-for="group in groups"
                :key="group.id"
                :group="group"
                :permissions="role.permissions"
                style="max-width: 400px"
                />
            </div>
            <div class="card-footer">
                <input type="submit" class="btn btn-success mr-2" value="Atualizar Permissões">
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
import CardPermission from '@/components/views/roles/CardPermission'

export default {
    components: {
        'card-permission': CardPermission
    },
    created() {
        this.$httpApi.get('role/show/'+this.role_id, {
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.role = res.data.item;
            res.data.item.permissions.forEach((permission, index) => {
                this.role.permissions[index] = permission.name
            });
        })

        this.$httpApi.get('permissiongroups',{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
        },
        }).then(res => {
            this.groups = res.data;
        }).catch(res => {
            if(res.response.data.errors){
                this.errors = res.response.data.errors
            }
            this.$store.dispatch('exibeErro', [
                res.response
            ])
        });
        
    },

    props: [
        'role_id',
    ],

    data() {
        return {
            groups: [],
            role: {
                name: String,
                description: String,
                permissions: Array,
            }
        }
    },

    methods: {
        attPermissions() {
            var permissoes = document.querySelectorAll('input.permissao')
            var newPermissions = [];
            permissoes.forEach(permissao => {
                if (permissao.checked) {
                    newPermissions.push(permissao.name);
                }
            });
            this.$httpApi.post('role/addperm/'+this.role_id, newPermissions, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                this.$router.push({ name: 'Roles.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        }, 

        refreshPermissions(){ 
              this.$httpApi.get('role/attgroups', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                this.$store.dispatch('criarMensagem', [
                    res.data.message, 'success'
                ])
            }).catch(res => { 
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                } 
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        }

    }
}
</script>

<style>

</style>