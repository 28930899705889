<template>
        <div class="d-flex flex-wrap justify-content-start">
            <div class="form-group col-10">
                <label for="input-register">Tipo de Conexão *</label>
                <select name="trunkRegister" id="input-register" class="form-control" v-model="trunk.trunkRegister">
                    <option value="">Selecione uma opção</option>
                    <option :selected="trunk.trunkRegister == 'Y' ? true : false" value="Y">Registro</option>
                    <option :selected="trunk.trunkRegister == 'N' ? true : false" value="N">TechPrefix</option>
                </select>
                <p v-for="erro in errors.trunkRegister" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-2">
                <label for="input-active">Ativo *</label>
                <select name="trunkActive" id="input-active" class="form-control">
                    <option value="">Selecione uma opção</option>
                    <option :selected="trunk.trunkActive == 'Y' ? true : false" value="Y">Sim</option>
                    <option :selected="trunk.trunkActive == 'N' ? true : false" value="N">Não</option>
                </select>
                <p v-for="erro in errors.trunkActive" :key="erro" class="text-danger">{{erro}}</p>
            </div>


            <div class="form-group col-5">
                <label for="input-name">Nome *</label>
                <input type="text" name="trunkName" id="input-name" class="form-control" v-model="trunk.trunkName">
                <p v-for="erro in errors.trunkName" :key="erro" class="text-danger">{{erro}}</p>
            </div>
            <div class="form-group col-2">
                <label for="server-type">Server Type</label>
                <select name="server-type" id="server-type" class="form-control" v-model="serverType" @change="placeholderServer">
                    <option value="ipv4" selected>IPv4</option>
                    <option value="ipv6">IPv6</option>
                    <option value="url">Domínio</option>
                </select>
            </div>
            <div class="form-group col-3">
                <label for="input-host">Servidor *</label>
                <input :type="serverType == 'url' ? 'url' : 'text'" name="trunkHost" id="input-host" class="form-control" v-model="trunk.trunkHost" :placeholder="placeholder">
                <p v-for="erro in errors.trunkHost" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-2">
                <label for="input-port">Porta *</label>
                <input type="text" name="trunkPort" id="input-port" class="form-control" v-model="trunk.trunkPort">
                <p v-for="erro in errors.trunkPort" :key="erro" class="text-danger">{{erro}}</p>
            </div>


            <div class="form-group col-12" id="techPrefix" v-if="trunk.trunkRegister == 'N'">
                <div class="form-group flex-fill mr-2">
                    <label for="input-tech-prefix">TechPrefix *</label>
                    <input type="text" name="trunkTechPrefix" id="input-tech-prefix" class="form-control" v-model="trunk.trunkTechPrefix">
                    <p v-for="erro in errors.trunkTechPrefix" :key="erro" class="text-danger">{{erro}}</p>
                </div>
                <div class="form-group flex-fill mr-2">
                </div>
            </div>

            <div class="form-group col-12 d-flex" id="registro" v-if="trunk.trunkRegister == 'Y'">
                <div class="form-group flex-fill mr-2">
                    <label for="input-username">Username *</label>
                    <input type="text" name="trunkUserName" id="input-username" class="form-control" v-model="trunk.trunkUserName">
                    <p v-for="erro in errors.trunkUserName" :key="erro" class="text-danger">{{erro}}</p>
                </div>

                <div class="form-group flex-fill ml-2">
                    <label for="input-password">Senha *</label>
                    <input type="text" name="trunkPassword" id="input-password" class="form-control" v-model="trunk.trunkPassword">
                    <p v-for="erro in errors.trunkPassword" :key="erro" class="text-danger">{{erro}}</p>
                </div>
            </div>


            <div class="form-group col-4">
                <label for="input-carrier">Operadora *</label>
                <select name="carrier" id="input-carrier" class="form-control">
                    <option value="">Selecione uma opção</option>
                    <option :selected="carrier.idCarrier == trunk.carrier ? true : false" v-for="carrier in carriers" :key="carrier.idCarrier" :value="carrier.idCarrier">{{carrier.carrierName}}</option>
                </select>
                <p v-for="erro in errors.carrier" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-proxy">Proxy</label>
                <input type="text" name="trunkOutboundProxy" id="input-proxy" class="form-control" v-model="trunk.trunkOutboundProxy">
                <p v-for="erro in errors.trunkOutboundProxy" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-context">Contexto *</label>
                <input type="text" name="trunkContext" id="input-context" class="form-control" :value="(trunk.trunkContext != null ? trunk.trunkContext : 'from-trunk')">
                <p v-for="erro in errors.trunkContext" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-2">
                <label for="input-channels">Canais *</label>
                <input type="number" name="trunkChannels" id="input-channels" class="form-control" v-model="trunk.trunkChannels">
                <p v-for="erro in errors.trunkChannels" :key="erro" class="text-danger">{{erro}}</p>
            </div>
            <div class="form-group col-6">
                <label for="input-codecs">Codecs *</label>
                <input type="text" name="trunkCodecs" id="input-codecs" class="form-control" title="Separe os codecs por ',' . (Ex.: g729, ulaw, alaw) " :value="(trunk.trunkCodecs != null ? trunk.trunkCodecs : 'g729, ulaw, alaw')">
            </div>
            <div class="form-group col-6">
                <label for="input-dtmf">Dtmf *</label>
                <input type="text" name="trunkDtmf" id="input-dtmf" class="form-control" :value="(trunk.trunkDtmf != null ? trunk.trunkDtmf : 'rfc2833')" readonly>
                <p v-for="erro in errors.trunkDtmf" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>
export default {
    props: [
        'trunk',
        'errors'
    ],

    created() {
        this.$httpApi.get('operadoras',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.carriers = res.data.items
        })

        this.placeholderServer();
       
    },

    data() {
        return {
            register: '',
            serverType: 'ipv4', 
            placeholder: '', 
            carriers: Array,
        }
    },

    methods: {
        placeholderServer() { 
            if(this.serverType == 'ipv4'){
                this.placeholder = '000.000.000.000'
            } else if (this.serverType == 'ipv6'){
                this.placeholder = '0000:0000:0000:0000:0000:0000:0000:0000'
            } else { 
                this.placeholder = 'http://seudominio.com ou https://seudominio.com'
            }
        }
    }, 

    watch: {
        trunk: function() {
            this.register = this.trunk.trunkRegister;
        }
    }
}
</script>

<style>

</style>