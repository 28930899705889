<template>
    <div class="card" v-if="$canIf('users.edit')">
        <form action="" method="post" v-on:submit.prevent='editUser' id="user-form">
            <div class="card-header">
                <h4>Editar Usuário Administrador</h4>
            </div>
            <div class="card-body">
                <users-form
                :errors="errors"
                :user="user"
                ></users-form>
            </div>
            <div class="card-footer">
                <div class="d-flex align-items-center">
                    <input class="btn btn-success mr-2" type="submit" value="Atualizar">
                    <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import UsuariosForm from './UsuariosForm.vue'
export default {
    components: {
        'users-form': UsuariosForm
    },

    props: ['user_id'],

    created() {
        this.$httpApi.get('users/cshow/'+this.user_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.user = res.data.items
        })
    },

    data() {
        return {
            errors: {},
            user: {},
        }
    },

    methods: {
        editUser() {
            var dados = new FormData(document.getElementById('user-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('users/edit/'+this.user.id, dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                this.$router.push({ name: 'Admin.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                this.$store.state.redirect = true
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>