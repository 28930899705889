import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/components/views/auth/Login'

import Dashboard from '@/components/views/Dashboard'
import Home from '@/components/views/Home'

import Usuarios from '@/components/views/usuarios/Usuarios'
import UsuariosIndex from '@/components/views/usuarios/UsuariosIndex'
import UsuariosCreate from '@/components/views/usuarios/UsuariosCreate'
import UsuariosEdit from '@/components/views/usuarios/UsuariosEdit'
import UsuariosRoles from '@/components/views/usuarios/UsuariosRoles'

import UsuariosAdmin from '@/components/views/usuariosAdmin/Usuarios'
import UsuariosAdminIndex from '@/components/views/usuariosAdmin/UsuariosIndex'
import UsuariosAdminCreate from '@/components/views/usuariosAdmin/UsuariosCreate'
import UsuariosAdminEdit from '@/components/views/usuariosAdmin/UsuariosEdit'

// import UsuariosCustomers from '@/components/views/usuarios/UsuariosCustomers'
// import UsuariosAddCustomers from '@/components/views/usuarios/UsuariosAddCustomers'

import Roles from '@/components/views/roles/Roles'
import RolesIndex from '@/components/views/roles/RolesIndex'
import RolesCreate from '@/components/views/roles/RolesCreate'
import RolesEdit from '@/components/views/roles/RolesEdit'
import AttPermissions from '@/components/views/roles/AttPermissions'
import PermissionGroupsIndex from '@/components/views/permissionGroups/Index'
import PermissionGroupsEdit from '@/components/views/permissionGroups/Edit'
import PermissionGroupsShow from '@/components/views/permissionGroups/Show'
import NoGroup from '@/components/views/permissionGroups/NoGroup'

import Customers from '@/components/views/customers/Customers'
import CustomersIndex from '@/components/views/customers/Index'
import CustomersCreate from '@/components/views/customers/Create'
import CustomersEdit from '@/components/views/customers/Edit'
import CustomersDids from '@/components/views/customers/Dids'

import CustomerGroups from '@/components/views/customerGroups/CustomerGroups'
import CustomerGroupsIndex from '@/components/views/customerGroups/Index'
import CustomerGroupsCreate from '@/components/views/customerGroups/Create'
import CustomerGroupsEdit from '@/components/views/customerGroups/Edit'
import CustomerGroupsCustomers from '@/components/views/customerGroups/Customers'
import CustomerGroupsAddCustomers from '@/components/views/customerGroups/AddCustomers'

import Carriers from '@/components/views/carriers/Carriers'
import CarriersIndex from '@/components/views/carriers/Index'
import CarriersCreate from '@/components/views/carriers/Create'
import CarriersEdit from '@/components/views/carriers/Edit'

// import Routes from '@/components/views/routes/Routes'
// import RoutesIndex from '@/components/views/routes/Index'
// import RoutesCreate from '@/components/views/routes/Create'
// import RoutesEdit from '@/components/views/routes/Edit'

import IntRoutes from '@/components/views/intRoutes/IntRoutes'
import IntRoutesIndex from '@/components/views/intRoutes/Index'
import IntRoutesCreate from '@/components/views/intRoutes/Create'
import IntRoutesEdit from '@/components/views/intRoutes/Edit'

import RatePlans from '@/components/views/ratePlans/RatePlans'
import RatePlansIndex from '@/components/views/ratePlans/Index'
import RatePlansCreate from '@/components/views/ratePlans/Create'
import RatePlansEdit from '@/components/views/ratePlans/Edit'
import RatePlansTrunks from '@/components/views/ratePlans/Trunks'

import IntRatePlans from '@/components/views/intRatePlans/IntRatePlans'
import IntRatePlansIndex from '@/components/views/intRatePlans/Index'
import IntRatePlansCreate from '@/components/views/intRatePlans/Create'
import IntRatePlansEdit from '@/components/views/intRatePlans/Edit'

import Trunks from '@/components/views/trunks/Trunks'
import TrunksIndex from '@/components/views/trunks/Index'
import TrunksCreate from '@/components/views/trunks/Create'
import TrunksEdit from '@/components/views/trunks/Edit'

import IntTrunks from '@/components/views/intTrunks/IntTrunks'
import IntTrunksIndex from '@/components/views/intTrunks/Index'
import IntTrunksCreate from '@/components/views/intTrunks/Create'
import IntTrunksEdit from '@/components/views/intTrunks/Edit'
import IntTrunksAdd from '@/components/views/intTrunks/Add'

import Subscribers from '@/components/views/subscribers/Subscribers'
import SubscribersIndex from '@/components/views/subscribers/Index'
import SubscribersCreate from '@/components/views/subscribers/Create'
import SubscribersEdit from '@/components/views/subscribers/Edit'
import SubscribersDids from '@/components/views/subscribers/Dids'
import SubscribersPlans from '@/components/views/subscribers/Plans'

import Dids from '@/components/views/dids/Dids'
import DidsIndex from '@/components/views/dids/Index'
import DidsCreate from '@/components/views/dids/Create'
import DidsEdit from '@/components/views/dids/Edit'

import RateCosts from '@/components/views/rateCosts/RateCosts'
// import RateCostsCreate from '@/components/views/rateCosts/Create'
import RateCostsEdit from '@/components/views/rateCosts/Edit'

import IntCosts from '@/components/views/intCosts/IntCost'

import IntCostGroups from '@/components/views/intCosts/Groups/Groups'
import IntCostGroupsIndex from '@/components/views/intCosts/Groups/Index'
import IntCostGroupsCreate from '@/components/views/intCosts/Groups/Create'
import IntCostGroupsEdit from '@/components/views/intCosts/Groups/Edit'

import IntCostCosts from '@/components/views/intCosts/Costs/Costs'
import IntCostCostsIndex from '@/components/views/intCosts/Costs/Index'
import IntCostCostsCreate from '@/components/views/intCosts/Costs/Create'
import IntCostCostsEdit from '@/components/views/intCosts/Costs/Edit'

import Config from '@/components/views/config/Config'
import ConfigIndex from '@/components/views/config/Index'
import ConfigEdit from '@/components/views/config/Edit'

import ReportsCDR from '@/components/views/reports/CDR'
import RelatorioCDRByOperadora from '@/components/views/reports/cdrByOperadora'

import CallSimulator from '@/components/views/callSimulator/callSimulator'

import error404 from '@/components/views/404'

Vue.use(VueRouter)

const canRoute = function(to,from,next,permission) {
	if (store.state.admin || store.state.permissions.includes(permission)) {
		next()
	} else {
		store.state.redirect = true
		store.dispatch('criarMensagem',[
			'Você não tem permissão para acessar a rota: '+to.path,
			'danger'
		]) 
		next(from.path)
	}
}

const routes = [
    {
        path: '/login',
        name: 'Login',
		component: Login,
    },
	{
        path: '/dashboard',
		component: Dashboard,
		children: [
			{
				path: '',
				name: 'Home',
				component: Home,
			},
			{
				path: 'usersadmin',
				component: UsuariosAdmin,
				children: [
					{
						path: '',
						name: 'Admin.Index',
						component: UsuariosAdminIndex,
					},

					{
						path: 'create/',
						name: 'Admin.Create',
						component: UsuariosAdminCreate,
					},

					{
						path: 'edit/:user_id',
						name: 'Admin.Edit',
						component: UsuariosAdminEdit,
						props: true,
					},
				]
			},
			{
				path: 'users',
				component: Usuarios,
				children: [
					{
						path: '',
						name: 'Users.Index',
						component: UsuariosIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'users.index')
						}
					},

					{
						path: 'create/',
						name: 'Users.Create',
						component: UsuariosCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'users.store')
						}
					},

					{
						path: 'edit/:user_id',
						name: 'Users.Edit',
						component: UsuariosEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'users.edit')
						}
					},
					
					{
						path: 'roles/:user_id',
						name: 'Users.Roles',
						component: UsuariosRoles,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'users.roleStore')
						}
					},

				]
			},
			{
				path: 'roles',
				component: Roles,
				children: [
					{
						path: '',
						name: 'Roles.Index',
						component: RolesIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'roles.index')
						}
					},

					{
						path: 'create/',
						name: 'Roles.Create',
						component: RolesCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'roles.create')
						}
					},

					{
						path: 'edit/:role_id',
						name: 'Roles.Edit',
						component: RolesEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'roles.edit')
						}
					},

					{
						path: 'attpermissions/:role_id',
						name: 'Roles.AttPermissions',
						component: AttPermissions,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'permissions.attPermission')
						}
					},

					{
						path: 'permissiongroups',
						name: 'Roles.PermissionGroups',
						component: PermissionGroupsIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'permissions.semGrupo')
						}
					},

					{
						path: 'permissiongroups/edit/:group_id',
						name: 'Roles.PermissionGroupsEdit',
						component: PermissionGroupsEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'permissions.semGrupo')
						}
					},

					{
						path: 'permissiongroups/show/:group_id',
						name: 'Roles.PermissionGroupsShow',
						component: PermissionGroupsShow,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'permissions.show')
						}
					},

					{
						path: 'permissiongroups/nogroup',
						name: 'Roles.NoGroup',
						component: NoGroup,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'permissions.semGrupo')
						}
					},
				]
			},

			{
				path: 'customers',
				component: Customers,
				children:[
					{
						path: '',
						name: 'Customers.Index',
						component: CustomersIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customers.index')
						}
					},

					{
						path: 'create/',
						name: 'Customers.Create',
						component: CustomersCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customers.store')
						}
					},

					{
						path: 'edit/:customer_id',
						name: 'Customers.Edit',
						component: CustomersEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customers.update')
						},
					},

					{
						path: 'dids/:customer_id',
						name: 'Customers.Dids',
						component: CustomersDids,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'dids.alocated')
						}
					},

					{
						path: 'subscribers/:customer_id',
						component: Subscribers,
						children:[
							{
								path: '',
								name: 'Subscribers.Index',
								component: SubscribersIndex,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'subscribers.index')
								}
							},
		
							{
								path: 'create/',
								name: 'Subscribers.Create',
								component: SubscribersCreate,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'subscribers.create')
								}
							},
		
							{
								path: 'edit/:subscriber_id',
								name: 'Subscribers.Edit',
								component: SubscribersEdit,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'subscribers.edit')
								},
							},

							{
								path: 'dids/:subscriber_id',
								name: 'Subscribers.Dids',
								component: SubscribersDids,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'subscribers.haveDid')
								},
							},

							{
								path: 'plans/:subscriber_id',
								name: 'Subscribers.Plans',
								component: SubscribersPlans,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'subscribers.ratePlan')
								}
							},
						],
					},
				],
			},

			{
				path: 'customergroups',
				component: CustomerGroups,
				children:[
					{
						path: '',
						name: 'CustomerGroups.Index',
						component: CustomerGroupsIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customergroups.index')
						}
					},

					{
						path: 'create/',
						name: 'CustomerGroups.Create',
						component: CustomerGroupsCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customergroups.store')
						}
					},

					{
						path: 'edit/:group_id',
						name: 'CustomerGroups.Edit',
						component: CustomerGroupsEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customergroups.update')
						},
					},

					{
						path: 'addcustomers/:group_id',
						name: 'CustomerGroups.AddCustomers',
						component: CustomerGroupsAddCustomers,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customergroups.addcustomer')
						},
					},

					{
						path: 'customers/:group_id',
						name: 'CustomerGroups.Customers',
						component: CustomerGroupsCustomers,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'customergroups.show')
						}
					},
				],
			},

			{
				path: 'carriers',
				component: Carriers,
				children:[
					{
						path: '',
						name: 'Carriers.Index',
						component: CarriersIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'operadoras.index')
						}
					},

					{
						path: 'create/',
						name: 'Carriers.Create',
						component: CarriersCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'operadoras.store')
						}
					},

					{
						path: 'edit/:carrier_id',
						name: 'Carriers.Edit',
						component: CarriersEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'operadoras.update')
						},
					},
				],
			},
			{
				path: 'rateplans',
				component: RatePlans,
				children:[
					{
						path: '',
						name: 'RatePlans.Index',
						component: RatePlansIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'nplans.index')
						}						
					},

					{
						path: 'create/',
						name: 'RatePlans.Create',
						component: RatePlansCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'nplans.store')
						}
					
					},

					{
						path: 'edit/:plan_id',
						name: 'RatePlans.Edit',
						component: RatePlansEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'nplans.update')
						}
						
					},

					{
						path: 'trunks/:route_id',
						name: 'RatePlans.Trunks',
						component: RatePlansTrunks,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'rtrunk.show')
						}
					},
				],
			},

			{
				path: 'routes-int',
				component: IntRoutes,
				children:[
					{
						path: '',
						name: 'IntRoutes.Index',
						component: IntRoutesIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'routesIntl.index')
						}
					},

					{
						path: 'create/',
						name: 'IntRoutes.Create',
						component: IntRoutesCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'routesIntl.create')
						}
					},

					{
						path: 'edit/:route_id',
						name: 'IntRoutes.Edit',
						component: IntRoutesEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'routesIntl.edit')
						},
					},
					
					{
						path: 'int_rateplans/:route_id',
						component: IntRatePlans,
						props: true,
						children:[
							{
								path: '',
								name: 'IntRatePlans.Index',
								component: IntRatePlansIndex,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecostinternational.index')
								}
							},
		
							{
								path: 'create/',
								name: 'IntRatePlans.Create',
								component: IntRatePlansCreate,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecostinternational.store')
								}
							},
		
							{
								path: 'edit/:int_plan_id',
								name: 'IntRatePlans.Edit',
								component: IntRatePlansEdit,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecostinternational.update')
								},
							},
						],
					},
					{
						path: 'trunks/:route_id',
						component: IntTrunks,
						children:[
							{
								path: '',
								name: 'IntTrunks.Index',
								component: IntTrunksIndex,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ritrunk.show')
								}
							},
		
							{
								path: 'create/',
								name: 'IntTrunks.Create',
								component: IntTrunksCreate,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ritrunk.store')
								}
							},
		
							{
								path: 'edit/:trunk_id',
								name: 'IntTrunks.Edit',
								component: IntTrunksEdit,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ritrunk.update')
								},
							},

							{
								path: 'add/',
								name: 'IntTrunks.Add',
								component: IntTrunksAdd,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ritrunk.pupdate')
								}								
							},
		
							
						],
					},
				],
			},

			{
				path: 'trunks',
				component: Trunks,
				children: [
					{
						path: '',
						name: 'Trunks.Index',
						component: TrunksIndex,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'trunk.index')
						}
					},

					{
						path: 'create/',
						name: 'Trunks.Create',
						component: TrunksCreate,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'trunk.store')
						}
					},

					{
						path: 'edit/:trunk_id',
						name: 'Trunks.Edit',
						component: TrunksEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'trunk.update')
						},
					},

					{
						path: 'rate_costs/:trunk_id',
						component: RateCosts,
						props: true,
						children:[
							{
								path: 'edit',
								name: 'RateCosts.Edit',
								component: RateCostsEdit,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecost.update')
								}								
							},
						],
					},
				]
			},

			{
				path: 'dids',
				component: Dids,
				children: [
					{
						path: '',
						name: 'Dids.Index',
						component: DidsIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'dids.index')
						}
					},

					{
						path: 'create/',
						name: 'Dids.Create',
						component: DidsCreate,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'dids.create')
						}
					},

					{
						path: 'edit/:did_id',
						name: 'Dids.Edit',
						component: DidsEdit,
						props: true,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'dids.edit')
						},
					},
				]
			},

			{
				path: 'int-costs',
				component: IntCosts,
				children: [
					{
						path: 'groups',
						component: IntCostGroups,
						children: [
							{
								path: '',
								name: 'IntCostGroups.Index',
								component: IntCostGroupsIndex,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'groupcostintl.index')
								},
							},
							{
								path: 'create/',
								name: 'IntCostGroups.Create',
								component: IntCostGroupsCreate,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'groupcostintl.store')
								},
							},
							{
								path: 'edit/:group_id',
								name: 'IntCostGroups.Edit',
								component: IntCostGroupsEdit,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'groupcostintl.edit')
								},
							},
						]
					},

					{
						path: ':group_id',
						component: IntCostCosts,
						children: [
							{
								path: '',
								name: 'IntCostCosts.Index',
								component: IntCostCostsIndex,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecostinternational.index')
								},
							},
							{
								path: 'create/',
								name: 'IntCostCosts.Create',
								component: IntCostCostsCreate,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecostinternational.store')
								},
							},
							{
								path: 'edit/:intCost_id',
								name: 'IntCostCosts.Edit',
								component: IntCostCostsEdit,
								props: true,
								beforeEnter: (to,from,next) => {
									canRoute(to,from,next,'ratecostinternational.update')
								},
							},
						]
					}
				]
			},

			{
				path: 'configuracoes',
				component: Config,
				children:[
					{
						path: '',
						name: 'Config.Index',
						component: ConfigIndex,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'config.index')
						}
					},

					{
						path: 'edit',
						name: 'Config.Edit',
						component: ConfigEdit,
						beforeEnter: (to,from,next) => {
							canRoute(to,from,next,'config.update')
						},
					},
				],
			},

			{
				path: 'relatorio-cdr',
				name: 'Reports.CDR',
				component: ReportsCDR,
				beforeEnter: (to,from,next) => {
					canRoute(to,from,next,'reports.cdr')
				}
			},

			{
				path: 'relatorio-cdrByOperadora',
				name: 'Relatorio.CDRByOperadora',
				component: RelatorioCDRByOperadora,
				beforeEnter: (to,from,next) => {
					canRoute(to,from,next,'reports.cdrByOperadora')
				}
			},

			{
				path: 'call-simulator',
				name: 'Call.Simulator',
				component: CallSimulator,
				beforeEnter: (to,from,next) => {
					canRoute(to,from,next,'services.callSimulation')
				}
			},
		],
    },
	
	{
		path: '*',
		name: 'error404',
		component: error404,
	}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
	if (!store.state.redirect) {
		store.state.showAlert = false;
		store.state.mensagemAlert.text = '';
		store.state.mensagemAlert.type = '';
	}
	if (!store.state.logado && to.path != '/login' ) {
		next('/login')
	} else if(store.state.logado && (to.path == '/login' || to.path == '/') ) {
		next({ name: 'Home' })
	} else {
		store.state.rotaAtual = to.name;
		store.state.redirect = false;
		next();
	}
	// next()
})

export default router