<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Dids de {{customer.customersName}}</h4>
            <div class="d-flex">
                <button v-if="$canIf('dids.alocated')" class="btn btn-success btn-sm" @click.prevent="clearSearch" @click="openModalDid">Vincular Did</button>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Número</th>
                        <th>Operadora</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <tr v-for="did in customer.dids" :key="did.idDid">
                        <td data-title="Id">{{did.idDid}}</td>
                        <td data-title="Número">{{did.did}}</td>
                        <td data-title="Operadora">{{did.carriers.carrierName}}</td>
                        <td class="acoes">
                            <button v-if="$canIf('dids.dealocated')" title="Desvincular Did do Cliente" class="m-1 btn btn-sm bg-danger" @click.prevent="unAlocate(did.idDid)"><i class="fas fa-trash-alt"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="modalDid" class="modal-fundo" @click.self="closeModalDid" >
            <div class="card modal-conteudo">
                   <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Vinculação de Did</h4>
                            <span class="btn-fechar" @click="closeModalDid">x</span>
                        </div>
                    </div>
                    <div class="card-body" >
                        <form action="" method="post" @submit.prevent="getDids" enctype="multipart/form-data" id="form-search-dids">
                      
                        <div class="row">
                        <div class="col-6 form-group">
                            <label for="did" class="form-label">Pesquisar DIDs Livres: </label>
                           <input type="number" class="form-control" name="word" placeholder="Informe um did para iniciar a pesquisa" v-model="pesquisa" v-on:keyup="findByDid"  >
                        </div>
                          <div class="col-3 form-group">
                            <label for="searchBy" class="form-label">Tipo de DID: </label>
                            <select name="searchBy" id="input-searchBy" class="form-control"  @change="getDids" v-model="tipoDid" >
                                <option value="free" selected>Livres</option>
                                <option value="alocated">Alocados</option>
                            </select>
                        </div>  
                         <div class="col-3 form-group">
                            <label for="carrier" class="form-label">Operadoras: </label>
                            <select name="carrier" id="input-carrier" class="form-control" @change="getDids"  v-model="carrierSelect">
                                  <option value="" selected>Selecione uma Operadora</option>
                                <option  v-for="carrier in carriers" :key="carrier.idCarrier" :value="carrier.idCarrier" >{{ carrier.carrierName }}</option>
                            </select>
                            </div>  
                        </div>   
                        </form>        
                    </div>
                    <div class="card-footer" >
                        <div class="scroll-modal" v-if="resultSearch != ''" >
                         <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Número</th>
                        <th>Operadora</th>
                        <th v-if="tipoDid != 'alocated'" class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela" >
                    <tr v-for="result in resultSearch" :key="result.idDid">
                        <td data-title="Id">{{result.idDid}}</td>
                        <td data-title="Número">{{result.did}}</td>
                        <td data-title="Operadora">{{result.carrierName}}</td>
                        <td v-if="tipoDid != 'alocated'" class="acoes">
                              <form action="" method="put" @submit.prevent="alocate(customer_id, result.idDid)" enctype="multipart/form-data" id="form-alocate">      
                                <button title="Vincular Did ao Cliente" class="m-1 btn btn-sm bg-success" @click="closeModalDid"><i class="fas fa-plus-circle"></i></button>
                             </form>
                         </td>
                        </tr>
                     </tbody>
                 </table>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'customer_id'
    ],
    created() {
        this.getCustomer();
        this.getCarriers();
        this.getDids(); 
    },

    data() {
        return {
            customer: {},
            dids: [],
            carriers: [],
            resultSearch: [],
            pesquisa: '',
            tipoDid: 'free',
            carrierSelect: ''
           

        }
    },

    methods: {
        getCustomer() {
            this.$httpApi.get('customers/'+this.customer_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.customer = res.data.items;
            })
        },

        findByDid(){
            var result = [];  
            result.pop();            
            var i; 
            for(i = 0; i < this.dids.length; i++){
                if(this.dids[i].did.includes(this.pesquisa)){           
                    result.push(this.dids[i]);
                }
            }              
            this.resultSearch = result;
        },

        getDids() {
            this.$httpApi.post('dids/search', {carrier: this.carrierSelect, searchBy: this.tipoDid, number: this.pesquisa}, { 
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.dids = res.data.items;
                this.findByDid();
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        getCarriers(){ 
               this.$httpApi.get('operadoras',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.carriers = res.data.items
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        }, 

        alocate(id, idDid) {          
            this.$httpApi.put('dids/alocated/'+ idDid,{idCustomer: id},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomer();
                this.getDids();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        unAlocate(id) {

            this.$httpApi.put('dids/deAlocated/'+id,{idCustomer: this.customer.idCustomers},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomer();
                this.getDids();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        openModalDid() {
            document.getElementById('modalDid').style = "display: block;"
        },

        closeModalDid() {
            document.getElementById('modalDid').style = "display: none;"
        },

        clearResultSearch(){ 
            this.resultSearch = null;
        },

        clearSearch(){
            this.pesquisa = '';
            this.resultSearch = '';
            this.carrierSelect = '';            
        }
    }

}
</script>

<style>

</style>