<template>
    <div class="card">
        <form action="" method="post" @submit.prevent="editCarrier" id="carrier-form" v-if="$canIf('carriers.update')">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Operadora</h4>
                </div>
            </div>
            <div class="card-body">
                <carrier-form
                :errors="errors"
                :carrier="carrier"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'Carriers.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  CarrierForm from "@/components/views/carriers/_Form";
export default {

    components: {
        'carrier-form': CarrierForm,
    },

    props: ['carrier_id'],

    created() {
        this.$httpApi.get('operadoras/show/'+this.carrier_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            // this.carrier.name = res.data.dados.carriersName;
            // this.carrier.description = res.data.dados.carriersDateInvoice.substring(0,10);
            this.carrier = res.data.dados
        })
    },

    data(){
        return {
            errors: {},
            carrier: {},
        }
    },

    methods: {
        editCarrier() {
            var dados = new FormData(document.getElementById('carrier-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('operadoras/update/'+this.carrier_id, dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Carriers.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>