<template>
    <div class="card" v-if="$canIf('roles.edit')">
        <form action="" method="post" v-on:submit.prevent='editRole' id="role-form">
            <div class="card-header">
                <h4>Editar Cargo</h4>
            </div>
            <div class="card-body">
                <roles-form
                :errors="errors"
                :role="role"
                ></roles-form>
            </div>
            <div class="card-footer">
                <input class="btn btn-success mr-2" type="submit" value="Atualizar">
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
import RolesForm from './RolesForm.vue'
export default {
    components: {
        'roles-form': RolesForm
    },

    created() {
        this.$httpApi.get('role/show/'+this.role_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.role = res.data.item;
        })
    },
    
    props: ['role_id'],

    data() {
        return {
            errors: {},
            role: {}
        }
    },

    methods: {
        editRole() {
            var dados = new FormData(document.getElementById('role-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('role/update/'+this.role_id, dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Roles.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>