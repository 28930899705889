import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import VueMask from 'v-mask'
import Vue2Filters from 'vue2-filters'
import $ from "jquery"
import axios from 'axios'
import money from 'v-money'

// import './plugins/axios'
import './plugins/permissions'

Vue.component('layout-header', require('./components/layouts/Header.vue').default);
Vue.component('layout-sidebar', require('./components/layouts/Sidebar.vue').default);
Vue.component('layout-footer', require('./components/layouts/Footer.vue').default);
Vue.component('pagination', require('laravel-vue-pagination'));


Vue.config.productionTip = false

// Vue.prototype.$domain = 'http://astbilling.local/'
Vue.prototype.$domain = 'https://alhambrab.brdsoft.com.br/'

// Configuração para Axios
Vue.use({
  install(Vue) {
    Vue.prototype.$httpApi = axios.create({
      // baseURL: 'http://astbilling.local/api/'
      baseURL: 'https://alhambrab.brdsoft.com.br/api/'
    })
    Vue.prototype.$httpApi.interceptors.request.use(function(request) {
      store.state.readyToLoad = false;
      return request;
    })
    Vue.prototype.$httpApi.interceptors.response.use(function(response) {
      store.state.readyToLoad = true;
      return response;
    }, function(error) {
      store.state.readyToLoad = true;
      return Promise.reject(error);
    })
  }
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateHour', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
  }
})

Vue.use(VueMask)
Vue.use(Vue2Filters)
Vue.use(money, {precision: 4})


$('[data-toggle="tooltip"]').tooltip();

