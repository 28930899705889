<template>
<div class="card">
    <div class="card-header">
        <div class="d-flex justify-content-between">
            <h3>Administradores</h3>
        </div>
    </div>
    <div class="card-body">
        <router-view></router-view>
    </div>
</div>
<!-- <div>
    <p class="p-0 m-0" v-for="n in 38" :key="n">n</p>
</div> -->
</template>

<script>
export default {
}
</script>

<style scoped>
.hide {
    display: none;
}

@media all and (max-width: 825px) {
    #btn-voltar {
        justify-content: center;
    }
}
</style>