<template>
    <div id="erro404" class="d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
            <h1 class="m-3">Erro 404</h1>
            <h2 class="m-2">Página não encontrada</h2>
        </div>
        <h3 @click="$router.go(-1)" class="text-danger" style="cursor: pointer">Voltar para a página anterior</h3>
    </div>
</template>

<script>
export default {

}
</script>

<style>
#erro404 {
    height: 100vh;
}
</style>