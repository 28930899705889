<template>
    <div class="card" v-if="$canIf('customers.store')" >
        <form action="" method="post" @submit.prevent="createCustomer" id="customers-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Cadastrar Cliente</h4>
                </div>
            </div>
            <div class="card-body">
                <customer-form
                :errors="errors"
                :customer="customer"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Cadastrar</button>
                <router-link :to="{ name: 'Customers.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  CustomerForm from "@/components/views/customers/_Form";
export default {

    components: {
        'customer-form': CustomerForm,
    },

    data(){
        return {
            errors: {},
            customer: {},
        }
    },

    methods: {
        createCustomer() {
            var dados = new FormData(document.getElementById('customers-form'))
            this.$httpApi.post('customers/store',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Customers.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
               if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }

}
</script>

<style>

</style>