<template>
    <div class="card" v-if="$canIf('subscribers.create')">
        <form action="" method="post" @submit.prevent="createSubscriber" id="subscriber-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Cadastrar Assinante</h4>
                </div>
            </div>
            <div class="card-body">
                <subscriber-form
                :errors="errors"
                :subscriber="subscriber"
                :ip_config="ip_config"
                :edit ="false"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Cadastrar</button>
                <router-link :to="{ name: 'Subscribers.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  SubscriberForm from "@/components/views/subscribers/_Form";
export default {

    components: {
        'subscriber-form': SubscriberForm,
    },

    props: [
        'customer_id'
    ],

    data(){
        return {
            errors: {},
            subscriber: {},
            ip_config: {},
        }
    },

    methods: {
        createSubscriber() {
            var dados = new FormData(document.getElementById('subscriber-form'));
            dados.append('accountcode', this.customer_id);

            this.$httpApi.post('subscribers',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {     
                this.subscriber.id = res.data.items.subscriber.id;                   
                this.$router.push({ name: 'Subscribers.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                  this.$httpApi.post('subscribers/'+this.subscriber.id+'/addRatePlan',{
                        ratePlan: this.subscriber.nPlan, ratePlanInternational: this.subscriber.intPlan
                    },{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                        },
                    }).then(res => {
                        this.$store.dispatch('criarMensagem',[
                            res.data.message,
                            "success"
                        ])
                        this.getPlans();
            });
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });

            console.log(this.subscriber)           
          
        },
    }

}
</script>

<style>

</style>