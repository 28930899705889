<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex justify-content-between align-items-start">
                <h3>Tarifas Internacionais</h3>
                <router-link v-if="$store.state.rotaAtual.split('.')[0] != 'IntCostGroups'" :to="{ name: 'IntCostGroups.Index' }" class="btn bg-danger btn-sm">Voltar para Grupos</router-link>
            </div>
        </div>
        <div class="card-body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>