<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Cargos</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('roles.create')" :to="{ name: 'Roles.Create' }" class="btn btn-success btn-sm mr-2">Novo Cargo</router-link>
            </div>
        </div>
        <br>
        <div>
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="cargo in roles" :key="cargo.id">
                        <td data-title="Id">{{cargo.id}}</td>
                        <td data-title="Nome">{{cargo.name}}</td>
                        <td data-title="Descrição">{{cargo.description}}</td>
                        <td class="acoes">
                            <router-link v-if="$canIf('roles.create')" title="Gerenciar Permissões"  :to="{ name: 'Roles.AttPermissions', params: {role_id: cargo.id}}" class="m-1 btn btn-sm bg-primary"><i class="fas fa-tasks"></i></router-link>
                            <router-link v-if="$canIf('roles.edit')" title="Editar Cargo"  :to="{ name: 'Roles.Edit', params: {role_id: cargo.id}}" class="m-1 btn btn-sm bg-info"><i class="fas fa-edit"></i></router-link>
                            <a v-if="$canIf('roles.delete') " title="Deletar Cargo" class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(cargo.id)"><i></i><i class="fas fa-trash-alt text-white"></i></a>
                        </td>
                    </tr>
                </tbody>
                
            </table>
        </div>

        <confirm-delete v-if="$canIf('dids.delete')"
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Cargo?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>
    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: {
        ConfirmDelete,
    },

    created() {
        this.getRoles();
    },

    data() {
        return {
            roles: [],
            isConfirmDeleteModalVisible: false,
            role: "",
        }
    },

    methods: {
        getRoles() {
            this.$httpApi.get('role',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then(res => {
                this.roles = res.data.items;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteRole(id) {
            this.$httpApi.delete('role/delete/'+id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
            }).then(res => {
                this.getRoles()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getRoles()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        showConfirmDeleteModal(id) {
            this.role = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.role = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteRole(this.role);
            this.closeConfirmDeleteModal();
        },
    }
}
</script>

<style scoped>
    .hide {
        display: none;
    }
</style>