<template>
    <div>
        <div class="d-flex justify-content-center align-items-center">
            <div v-if="$store.state.showAlert" :class="'alert alert-'+$store.state.mensagemAlert.type" role="alert">{{$store.state.mensagemAlert.text}}</div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="card" id="card-login">
                <form action="" method="get" v-on:submit.prevent='login'>
                <div class="card-header">
                    <div class="d-flex justify-content-center align-items-center">
                        <h2>LOGIN</h2>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="input-email">Email</label>
                        <input class="form-control" type="text" id="input-email" name="email" v-model='form.email'>
                    </div>
                    <div class="form-group">
                        <label for="input-senha">Senha</label>
                        <input class="form-control" type="password" id="input-senha" name="password" v-model='form.senha'>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center align-items-center">
                        <input class="btn btn-dark" type="submit" value="Entrar">
                    </div>
                </div>
                </form>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
export default {
data() {
        return {
            form: {
                email: '',
                senha: '',
            }
        }
    },

    methods:{
        login: function(){
            // dados passados para o login
            var data = {
                email: this.form.email,
                password: this.form.senha,
            }

            // método do login
            this.$httpApi.post('auth/login',data).then(res => {
                sessionStorage.setItem('Auth_Token', res.data.token.access_token);

                sessionStorage.setItem('Config_name', res.data.config.name);
                sessionStorage.setItem('Config_logo', res.data.config.logo);
                sessionStorage.setItem('Config_address', res.data.config.address);
                sessionStorage.setItem('Config_number', res.data.config.number);
                sessionStorage.setItem('Config_province', res.data.config.province);
                sessionStorage.setItem('Config_cep', res.data.config.cep);
                sessionStorage.setItem('Config_city', res.data.config.city);
                sessionStorage.setItem('Config_state', res.data.config.state);
                sessionStorage.setItem('Config_phone', res.data.config.phone);
                sessionStorage.setItem('Config_whatsapp', res.data.config.name);
                sessionStorage.setItem('Config_email', res.data.config.email);
                sessionStorage.setItem('Config_domain', res.data.config.domain);
                sessionStorage.setItem('User_name', res.data.user_name);

                this.$store.state.config.name = res.data.config.name;
                this.$store.state.config.logo = res.data.config.logo;
                this.$store.state.config.address = res.data.config.address;
                this.$store.state.config.number = res.data.config.number;
                this.$store.state.config.province = res.data.config.province;
                this.$store.state.config.cep = res.data.config.cep;
                this.$store.state.config.city = res.data.config.city;
                this.$store.state.config.state = res.data.config.state;
                this.$store.state.config.phone = res.data.config.phone;
                this.$store.state.config.whatsapp = res.data.config.whatsapp;
                this.$store.state.config.domain = res.data.config.domain;
                this.$store.state.config.email = res.data.config.email;
                this.$store.state.user_name = res.data.user_name;

                // this.$store.state.config = res.data.config;
                if (res.data.permissions == 'Admin') {
                    // criação das variáveis de sessão e atrtibutos do vuex
                    sessionStorage.setItem('Admin', true);
                    sessionStorage.setItem('Permissions', []);
                    this.$store.state.admin = true;
                    this.$store.state.permissions = []
                } else {
                    // criação das variáveis de sessão e atrtibutos do vuex
                    sessionStorage.setItem('Admin', false);
                    sessionStorage.setItem('Permissions', res.data.permissions);
                    this.$store.state.admin = false;
                    this.$store.state.permissions = res.data.permissions
                }
                // criação do atributo Logado e redirecionamento pra home
                this.$store.state.logado = true
                this.$router.push({ name: 'Home' })

                // Criação da mensagem de sucesso
                this.$store.dispatch('criarMensagem',[
                    'Você foi logado com sucesso',
                    'success'
                ])
                // Criação de variável para controle do tempo de refresh do token
                sessionStorage.setItem('TimeRefresh', Date.now())

                // início da contagem de tempo para o refresh
                // this.$activateRefresh()
            }).catch(error => {
                switch (error.response.status) {
                    case 401:
                        this.$store.dispatch('criarMensagem',[
                            'As credenciais estão erradas',
                            'danger'
                        ]);
                        break;

                    default:
                        this.$store.dispatch('criarMensagem',[
                            'Erro '+error.response.status+': Contate um Administrador',
                            'danger'
                        ]);
                        break;
                }
                // this.$store.dispatch('criarMensagem',[
                //     error.response.status,
                //     'danger'
                // ])
            })
        },
    },

    computed: {
      validation() {
        return this.form.senha.length >= 8
      }
    }
}
</script>

<style scoped>

/* 1100 750 450 */
@media all and (min-width: 1101px) {
    #card-login {
        width: 25%;
    }
}
@media all and (max-width: 1100px) and (min-width: 750px) {
    #card-login {
        width: 50%;
    }
}
@media all and (max-width: 749px) and (min-width: 450px) {
    #card-login {
        width: 75%;
    }
}
@media all and (max-width: 449px) {
    #card-login {
        width: 100%;
    }
}

</style>