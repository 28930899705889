<template>
    <div class="card">
        <div class="d-flex justify-content-between pt-2 pb-2 align-items-end card-header" style="height: fit-content">
            <h4>Lista de Grupos</h4>
            <router-link :to="{ name: 'Roles.NoGroup' }" class="btn btn-warning btn-sm d-flex align-items-center pt-3 pb-3" style="height: fit-content">Sem Grupo</router-link>
        </div>
        <div class="card-body">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Nome</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(2)">Descrição</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(2)">Permissões</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="group in groups" :key="group.id">
                        <td data-title="Id">{{group.id}}</td>
                        <td data-title="Nome">{{group.name}}</td>
                        <td data-title="Descrição">{{group.description}}</td>
                        <td data-title="Permissões">{{group.permissions.length}}</td>
                        <td class="acoes">
                            <router-link :to="{ name: 'Roles.PermissionGroupsEdit', params: {group_id: group.id}}" class="m-1 btn btn-sm bg-info">Editar</router-link>
                            <router-link :to="{ name: 'Roles.PermissionGroupsShow', params: {group_id: group.id}}" class="m-1 btn btn-sm bg-primary">Ver Detalhes</router-link>
                        </td>
                    </tr>
                </tbody>
                
            </table>
        </div>
        <div class="card-footer">
            <router-link :to="{ name: 'Roles.Index' }" class="btn btn-danger">Voltar</router-link>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.$httpApi.get('permissiongroups',{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
        },
        }).then(res => {
            this.groups = res.data;
        }).catch(res => {
            if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
        });
    },

    data() {
        return {
            groups: [],
        }
    },
}
</script>

<style>

</style>