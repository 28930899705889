<template>
    <div v-if="$canIf('config.update')">
        <div class="d-flex justify-content-between">
            <h4>Configuração atual do sistema</h4>
            <router-link :to="{ name: 'Config.Edit' }" class="btn btn-info btn-sm " style="height: fit-content">Editar Configurações</router-link>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Nome</th>
                        <th>Logo</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Cidade</th>
                        <!-- <th>Ações</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-title="Nome">{{config.name}}</td>
                        <td data-title="Logo" style="width: 15%">
                            <img v-if="config.logo" :src="this.$domain + 'logo/' +config.logo" alt="">
                        </td>
                        <td data-title="Email">{{config.email}}</td>
                        <td data-title="Telefone">{{config.phone}}</td>
                        <td data-title="Cidade">{{config.city}}</td>
                                                
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.getConfig();
    },

    data() {
        return {
            config: {},
        }
    },

    methods: {
        getConfig() {
            this.$httpApi.get('config',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.config = res.data[0];

            }).catch(res => {
               if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }

}
</script>

<style>

</style>