<template>
        <div class="d-flex flex-wrap justify-content-start">

            <div class="form-group col-9">
                <label for="input-description">Descrição *</label>
                <input type="text" name="rateCostInternationalDescription" id="input-description" class="form-control" v-model="intCost.rateCostInternationalDescription">
                <p v-for="erro in errors.rateCostInternationalDescription" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-prefix">Prefixo *</label>
                <input type="text" name="rateCostInternationalPrefix" id="input-prefix" class="form-control" v-model="intCost.rateCostInternationalPrefix">
                <p v-for="erro in errors.rateCostInternationalPrefix" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-delay">Delay *</label>
                <input type="text" name="rateCostDelay" id="input-delay" class="form-control" v-model="intCost.rateCostDelay">
                <p v-for="erro in errors.rateCostDelay" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-initial">Inicial *</label>
                <input type="text" name="rateCostInitial" id="input-initial" class="form-control" v-model="intCost.rateCostInitial">
                <p v-for="erro in errors.rateCostInitial" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-increment">Incremento *</label>
                <input type="text" name="rateCostIncrement" id="input-increment" class="form-control" v-model="intCost.rateCostIncrement">
                <p v-for="erro in errors.rateCostIncrement" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-price">Preço *</label>
                <input type="text" name="rateCostPrice" id="input-price" class="form-control" v-model="intCost.rateCostPrice" v-money="intCost.rateCostPrice">
                <p v-for="erro in errors.rateCostPrice" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>

import {VMoney} from 'v-money'
export default {
    props: [
        'intCost',
        'errors'
    ],

     data () {
      return {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false
        }
      }
    },  

    directives: {money: VMoney},
}
</script>

<style>

</style>