<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h3>Informações de Login</h3>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <label for="input-name">Nome</label>
                <input class="form-control" type="text" id="input-name" name="name" v-model="user.name">
                <p v-for="erro in errors.name" :key="erro" class="text-danger">{{erro}}</p>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-6">
                <label for="input-email">Email</label>
                <input class="form-control" type="email" id="input-email" name="email" v-model="user.email">
                <p v-for="erro in errors.email" :key="erro" class="text-danger">{{erro}}</p>
            </div>
            <div class="form-group col-6">
                <label for="input-password">Senha</label>
                <input class="form-control" type="password" id="input-password" name="password" v-model="user.password">
                <p v-for="erro in errors.password" :key="erro" class="text-danger">{{erro}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user' ,'errors' ]
}
</script>

<style>

</style>