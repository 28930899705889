<template>
    <div class="card" v-if="$canIf('trunk.store')">
        <form action="" method="post" @submit.prevent="createTrunk" id="trunk-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Cadastrar Tronco</h4>
                </div>
            </div>
            <div class="card-body">
                <trunk-form
                :errors="errors"
                :trunk="trunk"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Cadastrar</button>
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
import  TrunkForm from "@/components/views/trunks/_Form";
export default {

    components: {
        'trunk-form': TrunkForm,
    },

    data(){
        return {
            errors: {},
            trunk: {},
        }
    },

    methods: {
        createTrunk() {
            var dados = new FormData(document.getElementById('trunk-form'));

            this.$httpApi.post('troncos/store',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Trunks.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }

}
</script>

<style>

</style>