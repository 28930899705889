<template>
    <div class="card" v-if="$canIf('ratecostinternational.update')">
        <form action="" method="post" @submit.prevent="editIntCost" id="intCost-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Tarifa Internacional</h4>
                </div>
            </div>
            <div class="card-body">
                <int-cost-form
                :errors="errors"
                :intCost="intCost"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
import  IntCostForm from "@/components/views/intCosts/Costs/_Form";
export default {

    components: {
        'int-cost-form': IntCostForm,
    },

    props: [
        'group_id',
        'intCost_id'
    ],

    created() {
        this.$httpApi.get('ratecostinternational/show/'+this.intCost_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.intCost = res.data.item
        })
    },

    data(){
        return {
            errors: {},
            intCost: {},
        }
    },

    methods: {
        editIntCost() {
            var dados = new FormData(document.getElementById('intCost-form'));
            dados.append('_method', 'PUT');
            dados.append('rateCostInternationalGroup', this.group_id);

            this.$httpApi.post('ratecostinternational/update/'+this.intCost_id,dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'IntCostCosts.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }

}
</script>

<style>

</style>