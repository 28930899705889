<template>
    <div>
        <div class="d-flex justify-content-between">
            <h4>Lista de Planos</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('nplans.store') || $canIf('iplans.store')" :to="{ name: 'RatePlans.Create' }" class="btn btn-success btn-sm d-flex align-items-center pt-3 pb-3 h-50">Cadastrar Plano</router-link>
                <button v-if="$canIf('nplans.store') || $canIf('iplans.store')" class="btn bg-warning btn-sm ml-2 h-50" @click="openModalCSV">Importar CSV</button>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Descrição</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="plan in plans" :key="plan.idRatePlan">

                        <td data-title="Id">{{plan.idRatePlan}}</td>
                        <td data-title="Descrição">{{plan.ratePlanDescription}}</td>
                        <td class="acoes">
                            <router-link v-if="$canIf('nplans.update') || $canIf('iplans.update')" :to="{ name: 'RatePlans.Edit', params: {plan_id: plan.idRatePlan}}" class="m-1 btn btn-sm bg-info" title="Editar Plano"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('nplans.destroy') || $canIf('iplans.destroy')" title="Deletar Plano" class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(plan.idRatePlan, plan.idRoutes)"><i class="fas fa-trash-alt"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="modalCSV" class="modal-fundo" @click.self="closeModalCSV">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="importFile" enctype="multipart/form-data" id="formFile">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Importação de arquivo CSV</h4>
                            <a class="btn btn-sm bg-info" href="http://astbilling.local/" target="_blank">Ver modelo</a>
                            <span class="btn-fechar" @click="closeModalCSV">x</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <label for="formFile" class="form-label">Arquivo (Formato CSV apenas):</label>
                        <input class="form-control" type="file" name="file">
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <button class="btn bg-danger btn-sm" @click.prevent="closeModalCSV">Fechar</button>
                            <button class="btn bg-success btn-sm" @click="closeModalCSV">Importar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <confirm-delete
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Plano?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>
    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: {
        ConfirmDelete
    },

    created() {
        this.getRatePlans();
    },

    data() {
        return {
            plans: [],
            isConfirmDeleteModalVisible: false,
            plan: "",
            route: "",
        }
    },

    methods: {
        getRatePlans() {
            this.$httpApi.get('nplans',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.plans = res.data.items;

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteRatePlan(id, idRota) {
            this.$httpApi.delete('nplans/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$store.dispatch('criarMensagem', [
                    res.data.message,
                    "success"
                ])
                this.$httpApi.delete('routes/'+idRota,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                }).then(res => {
                    this.getRatePlans()
                    this.$store.dispatch('criarMensagem',[
                        res.data.message,
                        'success'
                    ])
                }).catch(res => {
                    this.getRoutes()
                    if(res.response.data.errors){
                        this.errors = res.response.data.errors
                    }
                    this.$store.dispatch('exibeErro', [
                        res.response
                    ])
                })
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
                this.getRatePlans()
            })
        },

        openModalCSV() {
            document.getElementById('modalCSV').style = "display: block;"
        },

        closeModalCSV() {
            document.getElementById('modalCSV').style = "display: none;"
        },

        importFile() {
            var inputFile = new FormData(document.getElementById('formFile'));

            this.$httpApi.post('rplans/import/'+this.plan_id, inputFile, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getRatePlans();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ]);
            }).catch(res => {
                this.getRatePlans();
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        showConfirmDeleteModal(id, route) {
            this.plan = id;
            this.route = route;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.plan = "";
            this.route = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteRatePlan(this.plan, this.route);
            this.closeConfirmDeleteModal();
        },
    }

}
</script>

<style>

</style>