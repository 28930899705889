<template>
    <div class="card" v-if="$canIf('customergroups.store')">
        <form action="" method="post" @submit.prevent="createGroup" id="customerGroup-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Cadastrar Grupo de Clientes</h4>
                </div>
            </div>
            <div class="card-body">
                <customer-group-form
                :errors="errors"
                :customer_group="group"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Cadastrar</button>
                <router-link :to="{ name: 'CustomerGroups.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  CustomerGroupForm from "@/components/views/customerGroups/_Form";
export default {
    components: {
        'customer-group-form': CustomerGroupForm,
    },

    data(){
        return {
            errors: {},
            group: {},
        }
    },

    methods: {
        createGroup() {
            var dados = new FormData(document.getElementById('customerGroup-form'));
            this.$httpApi.post('customergroups/store',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'CustomerGroups.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }

}
</script>

<style>

</style>