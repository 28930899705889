<template>
    <div>
        <div class="d-flex justify-content-between">
            <h4>Lista de Rotas Internacionais</h4>
            <router-link v-if="$canIf('routesIntl.create')" :to="{ name: 'IntRoutes.Create' }" class="btn btn-success btn-sm d-flex align-items-center pt-3 pb-3" style="height: fit-content">Cadastrar Rota</router-link>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Descrição</th>
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="route in routes" :key="route.id">

                        <td data-title="Id">{{route.idRoute}}</td>
                        <td data-title="Descrição">{{route.routeDescription}}</td>
                        <td class="acoes">

                            <router-link v-if="$canIf('routesIntl.index')" :to="{ name: 'IntRatePlans.Index', params: {route_id: route.idRoute}}" class="m-1 btn btn-sm bg-primary" title="Tarifas"><i class="fas fa-dollar-sign"></i></router-link>
                            <router-link v-if="$canIf('routesIntl.edit')" :to="{ name: 'IntRoutes.Edit', params: {route_id: route.idRoute}}" class="m-1 btn btn-sm bg-info" title="Editar Rota"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('routesIntl.delete')" title="Deletar Rota " class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(route.idRoute)"><i></i><i class="fas fa-trash-alt"></i></button>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <confirm-delete
            v-show="isConfirmDeleteModalVisible"
            modalHeadline="Excluir Rota?"
            deleteMessage="Você Tem Certeza?"
            @deleteRecordEvent="deleteItem"
            @close="closeConfirmDeleteModal"
        ></confirm-delete>
    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: {
        ConfirmDelete
    },

    created() {
        this.getRoutes();
    },

    data() {
        return {
            routes: [],
            isConfirmDeleteModalVisible: false,
            route: "",
        }
    },

    methods: {
        getRoutes() {
            this.$httpApi.get('routesIntl',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.routes = res.data.items;

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteRoute(id) {
            this.$httpApi.delete('routesIntl/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getRoutes()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getRoutes()
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        showConfirmDeleteModal(id) {
            this.route = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.route = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteRoute(this.route);
            this.closeConfirmDeleteModal();
        },
    }

}
</script>

<style>

</style>