<template>
    <div class="card" v-if="$canIf('operadoras.store')">
        <form action="" method="post" @submit.prevent="createCarrier" id="carrier-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Cadastrar Operadora</h4>
                </div>
            </div>
            <div class="card-body">
                <carrier-form
                :errors="errors"
                :carrier="carrier"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Cadastrar</button>
                <router-link :to="{ name: 'Carriers.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  CarrierForm from "@/components/views/carriers/_Form";
export default {

    components: {
        'carrier-form': CarrierForm,
    },

    data(){
        return {
            errors: {},
            carrier: {},
        }
    },

    methods: {
        createCarrier() {
            var inputFile = document.getElementById('carrier-form');
            var dados = new FormData(inputFile);

            this.$httpApi.post('operadoras/store',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Carriers.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }

}
</script>

<style>

</style>