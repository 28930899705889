<template>
        <div class="d-flex flex-wrap justify-content-start">

            <div class="form-group flex-fill">
                <label for="input-description">Descrição *</label>
                <input type="text" name="description" id="input-description" class="form-control" :value="intCostGroup.description">
                <p v-for="erro in errors.description" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>
export default {
    props: [
        'intCostGroup',
        'errors'
    ],
}
</script>

<style>

</style>