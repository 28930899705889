<template>
    <div class="card">
        <div class="d-flex justify-content-between pt-2 pb-2 align-items-end card-header" style="height: fit-content">
            <h5>Permissões do grupo: {{group.name}}</h5>
        </div>
        <div class="card-body">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Nome</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(2)">Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="permission in group.permissions" :key="permission.id">
                        <td data-title="Id">{{permission.id}}</td>
                        <td data-title="Nome">{{permission.name}}</td>
                        <td data-title="Descrição">{{permission.description}}</td>
                    </tr>
                </tbody>
                
            </table>
        </div>
        <div class="card-footer border-top">
            <router-link :to="{ name: 'Roles.PermissionGroups' }" class="btn bg-danger">Voltar</router-link>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.$httpApi.get('permissiongroups/show/'+this.group_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.group.name = res.data.name;
            this.group.description = res.data.description;
            this.group.permissions = res.data.permissions;
        })
    },
    
    props: ['group_id'],

    data() {
        return {
            errors: {},
            group: {
                name: String,
                description: String,
                permissions: Array,
            }
        }
    },
}
</script>

<style>

</style>