<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <h3>Dados Gerais</h3>
                </div>
            </div>
        </div>

        <div class="flex-row row">
                <dash-icon
                cor="red"
                largura=4
                titulo="Clientes"
                icone="fas fa-user"
                :quantidade="infos.customers"
                ></dash-icon>

            
                <dash-icon
                cor="blue"
                largura=4
                titulo="Contas Voip"
                icone="fas fa-phone-square"
                :quantidade="infos.subscribers"
                ></dash-icon>

                <dash-icon
                cor="yellow"
                largura=4
                titulo="Dids"
                icone="fas fa-phone"
                :quantidade=" infos.dids "
                ></dash-icon>

        </div>
        <br>

        <div v-if="ligacoes != 0">
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <h3>Ligações</h3>
                    </div>
                </div>
            </div>
            <div class="card">
                <table class="table">
                        <thead class="bg-light">
                            <tr>
                                <th>Cliente</th>
                                <th>Ramais</th>
                                <th>Ligações</th>
                                <th>Saldo</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="ligacao in ligacoes" :key="ligacao.nome">
                                <td>{{ ligacao.nome }}</td>
                                <td>{{ ligacao.ramaisAtivos }} / {{ ligacao.ramaisTotais }}</td>
                                <td>{{ ligacao.ligacoesAtivas }}</td>
                                <td>R$ {{ ligacao.saldo }}</td>
                            </tr>
                        </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
import DashIcon from "@/components/layouts/CardIcon";
export default {
    components: { 
        DashIcon,
    },
    
    created() {
        this.getInfos();
    },

    data() {
        return {
            infos: {},
            ligacoes : [],
        }
    },

    methods: {
        getInfos() {
            this.$httpApi.get('home', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.infos      = res.data.items
                this.ligacoes   = res.data.ligacoes

            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        }
    }
}
</script>

<style>

</style>