<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Planos do assinante: {{subscriber.username}} </h4>
            <div class="d-flex">
                <router-link :to="{ name: 'Subscribers.Index' }" class="btn bg-secondary btn-sm">Voltar para Assinantes</router-link>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-end">
                    <h5>Plano Nacional</h5>
                </div>
            </div>
            <div class="card-body" v-if="ratePlan">
                <div class="row">
                    <div class="col-md-4">
                        <p><strong>Descrição</strong>: {{ratePlan.ratePlanDescription}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <p><strong>VC1</strong>: {{ratePlan.ratePlanVC1}}</p>
                    </div>
                    <div class="col-md-4">
                        <p><strong>VC2</strong>: {{ratePlan.ratePlanVC2}}</p>
                    </div>
                    <div class="col-md-4">
                        <p><strong>VC3</strong>: {{ratePlan.ratePlanVC3}}</p>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <p><strong>Local</strong>: {{ratePlan.ratePlanLC}}</p>
                    </div>
                    <div class="col-md-6">
                        <p><strong>Longa Distância</strong>: {{ratePlan.ratePlanLDN}}</p>
                    </div>
                </div>
                

                <div class="row">
                    <div class="col-md-4">
                        <p><strong>Rate Start</strong>: {{ratePlan.ratePlanDelay}}</p>
                    </div>
                    <div class="col-md-4">
                        <p><strong>Rate Mínimo</strong>: {{ratePlan.ratePlanInitial}}</p>
                    </div>
                    <div class="col-md-4">
                        <p><strong>Rate Cycle</strong>: {{ratePlan.ratePlanIncrement}}</p>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button v-if="ratePlan && $canIf('nplans.update')" title="Alterar Plano" class="m-1 btn btn-sm bg-info" @click.prevent="openModalRatePlan">Alterar Plano</button>
                <button v-if="!ratePlan && $canIf('nplans.store')" title="Cadastrar Plano" class="m-1 btn btn-sm bg-success" @click.prevent="openModalRatePlan">Cadastrar Plano</button>
                <button v-if="ratePlan && $canIf('nplans.destroy')" title="Remover Plano" class="m-1 btn btn-sm bg-danger" @click.prevent="removePlanNac">Remover Plano</button>
                <button v-if="ratePlan && $canIf('nplans.export')" title="Remover Plano" class="m-1 btn btn-sm bg-default" @click.prevent="exportPlan">Exportar Tarifas</button>
            </div>

            <a href="" class="d-none" id="export-plan" target="_blank"></a>
        </div>

        <div id="modalRatePlan" class="modal-fundo" @click.self="closeModalRatePlan">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="changeRatePlan" enctype="multipart/form-data" id="ratePlan-form">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Cadastro de Plano Nacional</h4>
                            <span class="btn-fechar" @click="closeModalRatePlan">x</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="input-nacional" class="form-label">Lista de planos:</label>
                            <select name="ratePlan" id="input-nacional" class="form-control">
                                <option value="">Selecione uma opção</option>
                                <option v-for="plan in ratePlans" :value="plan.idRatePlan" :key="plan.idRatePlan">{{plan.ratePlanDescription}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <button class="btn bg-danger btn-sm" @click.prevent="closeModalRatePlan">Fechar</button>
                            <button class="btn bg-success btn-sm">Salvar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-end">
                    <h5>Plano Internacional</h5>
                </div>
            </div>
            <div class="card-body" v-if="intRatePlan">
                <div class="row">
                    <div class="col-md-8">
                        <p><strong>Descrição</strong>: {{intRatePlan.routeDescription}}</p>
                    </div>
                    <div class="col-md-4">
                        <router-link v-if="$canIf('iplans.showByGroup')" :to="{ name: 'IntRatePlans.Index', params: {route_id: intRatePlan.idRoute}}" class="m-1 btn btn-sm bg-primary">Visualizar Tarifas</router-link>
                        <button v-if="intRatePlan && $canIf('iplans.export')" title="Exportasr Tarifa" class="m-1 btn btn-sm bg-default" @click.prevent="exportPlanIntl">Exportar Tarifa</button>
                    </div>
                </div>
                
            </div>
            
            <div class="card-footer" v-if="countries && intRatePlan">
                <h6><strong>Países disponíveis para o assinante</strong>:</h6>
                <ul>
                    <li v-for="country in countries" :key="country.id" class="mb-3 flex-row">{{country.countries.countryCode}} - {{country.countries.countryName}} <span v-if="$canIf('intlcallallow.delete')" @click="deleteCountry(country.id)" class="ml-1 text-danger" style="cursor: pointer;">Deletar</span></li>
                </ul>
            </div>

            <div class="card-footer">
                <button v-if="intRatePlan && $canIf('iplans.update')" title="Alterar Plano" class="m-1 btn btn-sm bg-info" @click.prevent="openModalIntRatePlan">Alterar Plano</button>
                <button v-if="!intRatePlan && $canIf('iplans.store')" title="Cadastrar Plano" class="m-1 btn btn-sm bg-success" @click.prevent="openModalIntRatePlan">Cadastrar Plano</button>
                <button v-if="intRatePlan && $canIf('iplans.destroy')" title="Remover Plano" class="m-1 btn btn-sm bg-danger" @click.prevent="removePlanIntl">Remover Plano</button>
                <button v-if="intRatePlan && $canIf('country.index')" title="Países" class="m-1 btn btn-sm bg-warning" @click.prevent="openModalCountry">Países</button>
            </div>

            <a href="" class="d-none" id="export-intl" target="_blank"></a>

            <div id="modalIntRatePlan" class="modal-fundo" @click.self="closeModalIntRatePlan">
                <div class="card modal-conteudo">
                    <form action="" method="post" @submit.prevent="changeIntRatePlan" enctype="multipart/form-data" id="intRatePlan-form">
                        <div class="card-header">
                            <div class="d-flex align-items-center justify-content-between pb-3">
                                <h4 class="m-0">Cadastro de Plano Internacional</h4>
                                <span class="btn-fechar" @click="closeModalIntRatePlan">x</span>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="input-interNacional" class="form-label">Lista de planos:</label>
                                <select name="intRatePlan" id="input-interNacional" class="form-control">
                                    <option value="">Selecione uma opção</option>
                                    <option v-for="plan in intRatePlans" :value="plan.idRoute" :key="plan.idRoute">{{plan.routeDescription}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex justify-content-between">
                                <button class="btn bg-danger btn-sm" @click.prevent="closeModalIntRatePlan">Fechar</button>
                                <button class="btn bg-success btn-sm">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div id="modalCountry" class="modal-fundo" @click.self="closeModalCountry">
                <div class="card modal-conteudo">
                    <form action="" method="post" @submit.prevent="addCountry" enctype="multipart/form-data" id="country-form">
                        <div class="card-header">
                            <div class="d-flex align-items-center justify-content-between pb-3">
                                <h4 class="m-0">Cadastro de Países</h4>
                                <span class="btn-fechar" @click="closeModalCountry">x</span>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="input-country" class="form-label">Lista de países:</label>
                                <select name="country" id="input-country" class="form-control">
                                    <option value="">Selecione uma opção</option>
                                    <option v-for="country in freeCountries" :value="country.countryCode" :key="country.countryCode">{{country.countryName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex justify-content-between">
                                <button class="btn bg-danger btn-sm" @click.prevent="closeModalCountry">Fechar</button>
                                <button class="btn bg-success btn-sm">Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: [
        'customer_id',
        'subscriber_id',
    ],
    created() {
        this.getPlans();

        this.$httpApi.get('nplans',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.ratePlans = res.data.items;
        });

        this.$httpApi.get('routesIntl',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.intRatePlans = res.data.items;
        });
    },

    data() {
        return {
            customer: {},
            subscriber: {},
            havePlan: {},
            ratePlan: {},
            intRatePlan: {},
            ratePlans: [],
            intRatePlans: [],
            countries: [],
            freeCountries: [],
            verifica: "",
        }
    },

    methods: {
        
        getPlans() {
            this.$httpApi.get('subscribers/'+this.subscriber_id+'/ratePlan',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.subscriber = res.data.subscriber;
                this.havePlan = res.data.havePlan;
                if (this.havePlan) {
                    if (this.havePlan.idRatePlanInternationl) {
                        this.$httpApi.get('routesIntl/'+this.havePlan.idRatePlanInternationl,{
                            headers: {
                                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                            },
                        }).then(res => {
                            this.intRatePlan = res.data.item;
                        });
                    } else {
                        this.intRatePlan = null;
                    }
                    if (this.havePlan.idRatePlan) {
                        this.$httpApi.get('nplans/show/'+this.havePlan.idRatePlan,{
                            headers: {
                                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                            },
                        }).then(res => {
                            this.ratePlan = res.data.dados;
                        });
                    } else {
                        this.ratePlan = null;
                    }
                } else {
                    this.intRatePlan = null;
                    this.ratePlan = null;
                }
                this.getCountry();
            });
        },

        getCountry() {
            this.$httpApi.get('subscribers/freeCountries/'+this.subscriber_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.freeCountries = res.data.items;
                this.$httpApi.get('intlcallallow/show',{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                    params: {
                        subscriber: this.subscriber.username,
                    }
                }).then(res => {
                    this.countries = res.data.dados;
                }).catch(
                    this.countries = null
                )
            });
        },

        deleteCountry(id) {
            this.$httpApi.delete('intlcallallow/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(() => {
                this.getCountry();
                this.$store.dispatch('criarMensagem',[
                    'País desvinculado com sucesso',
                    'success'
                ])
            })
        },

        changeRatePlan() {
            var dados = new FormData(document.getElementById('ratePlan-form'));
            this.$httpApi.get('nplans/show/'+dados.get('ratePlan'),{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.ratePlan = res.data.dados;
                this.verifica = "nac";
                this.savePlans();
            });
            this.closeModalRatePlan();
            
        },

        exportPlan(){
            this.$httpApi.post('nplans/export/'+ this.havePlan.idRatePlan, '', {
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                var link = document.getElementById('export-plan');
                link.setAttribute('href', res.data.item);
                link.click();
            }).catch( res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        removePlanNac() {
            this.$httpApi.get('subscribers/'+ this.havePlan.id +'/delRatePlanNac',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.ratePlan = null

                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            });
            this.getPlans();
        },

        changeIntRatePlan() {
            var dados = new FormData(document.getElementById('intRatePlan-form'));
            console.log(dados.get('intRatePlan'));
            this.$httpApi.get('routesIntl/'+dados.get('intRatePlan'),{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.intRatePlan = res.data.item;
                this.verifica = "int";
                this.savePlans();
            });
            this.closeModalIntRatePlan();
        },

        exportPlanIntl(){
            this.$httpApi.post('routesIntl/'+ this.intRatePlan.idRoute +'/export', '', {
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                var link = document.getElementById('export-intl');
                link.setAttribute('href', res.data.item);
                link.click();
            }).catch( res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        removePlanIntl() {
            this.$httpApi.get('subscribers/'+ this.havePlan.id +'/delRatePlanIntl',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                this.intRatePlan = {};

                this.getPlans();

                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            });
        },

        savePlans() {
            if (this.havePlan){
                if(this.verifica == "int"){
                    this.addPlan(this.intRatePlan.idRoute, 'addRatePlanIntl')
                } else {
                    this.addPlan(this.ratePlan.idRatePlan, 'addRatePlanNac')
                }
                this.getPlans();
            } else {
                if (this.ratePlan) {
                    this.$httpApi.post('subscribers/'+this.subscriber_id+'/addRatePlan',{
                        ratePlan: this.ratePlan.idRatePlan
                    },{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                        },
                    }).then(res => {
                        // this.$router.push({ name: 'Subscribers.Index' })
                        this.$store.dispatch('criarMensagem',[
                            res.data.message,
                            "success"
                        ])
                        this.getPlans();
                    });
                }

                if(this.intRatePlan){
                    this.$httpApi.post('subscribers/'+this.subscriber_id+'/addRatePlan',{
                        ratePlanInternational: this.intRatePlan.idRoute,
                    },{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                        },
                    }).then(res => {
                        this.$store.dispatch('criarMensagem',[
                            res.data.message,
                            'success'
                        ])
                        this.getPlans();
                    });
                }
            }
        },

        addPlan(id, rota){
            this.$httpApi.post('subscribers/'+this.havePlan.id+'/'+rota,{
                plan: id,
            },{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
            this.getPlans();
        },


        addCountry() {
            var dados = new FormData(document.getElementById('country-form'));
            this.$httpApi.post('intlcallallow/store',{
                subscriber: this.subscriber.username,
                countryCode: dados.get('country'),
            },{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCountry();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ]).catch(res => {
                    if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
                })
            });
            this.closeModalCountry();
        },

        openModalRatePlan() {
            document.getElementById('modalRatePlan').style = "display: block;"
        },

        closeModalRatePlan() {
            document.getElementById('modalRatePlan').style = "display: none;"
        },

        openModalIntRatePlan() {
            document.getElementById('modalIntRatePlan').style = "display: block;"
        },

        closeModalIntRatePlan() {
            document.getElementById('modalIntRatePlan').style = "display: none;"
        },

        openModalCountry() {
            document.getElementById('modalCountry').style = "display: block;"
        },

        closeModalCountry() {
            document.getElementById('modalCountry').style = "display: none;"
        },
    }

}
</script>

<style scoped>

.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;

    animation-name: modal;
    animation-duration: 700ms;

}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

@keyframes modal {
    from {
        top: 30%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
    }
}


</style>