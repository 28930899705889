<template>
  <transition name="modal-fade">
    <div class="card modal-conteudo">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="orange"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <h3 class="text-center text-lg leading-6 font-medium text-gray-900" id="modal-headline" >{{ modalHeadline }} </h3>
            <p class="text-center text-sm text-gray-500"> {{ deleteMessage }} </p>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    
      <div class="card-footer">
        <div class="d-flex justify-content-center">
          <button type="button" @click="deleteRecord" class="btn btn-danger">Sim</button>
          <button type="button" @click="close" class="btn btn-secondary">Não </button>
        </div>
      </div> 
    </div>
  </transition>
</template>
<script>
export default {
  name: "modal",
  props: {
    modalHeadline: {
      type: String,
      required: true,
    },
    deleteMessage: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteRecord() {
      this.$emit("deleteRecordEvent");
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 160px;
}
</style>