<template>
    <div class="card">
        <form v-if="trunk.rate_cost" action="" method="post" @submit.prevent="editRateCost" id="rateCost-form">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-end">
                    <h4>Editar Tarifa Nacional do tronco: {{trunk.trunkName}}</h4>
                    <div class="d-flex">
                        <button v-if="$canIf('rtrunk.delete')" class="btn btn-danger btn-sm mr-2" @click.prevent="showConfirmDeleteModal()">Deletar</button>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-end">
                <p>Tarifa referente ao custo de uma ligação nacional.</p>
                </div>
            </div>
            <div class="card-body">
                <rate-cost-form
                :errors="errors"
                :rateCost="rateCost"
                />
            </div>
            <div class="card-footer">
                <button v-if="$canIf('rtrunk.update')" class="btn bg-success mr-2">Atualizar</button>
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>

        <form v-else action="" method="post" @submit.prevent="createRateCost" id="rateCost-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Cadastrar tarifa nacional do tronco: {{trunk.trunkName}}</h4>
                </div>
            </div>
            <div class="card-body">
                <rate-cost-form
                :errors="errors"
                :rateCost="rateCost"
                />
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Cadastrar</button>
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>

        <confirm-delete
            v-show="isConfirmDeleteModalVisible"
            modalHeadline="Excluir Tarifa?"
            deleteMessage="Você Tem Certeza?"
            @deleteRecordEvent="deleteItem"
            @close="closeConfirmDeleteModal"
        ></confirm-delete>
    </div>
</template>

<script>
import  RateCostForm from "@/components/views/rateCosts/_Form";
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {

    components: {
        'rate-cost-form': RateCostForm,
        ConfirmDelete
    },

    props: [
        'trunk_id',
        // 'rateCost_id',
    ],

    created() {
        this.getRateCost();
    },

    data(){
        return {
            errors: {},
            rateCost: {},
            trunk: {},
            isConfirmDeleteModalVisible: false,
        }
    },

    methods: {
        getRateCost() {
            this.$httpApi.get('troncos/show/'+this.trunk_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.trunk = res.data.items;
                if (res.data.items.rate_cost) {
                    this.rateCost = res.data.items.rate_cost;
                } else {
                    this.rateCost = {};
                }
            })
        },

        editRateCost() {
            var dados = new FormData(document.getElementById('rateCost-form'));
            dados.append('idTrunk', this.trunk_id)
            dados.append('_method', 'PUT');

            this.$httpApi.post('ratecost/update/'+this.rateCost.idrateCost, dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getRateCost();
                this.$store.dispatch('criarMensagem',[
                    res.response.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getRateCost();
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        createRateCost() {
            var dados = new FormData(document.getElementById('rateCost-form'));
            dados.append('idTrunk', this.trunk_id)

            this.$httpApi.post('ratecost/store',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getRateCost();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getRateCost();
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        deleteRateCost() {
            this.$httpApi.delete('ratecost/delete/'+this.rateCost.idrateCost,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getRateCost();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                this.getRateCost();
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        showConfirmDeleteModal() {
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteRateCost();
            this.closeConfirmDeleteModal();
        },
    },
}
</script>

<style>

</style>