<template>
    <div class="card p-0 m-2" style="height: fit-content">
        <div class="card-header  pb-3">
            <div class="d-flex flex-row align-items-center justify-content-between">

                <div class="d-flex align-items-center">
                    <h5 class="m-0 pr-2 titulo" ><strong>{{group.name}}</strong></h5>
                    <a @click.prevent="" style="cursor: default" href="#" class="red-tooltip" :title="group.description" data-toggle="tooltip" data-placement="bottom" ><i class="fa fa-question-circle" style="color: #777777;"></i></a>
                </div>

                <div class="form-group m-0 d-flex align-items-center">
                    <label class="switch">
                        <input type="checkbox" class="permissionGroup" :id="group.name" checked>
                        <span class="slider round"></span>
                    </label>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="d-flex flex-column">
                <div class="form-group" v-for="permission in group.permissions" :key="permission.id">

                    <label class="switch">
                        <input v-if="permissions.includes(permission.name)" checked type="checkbox" :class="'permissao '+ group.name" :id="permission.name" :name="permission.id">
                        <input v-else type="checkbox" :class="'permissao '+ group.name" :id="permission.name" :name="permission.id">
                        <span class="slider round"></span>
                    </label>
                    <!-- <input type="checkbox" :class="'permissao '+ group.name" :id="permission.name" :name="'permissoes['+permission.id+']'"> -->

                    <label v-if="(typeof permission.name.split('.')[1]) == 'undefined' " class="ml-2 mr-1 permission-name" :for="permission.name">
                        {{permission.name}}
                    </label>

                    <label v-else :for="permission.name" class="ml-2 mr-1 permission-name">
                        {{permission.name.split('.')[1]}}
                    </label>

                    <a @click.prevent="" style="cursor: default" href="#" class="red-tooltip" :title="permission.description" data-toggle="tooltip" data-placement="right" ><i class="fa fa-question-circle" style="color: #777777;"></i></a>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'group',
        'permissions',
    ],

    mounted() {
        var permissoes = document.querySelectorAll('.permissao.'+this.group.name);
        permissoes.forEach(element => {
            if (!element.checked) {
                var grupo = document.getElementById(this.group.name);
                grupo.checked = false;
            }
        });

        document.getElementById(this.group.name).addEventListener('click', element => {
            permissoes = document.querySelectorAll('.'+this.group.name);
            if (element.target.checked) {
                permissoes.forEach(element2 => {
                    element2.checked = true;
                });
            } else {
                permissoes.forEach(element2 => {
                    element2.checked = false;
                });
            }
        })

        document.querySelectorAll('.permissao.'+this.group.name).forEach(element => {
            element.addEventListener('click', function() {
                if(!element.checked) {
                    var grupo = this.getAttribute('id').split('.')[0];
                    grupo = document.getElementById(grupo);
                    grupo.checked = false;
                }
            })
        })
    },
}
</script>

<style scoped>
    .titulo{
        text-transform: capitalize;
        font-family:sans-serif
    }

    .permission-name {
        font-size: 0.9rem;
        font-weight: bold;
    }

/* ////////////////////////////////////////////// */

    .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 17px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .3s;
        transition: .3s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .3s;
        transition: .3s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

/* ////////////////////////////////////////////// */
</style>