import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		mensagemAlert: {
			text: '',
			type: '',
		},
		showAlert: false,
		logado: sessionStorage.getItem('Auth_Token') ? true : false,
		permissions: sessionStorage.getItem('Permissions') ? sessionStorage.getItem('Permissions') : null,
		admin: sessionStorage.getItem('Admin') == 'true',
		redirect: false,
		readyToLoad: true,
		rotaAtual: '',
		user_name: sessionStorage.getItem('User_name'),
		config: {
			name: sessionStorage.getItem('Config_name') ? sessionStorage.getItem('Config_name') : null,
			logo: sessionStorage.getItem('Config_logo') ? sessionStorage.getItem('Config_logo') : null,
			address: sessionStorage.getItem('Config_address') ? sessionStorage.getItem('Config_address') : null,
			number: sessionStorage.getItem('Config_number') ? sessionStorage.getItem('Config_number') : null,
			province: sessionStorage.getItem('Config_province') ? sessionStorage.getItem('Config_province') : null,
			cep: sessionStorage.getItem('Config_cep') ? sessionStorage.getItem('Config_cep') : null,
			city: sessionStorage.getItem('Config_city') ? sessionStorage.getItem('Config_city') : null,
			state: sessionStorage.getItem('Config_state') ? sessionStorage.getItem('Config_state') : null,
			phone: sessionStorage.getItem('Config_phone') ? sessionStorage.getItem('Config_phone') : null,
			whatsapp: sessionStorage.getItem('Config_whatsapp') ? sessionStorage.getItem('Config_whatsapp') : null,
			domain: sessionStorage.getItem('Config_domain') ? sessionStorage.getItem('Config_domain') : null,
			email: sessionStorage.getItem('Config_email') ? sessionStorage.getItem('Config_email') : null,
		},		
	},
	getters: {
	},
	mutations: {
		limparMensagem(state){
			state.showAlert = false;
			state.mensagemAlert.text = '';
			state.mensagemAlert.type = '';
		},
		criarMensagem(state, payload){
			// state.redirect = true
			state.mensagemAlert = payload
			state.showAlert = true
		},
		logout(state){
			sessionStorage.removeItem('Auth_Token')
			sessionStorage.removeItem('Admin');
			sessionStorage.removeItem('Permissions');
			state.admin = false
			state.permissions = []
			state.logado = false
			router.push('/login')
		},
		exibeErro(state, payload){
			state.mensagemAlert = payload
			state.showAlert = true
		},
	},
	actions: {
		criarMensagem(context, payload){
			var mensagem = {
				text: payload[0],
				type: payload[1],
			}
			context.commit('criarMensagem', mensagem )
		},
		logout(context,payload){
			context.commit('logout', payload)
		},
		limparMensagem(context,payload){
			context.commit('limparMensagem', payload)
		},
		exibeErro(contexto, res){
			var texto = "";
			if(res[0].status == 500){
				if(res[0].data.message == "Token is invalid" || res[0].data.message == "Token is Expired" || res[0].data.message == "Authorization Token not found" ){
					texto = "Token Expirado";
					contexto.commit('logout', mensagem )
				} else {
					texto = "Erro Interno. Contate um Administrador";
				}
				
			}else if (res[0].status == 403){
				texto = "Você não tem autorização para utilizar esta função";
			} else {
				texto = res[0].data.message;
			}
			
			if(texto == "The given data was invalid."){
				texto = "Atente-se ao formulário";
			}
			var mensagem = {
				text: texto,
				type: "danger",
			}
			contexto.commit('exibeErro', mensagem )
		}
	},
	modules: {
	}
})
