<template>
        <div class="form-group">

            <label for="input-description">Descrição *</label>
            <input type="text" name="routeDescription" id="input-description" class="form-control" v-model="route.routeDescription">
            <p v-for="erro in errors.routeDescription" :key="erro" class="text-danger">{{erro}}</p>

        </div>
</template>

<script>
export default {
    props: ['route', 'errors'],
}
</script>

<style>

</style>