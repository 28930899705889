<template>
    <div class="card" v-if="$canIf('config.update')">
        <form action="" method="post" @submit.prevent="editConfig" id="config-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Configurações</h4>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    
                    <div class="form-group col-6">
                        <label for="input-name">Nome *</label>
                        <input type="text" name="name" id="input-name" class="form-control" :value='config.name'>
                        <p v-for="erro in errors.name" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-3">
                        <label for="input-phone">Telefone *</label>
                        <input type="text" name="phone" id="input-phone" class="form-control" :value='config.phone'>
                        <p v-for="erro in errors.phone" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-3">
                        <label for="input-whatsapp">Whatsapp *</label>
                        <input type="text" name="whatsapp" id="input-whatsapp" class="form-control" :value='config.whatsapp'>
                        <p v-for="erro in errors.whatsapp" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-6">
                        <label for="input-address">Endereço *</label>
                        <input type="text" name="address" id="input-address" class="form-control" :value='config.address'>
                        <p v-for="erro in errors.address" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-2">
                        <label for="input-number">Número *</label>
                        <input type="text" name="number" id="input-number" class="form-control" :value='config.number'>
                        <p v-for="erro in errors.number" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-province">Bairro *</label>
                        <input type="text" name="province" id="input-province" class="form-control" :value='config.province'>
                        <p v-for="erro in errors.province" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-cep">Cep *</label>
                        <input type="text" name="cep" id="input-cep" class="form-control" :value='config.cep'>
                        <p v-for="erro in errors.cep" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-city">Cidade *</label>
                        <input type="text" name="city" id="input-city" class="form-control" :value='config.city'>
                        <p v-for="erro in errors.city" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-state">Estado *</label>
                        <input type="text" name="state" id="input-state" class="form-control" :value='config.state'>
                        <p v-for="erro in errors.state" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-domain">Domínio *</label>
                        <input type="text" name="domain" id="input-domain" class="form-control" :value='config.domain'>
                        <p v-for="erro in errors.domain" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-3">
                        <label for="input-language">Idioma *</label>
                        <input type="text" name="language" id="input-language" class="form-control" :value='config.language'>
                        <p v-for="erro in errors.language" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="col-5">
                        <label for="input-arquivo">Logo *</label>
                        <input type="file" name="arquivo" id="input-arquivo" class="form-control" :value="config.arquivo">
                        <p v-for="erro in errors.arquivo" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="col-12">
                        <hr>
                        <h4>Configurações de email</h4>
                    </div>


                    <div class="form-group col-4">
                        <label for="input-email">Email *</label>
                        <input type="text" name="email" id="input-email" class="form-control" :value='config.email'>
                        <p v-for="erro in errors.email" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-email_host">Host *</label>
                        <input type="text" name="email_host" id="input-email_host" class="form-control" :value='config.email_host'>
                        <p v-for="erro in errors.email_host" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-2">
                        <label for="input-email_driver">Driver *</label>
                        <input type="text" name="email_driver" id="input-email_driver" class="form-control" :value='config.email_driver'>
                        <p v-for="erro in errors.email_driver" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-2">
                        <label for="input-email_port">Porta *</label>
                        <input type="text" name="email_port" id="input-email_port" class="form-control" :value="config.email_port">
                        <p v-for="erro in errors.email_port" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-email_username">Usuário *</label>
                        <input type="text" name="email_username" id="input-email_username" class="form-control" :value="config.email_username">
                        <p v-for="erro in errors.email_username" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-email_passwd">Senha *</label>
                        <input type="text" name="email_passwd" id="input-email_passwd" class="form-control" :value="config.email_passwd">
                        <p v-for="erro in errors.email_passwd" :key="erro" class="text-danger">{{erro}}</p>
                    </div>


                    <div class="form-group col-4">
                        <label for="input-email_encryption">Criptografia *</label>
                        <input type="text" name="email_encryption" id="input-email_encryption" class="form-control" :value="config.email_encryption">
                        <p v-for="erro in errors.email_encryption" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-email_from_address">From Address *</label>
                        <input type="text" name="email_from_address" id="input-email_from_address" class="form-control" :value="config.email_from_address">
                        <p v-for="erro in errors.email_from_address" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-email_from_name">From Name *</label>
                        <input type="text" name="email_from_name" id="input-email_from_name" class="form-control" :value="config.email_from_name">
                        <p v-for="erro in errors.email_from_name" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-4">
                        <label for="input-days_to_notify">Notificar *</label>
                        <input type="text" name="days_to_notify" id="input-days_to_notify" class="form-control" :value="config.days_to_notify">
                        <p v-for="erro in errors.days_to_notify" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                </div>
            </div>
            <div class="card-footer">
                <button class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'Config.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
export default {

    created() {
        this.$httpApi.get('config',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.config = res.data[0];
        }).catch(res => {
            if(res.response.data.errors){
                this.errors = res.response.data.errors
            }
            this.$store.dispatch('exibeErro', [
                res.response
            ])
        });
    },

    data(){
        return {
            errors: {},
            config: {},
        }
    },

    methods: {
        editConfig() {
            var dados = new FormData(document.getElementById('config-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('config/update/1', dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                sessionStorage.setItem('Config_name', res.data.item.name);
                sessionStorage.setItem('Config_logo', res.data.item.logo);
                sessionStorage.setItem('Config_address', res.data.item.address);
                sessionStorage.setItem('Config_number', res.data.item.number);
                sessionStorage.setItem('Config_province', res.data.item.province);
                sessionStorage.setItem('Config_cep', res.data.item.cep);
                sessionStorage.setItem('Config_city', res.data.item.city);
                sessionStorage.setItem('Config_state', res.data.item.state);
                sessionStorage.setItem('Config_phone', res.data.item.phone);
                sessionStorage.setItem('Config_whatsapp', res.data.item.name);
                sessionStorage.setItem('Config_domain', res.data.item.domain);
                sessionStorage.setItem('Config_email', res.data.item.email);

                this.$store.state.config.name = res.data.item.name;
                this.$store.state.config.logo = res.data.item.logo;
                this.$store.state.config.address = res.data.item.address;
                this.$store.state.config.number = res.data.item.number;
                this.$store.state.config.province = res.data.item.province;
                this.$store.state.config.cep = res.data.item.cep;
                this.$store.state.config.city = res.data.item.city;
                this.$store.state.config.state = res.data.item.state;
                this.$store.state.config.phone = res.data.item.phone;
                this.$store.state.config.whatsapp = res.data.item.whatsapp;
                this.$store.state.config.domain = res.data.item.domain;
                this.$store.state.config.email = res.data.item.email;
                
                this.$router.push({ name: 'Config.Index' })
                this.$store.dispatch('criarMensagem',[
                    'Configurações atualizadas com sucesso',
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    },
}
</script>

<style>

</style>