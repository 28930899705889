<template>
    <div class="card">
        <form action="" method="post" v-on:submit.prevent=''>
            <div class="card-header">
                <h4>Editar Grupo</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-4">
                        <label>Nome</label>
                        <input class="form-control" type="text" readonly id="input-name" :value='group.name'>
                        <p v-for="erro in errors.name" :key="erro" class="text-danger">{{erro}}</p>
                    </div>

                    <div class="form-group col-8">
                        <label for="input-description">Descrição</label>
                        <input class="form-control" type="text" id="input-description" name="description" v-model='group.description'>
                        <p v-for="erro in errors.description" :key="erro" class="text-danger">{{erro}}</p>
                    </div>
                </div>
                
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-center align-items-center">
                    <input class="btn btn-success mr-2" type="submit" value="Salvar" @click="editarGrupo">
                    <router-link :to="{ name: 'Roles.PermissionGroups' }" class="btn btn-danger">Voltar</router-link>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    created() {
        this.$httpApi.get('permissiongroups/show/'+this.group_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.group.name = res.data.name;
            this.group.description = res.data.description;
            this.group.permissions = res.data.permissions;
        })
    },
    
    props: ['group_id'],

    data() {
        return {
            errors: {},
            group: {
                name: String,
                description: String,
                permissions: Array,
            }
        }
    },

    methods: {
        editarGrupo() {
            this.$httpApi.put('permissiongroups/altdesc/'+this.group_id, this.group, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Roles.PermissionGroups' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                this.$store.state.redirect = true
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        }
    }
}
</script>

<style>

</style>