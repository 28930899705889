<template>
        <div class="row">
            
            <div class="form-group col-5">
                <label for="input-name">Nome *</label>
                <input type="text" name="carrierName" id="input-name" class="form-control" v-model="carrier.carrierName">
                <p v-for="erro in errors.carrierName" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-7">
                <label for="input-description">Descrição *</label>
                <input type="text" name="carrierDescription" id="input-description" class="form-control" v-model="carrier.carrierDescription">
                <p v-for="erro in errors.carrierDescription" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-4">
                <label for="input-contact-person">Pessoa para contato *</label>
                <input type="text" name="carrierContactPerson" id="input-contact-person" class="form-control" v-model="carrier.carrierContactPerson">
                <p v-for="erro in errors.carrierContactPerson" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-3">
                <label for="input-contact-phone">Telefone para contato *</label>
                <input type="text" name="carrierContactPhone" id="input-contact-phone" class="form-control" v-model="carrier.carrierContactPhone">
                <p v-for="erro in errors.carrierContactPhone" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-5">
                <label for="input-contact-email">Email para contato *</label>
                <input type="text" name="carrierContactEmail" id="input-contact-email" class="form-control" v-model="carrier.carrierContactEmail">
                <p v-for="erro in errors.carrierContactEmail" :key="erro" class="text-danger">{{erro}}</p>
            </div>

        </div>
</template>

<script>
export default {
    props: ['carrier', 'errors'],
}
</script>

<style>

</style>