<template>
    <div class="card" v-if="$canIf('routesIntl.edit')">
        <form action="" method="post" @submit.prevent="editRoute" id="route-form">
            <div class="card-header">
                <div class="d-flex">
                    <h4>Editar Rota</h4>
                </div>
            </div>
            <div class="card-body">
                <int-route-form
                :errors="errors"
                :route="route"
                />
                <div class="col-12 mt-5">
                  <div class="card">
                        <div class="card-header">
                      <h5>Selecione Gateways/Troncos disponíveis <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="top" title="É necessário selecionar um  ou mais Gateways/Troncos para salvar ou editar as configurações  "></i></h5>
                             </div>                            
                    <div class="d-flex justify-content-center" id="form-trunks">
                        <div class="col-5">
                            <div class="card">
                                <div class="card-header">
                                    <h5>Disponíveis</h5>
                                </div>
                                <div class="card-body">
                                    <table class="table">
                                        <tbody class="caixa" id="not-bound-box">
                                            <tr v-for="tronco in allTrunks" :key="tronco.idTrunk" class="not-bounds" :id="tronco.idTrunk">
                                                <td>
                                                    <input type="checkbox" class="form-control not-bound-checkbox" :id="tronco.idTrunk+'_checkbox'">
                                                </td>
                                                <td>{{tronco.trunkName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 d-flex flex-column align-self-center justify-content-center">
                            <div class="btn bg-brd btn-sm" @click="goRight">
                                <h5 class="m-0">
                                    <i class="fas fa-chevron-right text-white"></i>
                                </h5>
                            </div>
                            <br>
                            <div class="btn bg-brd btn-sm" @click="goLeft">
                                <h5 class="m-0">
                                    <i class="fas fa-chevron-left text-white"></i>
                                </h5>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between">
                                    <h5>Utilizados</h5>
                                    <div class="d-flex">
                                        <button class="btn bg-brd btn-sm" id="btn-subir" @click.prevent="goUp">
                                            <i class="fas fa-chevron-up text-white"></i>
                                        </button>
                                        <button class="btn bg-brd btn-sm" id="btn-descer" @click.prevent="goDown">
                                            <i class="fas fa-chevron-down text-white"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body pl-0 pr-0">
                                    <table class="table">
                                        <tbody class="caixa" id="bound-box">
                                            <tr v-for="tronco in trunks" :key="tronco.idTrunk" class="bounds trunks" :id="tronco.idTrunk">
                                                <td>
                                                    <input type="checkbox" class="form-control">
                                                </td>
                                                <td>{{tronco.trunkName}}</td>
                                                <td class="prioridade">{{tronco.priority}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="card-footer">
                <button v-if="$canIf('routesIntl.edit')" class="btn bg-success mr-2">Atualizar</button>
                <router-link :to="{ name: 'IntRoutes.Index' }" class="btn bg-danger">Voltar</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import  IntRouteForm from "@/components/views/intRoutes/_Form";
export default {

    components: {
        'int-route-form': IntRouteForm,
    },

    props: ['route_id'],

    created() {
        this.$httpApi.get('routesIntl/'+this.route_id,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.route = res.data.item  
            this.getTrunks();
        })
    },

    updated() {
        var vinculados = document.querySelectorAll('.bounds');
        vinculados.forEach((tronco, index) => {
           tronco.querySelector('.prioridade').innerHTML = index + 1; 
        });
    },

    data(){
        return {
            errors: {},
            route: {},
            trunks: [],
            allTrunks: [],
            idTrunks: [],
            newTrunks: [],
        }
    },

    methods: {
        editRoute() {
            var dados = new FormData(document.getElementById('route-form'));
            dados.append('_method', 'PUT');

            this.$httpApi.post('routesIntl/'+this.route_id, dados, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(() => {
                var trunks = document.querySelectorAll('.bounds');
                var update = [];
                trunks.forEach(tronco => {
                    update.push(tronco.getAttribute('id'));
                })
                this.$httpApi.post('routesinthavetrunk/addAll/'+this.route_id, update, {
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                }).then(() => {
                    this.$router.push({ name: 'IntRoutes.Index' })
                    this.$store.dispatch('criarMensagem',[
                        "Rota atualizada com sucesso",
                        'success'
                    ])
                })
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        getAllTrunks() {
            this.$httpApi.get('troncos',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.allTrunks = [];
                res.data.items.forEach(tronco => {
                    if (!this.idTrunks.includes(tronco.idTrunk)) {
                        this.allTrunks.push(tronco);
                    }
                })
            })
        },

        getTrunks() {
            this.$httpApi.get('routesinthavetrunk/show/'+this.route_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.trunks = [];
                this.idTrunks = [];
                if (res.data.status == 'info') {
                    this.getAllTrunks();
                    return;
                }
                res.data.items.forEach(tronco => {
                    this.idTrunks.push(tronco.idTrunk);
                    this.trunks.push({
                        id: tronco.id,
                        priority: this.trunks.length+1,
                        idTrunk: tronco.idTrunk,
                        trunkName: tronco.trunkName,
                    })
                });
                this.getAllTrunks();
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
                this.getAllTrunks();
            });
        },

        goRight() {
            var n_vinculados = document.querySelectorAll('.not-bounds');
            var mover = [];
            n_vinculados.forEach(item => {
                if (item.firstElementChild.firstElementChild.checked) {
                    this.allTrunks.forEach(tronco => {
                        if (tronco.idTrunk == item.getAttribute('id')) {
                            mover.push(tronco);
                        }
                    });
                }
            });
            mover.forEach(item => {
                const index = this.allTrunks.indexOf(item);
                if (index > -1) {
                    this.allTrunks.splice(index, 1);
                }
                this.trunks.push({
                    priority: this.trunks.length+1,
                    idTrunk: item.idTrunk,
                    trunkName: item.trunkName,
                })
            });
        },

        goLeft() {
            var vinculados = document.querySelectorAll('.bounds');
            var mover = [];
            vinculados.forEach(item => {
                if (item.firstElementChild.firstElementChild.checked) {
                    this.trunks.forEach(tronco => {
                        if (tronco.idTrunk == item.getAttribute('id')) {
                            mover.push(tronco);
                        }
                    });
                }
            });
            mover.forEach(item => {
                const position = this.trunks.indexOf(item);
                if (position > -1) {
                    this.trunks.splice(position, 1);
                }
                this.allTrunks.push({
                    idTrunk: item.idTrunk,
                    trunkName: item.trunkName,
                })
            });
        },

        goUp() {
            var vinculados = document.querySelectorAll('.bounds');
            vinculados.forEach((item, index) => {
                if (item.firstElementChild.firstElementChild.checked && index > 0) {
                    item.querySelector('.prioridade').innerHTML = index;
                    vinculados[index - 1].querySelector('.prioridade').innerHTML = index + 1;
                    document.getElementById('bound-box').insertBefore(item,vinculados[index - 1])
                    vinculados = document.querySelectorAll('.bounds');
                }
            });
        },

        goDown() {
            var vinculados = document.querySelectorAll('.bounds');

            for (let index = vinculados.length - 1; index >= 0; index--) {
                const item = vinculados[index];

                if (item.firstElementChild.firstElementChild.checked && index < vinculados.length - 1) {
                    if (typeof vinculados[index + 2] != undefined) {
                        document.getElementById('bound-box').insertBefore(item,vinculados[index + 2])
                    } else {
                        document.getElementById('bound-box').appendChild(item)
                    }
                    item.querySelector('.prioridade').innerHTML = index + 2;
                    vinculados[index + 1].querySelector('.prioridade').innerHTML = index + 1;
                    vinculados = document.querySelectorAll('.bounds');
                }
            }
        },
    },
}
</script>

<style>

</style>