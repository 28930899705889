<template>
    <div>
         <div class="card" >
            <div class="card-body">
                <form action="" method="post" id="form-search" class="d-flex flex-column  m-1" @submit.prevent="getIntRatePlansByDescriptionAndByPrefix">
                    <div class="row">
                        <div class="col-5 form-group">
                            <input type="text" class="form-control" id="input-source" name="prefix" v-model="prefix" value="" placeholder="Procure por Prefixo">
                        </div>
                         <div class="col-5 form-group">
                            <input type="text" class="form-control" id="input-source" name="description" v-model="description" value="" placeholder="Procure por descrição">
                        </div>                    
                        <div class="col-md-2">
                            <button class="btn bg-success btn-sm">Pesquisar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Lista de Planos Internacionais</h4>
            <div class="d-flex">
                <router-link v-if="$canIf('iplans.store')" :to="{ name: 'IntRatePlans.Create' }" class="btn btn-success btn-sm">Cadastrar Plano</router-link>
                <button v-if="$canIf('iplans.store')" class="btn bg-warning btn-sm ml-2 mr-2" @click="openModalCSV">Importar CSV</button>
                <button v-if="$canIf('iplans.index')" class="btn bg-info btn-sm ml-2 mr-2" @click="exportFile">Exportar CSV</button>
                <button v-if="$canIf('iplans.destroy')" class="btn btn-sm btn-danger ml-2"  @click="deleteAll">Excluir Todos</button>
            </div>

            <a href="" class="d-none" id="export-plan" target="_blank"></a>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(0)">Id</th>
                        <th style="cursor:pointer">Prefixo</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(1)">Descrição</th>
                        <th style="cursor:pointer" v-on:click="ordenaColuna(2)">Valor</th>
                        <!-- <th style="cursor:pointer" v-on:click="ordenaColuna(2)">Rota</th> -->
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="plan in plans.data" :key="plan.idRatePlanInternational">

                        <td data-title="Id">{{plan.idRatePlanInternational}}</td>
                        <td data-title="Prefixo">{{ plan.ratePlanInternationalPrefix }}</td>
                        <td data-title="Descrição">{{plan.ratePlanInternationalDescription}}</td>
                        <td data-title="Valor">{{plan.rate}}</td>
                        <td class="acoes">

                            <router-link v-if="$canIf('iplans.update')" :to="{ name: 'IntRatePlans.Edit', params: {int_plan_id: plan.idRatePlanInternational}}" class="m-1 btn btn-sm bg-info"><i class="fas fa-edit"></i></router-link>
                            <button v-if="$canIf('iplans.destroy')" title="Deletar" class="m-1 btn btn-sm bg-danger" @click.prevent="showConfirmDeleteModal(plan.idRatePlanInternational)"><i class="fas fa-trash-alt"></i></button>
                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="modalCSV" class="modal-fundo" @click.self="closeModalCSV">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="importFile" enctype="multipart/form-data" id="formFile">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0 ">Importação de arquivo CSV</h4>
                        </div>
                        <span class="btn-fechar" @click="closeModalCSV">x</span>
                    </div>
                    <div class="card-body">
                        <h6>Formato</h6>
                        <div class="row">
                            <div class="col-md-8 border">
                                Prefixo, Nome, Descarte, Minimo, Incremento, Valor <br>
                                7840,Abkhazia Fixed,1,1,1,0.193800 <br>
                                7940,Abkhazia Mobile,1,1,1,0.218500 <br>
                            </div>
                            <div class="col-md-4">
                                <a class="btn btn-sm bg-info" @click.prevent="getModel" target="_blank">Download do Modelo</a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <label for="formFile" class="form-label">Arquivo (Formato CSV apenas):</label>
                            <input class="form-control" type="file" name="file">
                        </div>

                        <br>
                        <br>

                        <div class="form-row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-11">
                                <input class="form-check-input" type="checkbox" name="update" id="update">
                                <label class="form-check-label" for="update">Para realizar somente um update das tarifas, marque esta opção</label>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <button class="btn bg-danger btn-sm" @click.prevent="closeModalCSV">Fechar</button>
                            <button class="btn bg-success btn-sm" @click="closeModalCSV">Importar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <confirm-delete
        v-show="isConfirmDeleteModalVisible"
        modalHeadline="Excluir Plano?"
        deleteMessage="Você Tem Certeza?"
        @deleteRecordEvent="deleteItem"
        @close="closeConfirmDeleteModal"
        ></confirm-delete>

        <div>
            <pagination :data="plans" @pagination-change-page="getIntRatePlansByDescriptionAndByPrefix" :limit="5">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        
        <a href="" class="d-none" id="export-link" target="_blank"></a>

    </div>
</template>

<script>
import ConfirmDelete from "@/components/layouts/ConfirmDelete";
export default {
    components: { ConfirmDelete},

  
    props: [
        'route_id'
    ],
    created() {
        this.getIntRatePlansByDescriptionAndByPrefix();
    },

    data() {
        return {
            plans: {},
            isConfirmDeleteModalVisible: false,
            plan: "",
            prefix: '', 
            description: '',
        }
    },

    methods: {
        getModel(){
            var link = document.getElementById('export-link');
            link.setAttribute('href', this.$domain + "tarifa/modelo_tarifa.csv");
            link.click();
        },

        getIntRatePlansByDescriptionAndByPrefix(page){ 
               this.$httpApi.post('iplans/searchrateplans/' + this.route_id, { prefix: this.prefix, description: this.description},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
                params: {
                    page: page
                }
            }).then(res => {
                this.plans = res.data.items;
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
            });
        },

        deleteIntRatePlan(id) {
            this.$httpApi.delete('iplans/delete/'+id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getIntRatePlansByDescriptionAndByPrefix()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

          deleteAll() {
            this.$httpApi.delete('iplans/delete/by-group/'+this.route_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getIntRatePlansByDescriptionAndByPrefix()
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        openModalCSV() {
            document.getElementById('modalCSV').style = "display: block;"
        },

        closeModalCSV() {
            document.getElementById('modalCSV').style = "display: none;"
        },

        exportFile(){ 
            this.$httpApi.get('iplans/export/'+this.route_id, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                var link = document.getElementById('export-plan');
                link.setAttribute('href', res.data.items.download);
                link.click();
            }).catch(res => {
                this.getRatePlans();
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        importFile() {
            var inputFile = new FormData(document.getElementById('formFile'));

            this.$httpApi.post('iplans/import/'+this.route_id, inputFile, {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getIntRatePlansByDescriptionAndByPrefix();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ]);
            }).catch(res => {
                this.getRatePlans();
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },

        showConfirmDeleteModal(id) {
            this.plan = id;
            this.isConfirmDeleteModalVisible = true;
        },
        closeConfirmDeleteModal() {
            this.plan = "";
            this.isConfirmDeleteModalVisible = false;
        },
        deleteItem() {
            this.deleteIntRatePlan(this.plan);
            this.closeConfirmDeleteModal();
        },
    }

}
</script>

<style scoped>

.border {
    padding: 10px;
    font-family:'Courier New', Courier, monospace;
}
</style>