<template>
        <div class="d-flex flex-wrap justify-content-start">

            <div class="form-group col-6">
                <label for="input-did">Número *</label>
                <input type="text" name="did" id="input-name" class="form-control" v-model="did.did">
                <p v-for="erro in errors.did" :key="erro" class="text-danger">{{erro}}</p>
            </div>

            <div class="form-group col-6">
                <label for="input-proxy">Operadora *</label>
                <select name="idCarrier" id="input-idCarrier" class="form-control">
                    <option value="">Selecione uma operadora</option>
                    <option v-for="carrier in carriers" :key="carrier.idCarrier" :value="carrier.idCarrier" :selected="carrier.idCarrier == did.idCarrier ? true : false">{{carrier.carrierName}}</option>
                </select>
                <p v-for="erro in errors.idCarrier" :key="erro" class="text-danger">{{erro}}</p>
            </div>
             <div class="form-group col-6">
                <label for="input-reverseCall">Tarifação Reversa? </label>
                <select name="reverseCall" id="input-reverseCall" class="form-control" v-model="did.reverseCall"  >
                    <option value="y">Sim</option>      
                     <option value="n">Não</option>           
                </select>
            </div>
             <div class="form-group col-6" v-if="did.reverseCall == 'y'">
                <label for="input-idTrunk">Selecione um Tronco</label>
                <select name="idTrunk" id="input-idTrunk" class="form-control" required >
                    <option value="">Selecione uma opção</option>      
                  <option v-for="tronco in trunks" :key="tronco.idTrunk" :value="tronco.idTrunk" :selected="tronco.idTrunk == did.idTrunk ? true : false">{{tronco.trunkName}}</option>
                </select>
                    <p v-for="erro in errors.idTrunk" :key="erro" class="text-danger">{{erro}}</p>
            </div>
        </div>

        
</template>

<script>
export default {
    props: [
        'did',
        'errors'
    ],

    created() {
        this.$httpApi.get('operadoras',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
            },
        }).then(res => {
            this.carriers = res.data.items
        }),

        this.getTrunks();
    },

    data() {
        return {
            carriers: Array,
            reverseCall: '',
            trunks: [],
        }
    },

    methods: { 

        getTrunks(){ 
              
            this.$httpApi.get('troncos/list/whitout/softdelete',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.trunks = res.data.items;
                console.log(this.trunks)
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        }

    },
}
</script>

<style>

</style>