<template>
    <div class="card" v-if="$canIf('users.store')">
        <form action="" method="post" v-on:submit.prevent='createUser' id="user-form">
            <div class="card-header">
                <h4>Criar Usuário</h4>
            </div>
            <div class="card-body">
                <users-form
                :errors="errors"
                :user="user"
                ></users-form>
            </div>
            <div class="card-footer">
                <div class="d-flex align-items-center">
                    <input class="btn btn-success mr-2" type="submit" value="Cadastrar">
                    <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import UsuariosForm from './UsuariosForm.vue'
export default {
    components: {
        'users-form': UsuariosForm
    },

    data(){
        return {
            errors: {},
            user: {},
        }
    },

    methods: {
        createUser() {
            var dados = new FormData(document.getElementById('user-form'));

            this.$httpApi.post('users',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Users.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            });
        },
    }
}
</script>

<style>

</style>