<template>
  <div :class="'col-md-'+ largura + ' ' + cor">
    <div class="box">
      <div class="header">
        <h3>{{ titulo }} </h3>
      </div>

      <div class="body">
        <div class="inner">
          <div class="info">      
            <p> {{ quantidade }} </p>
          </div>

          <div class="icon">
            <i :class="icone"></i>
          </div>
          
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "cardicon",
  props: {
    titulo: {
      type: String,
      required: true,
    },
    icone: {
      type: String,
      required: true,
    },
    quantidade: {
      type: String,
      required: true,
    },
    largura:{
      required: true,
    },
    cor: {
      required: true,
    }
  },
  methods: {
  },
};
</script>

<style>
.red .box {
  background: #ca1414;
}

.blue .box {
  background: #361ab1;
}

.yellow .box {
  background: #d6e31f;
}

.box{ 
  margin: 5px;
}


.header {
  display: block;
  margin: 10px;
  /* text-align: center; */
}

.header h3 {
  font-size: 1.5rem;
  white-space: nowrap;
  padding: 0;
  color: #f9f9f9;
}

.body > .inner {
  padding: 5px;
}

.body p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f9f9f9;
}

.body .icon {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: absolute;
  bottom: 2px;
  right: 30px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15);
}
.box:hover {
  text-decoration: none;
  color: #676666;
}
.box:hover .icon {
  font-size: 80px;
}


</style>