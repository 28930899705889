<template>
    <div>
        <div class="d-flex justify-content-between align-items-end">
            <h4>Dids do assinante: {{subscriber.username}} </h4>
            <div class="d-flex">
                <button v-if="$canIf('subscribers.addHaveDid')" class="btn btn-success btn-sm" @click="openModalDid">Vincular Did</button>
                <router-link :to="{ name: 'Subscribers.Index' }" class="btn bg-secondary btn-sm">Voltar para Assinantes</router-link>
            </div>
        </div>
        <br>
        <div class="">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Id</th>
                        <th>Número</th>
                        <th>Descrição </th>                      
                        <th>Encaminhamento</th>
                        <th>Destino</th>                       
                        <th class="acoes">Ações</th>
                    </tr>
                </thead>
                <tbody class="tabela">
                    <template >
                        <tr v-for="res in result" :key="res.idDid">
                            <td data-title="Id">{{ res.idDid }} </td>
                            <td data-title="Número">{{ res.did }} </td>
                            <td data-title="Descrição">{{ res.customerDescription }}</td>
                            <td data-title="Encaminhamento">{{ (res.fwType == 'number' ? 'Número' : 'Conta') }}</td>
                            <td data-title="Destino">{{ (res.fwType == 'account' ? res.subscriberName : res.fwDestination) }} </td>                            
                            <td class="acoes">
                                <button v-if="$canIf('subscribers.editHaveDid')" data-toggle="tooltip" data-placement="top" title="Editar" class="m-1 btn btn-sm bg-info"  @click="openEditDidModal(res.idDid)"><i class="far fa-edit"></i></button>
                                <button v-if="$canIf('subscribers.delHaveDid')"  data-toggle="tooltip" data-placement="top" title="Desvincular" class="m-1 btn btn-sm bg-danger" @click.prevent="removeDid(res.username,res.haveDidId)"><i class="fas fa-minus-circle"></i></button>                         
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div id="modalDid" class="modal-fundo" @click.self="closeModalDid">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="addDid" enctype="multipart/form-data" id="form-did">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Vinculação de Did</h4>
                            <span class="btn-fechar" @click="closeModalDid">x</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="did" class="form-label">Dids disponíveis para o cliente <strong>{{customer.customersName}}</strong> *:</label>
                            <select name="idDid" id="did" class="form-control">
                                <option value="">Selecione um número</option>
                                <template v-for="did in customer.dids">
                                    <option v-if="!idDids.includes(did.idDid)" :key="did.idDid" :value="did.idDid">{{did.did}}</option>
                                </template>
                            </select>
                            <p v-for="erro in errors.idDid" :key="erro" class="text-danger">{{erro}}</p>
                        </div>
                        <div class="form-group">
                                <label for="input-description" class="form-label">Descrição *</label>
                                <input type="text" class="form-control" id="input-description" name="customerDescription">
                                <p v-for="erro in errors.customerDescription" :key="erro" class="text-danger">{{erro}}</p>
                            </div>
                        <div v-if="!$canIf('admin')" class="d-flex justify-content-between">
                            <div class="form-group flex-fill mr-2">
                                <label for="input-type" class="form-label">Tipo de encaminhamento *</label>
                                <select name="fwType" id="input-type" class="form-control">
                                    <option value="">Selecione uma opção</option>
                                    <option value="account">Conta</option>
                                    <option value="number">Número</option>
                                </select>
                                <p v-for="erro in errors.fwType" :key="erro" class="text-danger">{{erro}}</p>
                            </div>
                            <div class="form-group flex-fill ml-2">
                                <label for="input-destination" class="form-label">Destino</label>
                                <input type="text" class="form-control" id="input-destination" name="fwDestination">
                                <p v-for="erro in errors.fwDestination" :key="erro" class="text-danger">{{erro}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <button class="btn bg-success btn-sm">Cadastrar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div id="modalEditDid" class="modal-fundo" @click.self="closeModalEditDid">
            <div class="card modal-conteudo">
                <form action="" method="post" @submit.prevent="updateHaveDid" enctype="multipart/form-data" id="form-did">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between pb-3">
                            <h4 class="m-0">Atualização de dados</h4>
                             <span class="btn-fechar" @click="closeModalEditDid">x</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="input-description" class="form-label">Descrição *</label>
                            <input type="text" class="form-control" id="input-description" name="customerDescription" v-model="customerDescription">
                            <p v-for="erro in errors.customerDescription" :key="erro" class="text-danger">{{erro}}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="form-group flex-fill mr-2">
                                <label for="input-type" class="form-label">Tipo de encaminhamento * <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="top" title="Caso o encaminhamento seja uma conta, deverá ser informado um identificador de chamadas válido no cadastro do assinante."></i></label>
                                <select name="fwType" id="input-type" class="form-control" v-model="fwType" @change="(fwType == 'number' ? fwTypeIsHidden = false : fwTypeIsHidden =  true)">
                                    <option value="">Selecione uma opção</option>
                                    <option value="account" >Conta</option>
                                    <option value="number" >Número</option>
                                </select>
                                    <p v-for="erro in errors.fwType" :key="erro" class="text-danger">{{erro}}</p>
                            </div>
                            <div class="form-group flex-fill ml-2" v-if="fwType == 'number'">
                                <label for="input-destination" class="form-label">Destino</label>
                                <input type="text" class="form-control" id="input-destination" name="fwDestination" v-model="fwDestination" >
                                <p v-for="erro in errors.fwDestination" :key="erro" class="text-danger">{{erro}}</p>
                            </div>
                            <div class="form-group flex-fill ml-2" v-if="fwType == 'account'">
                                <label for="input-destination" class="form-label">Identificador de Chamadas</label>
                                <input type="text" class="form-control" id="input-destination" name="outbound_cid" v-model="outbound_cid" readonly >
                                <p v-for="erro in errors.fwDestination" :key="erro" class="text-danger">{{erro}}</p>
                            </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex justify-content-between">
                            <button class="btn bg-success btn-sm">Atualizar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'customer_id',
        'subscriber_id',
    ],
    created() {
        this.getCustomer();
    },

    data() {
        return {
            errors: {},
            customer: {},
            subscriber: {},
            idDids: [],
            dids: [],
            tipoEncaminhamento: '',
            haveDid: '', 
            customerDescription: '',
            idDid: '', 
            haveDidId: '',  
            fwType: '',
            fwDestination: '', 
            fwTypeIsHidden: true,
            idVinculo: '',
            result: '',
            outbound_cid: ''
        }
    },

    methods: {
        getCustomer() {
            this.$httpApi.get('customers/'+this.customer_id,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.customer = res.data.items;
                this.$httpApi.get('subscribers/'+this.subscriber_id+'/haveDid',{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                    },
                }).then(res => {
                    this.result = res.data.haveDid;
                    this.subscriber = res.data.subscriber;
                    console.log(this.result);
                })
            })
        },      

        updateHaveDid(){ 
            console.log(this.haveDidId)
            if(this.fwType == 'account') { 
                this.fwDestination = '';
            }
            this.$httpApi.put('subscribers/havedid/'+this.haveDidId, {customerDescription: this.customerDescription, fwType: this.fwType, fwDestination: this.fwDestination, idDid: this.haveDid.idDid},{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                }, 
            }).then( res => {
                this.closeModalEditDid();
                this.getCustomer();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                this.haveDid = '';
                this.clearVars();
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ]);
            });
        },
        
        getHaveDid(idDid) {
            this.haveDid = ''; 
            this.$httpApi.get('subscribers/getHaveDidByIdDid/'+ idDid ,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then( res => {
                this.haveDid = res.data.items; 
                console.log(this.haveDid)
                this.haveDidId = this.haveDid.id;
                this.customerDescription = this.haveDid.customerDescription;
                this.fwType = this.haveDid.fwType;
                this.fwDestination = this.haveDid.fwDestination;
                this.idDid = this.haveDidId.idDid;                
                this.outbound_cid = this.haveDid.outbound_cid;
            }).catch(res => {
                this.$store.dispatch('exibeErro', [
                    res.response.message
                ]);
            });
        },

        openEditDidModal(idDid){ 
            this.getHaveDid(idDid);
            document.getElementById('modalEditDid').style = "display: block;"
        }, 

        addDid() {
            var dados = new FormData(document.getElementById('form-did'));

            this.$httpApi.post('subscribers/'+this.subscriber_id+'/addHaveDid',dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomer();
                this.clearDescricao();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
                this.closeModalDid();
                this.clearVars();
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        removeDid(username, haveDidId) {
            console.log(username, haveDidId);
            
            this.$httpApi.delete('subscribers/'+username+'/'+haveDidId+'/delHaveDid',{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.getCustomer();
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                 if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },

        clearVars(){
            this.customerDescription = '';
            this.fwType = '';
             this.fwDestination = '';
              this.haveDid.idDid = '';
        },

        openModalDid() {
            document.getElementById('modalDid').style = "display: block;"
        },

        closeModalDid() {
            document.getElementById('modalDid').style = "display: none;"
        },

        closeModalEditDid() {
            document.getElementById('modalEditDid').style = "display: none;"
        },

        clearDescricao(){ 
            document.getElementById('input-description').value = '';
        },

    }

}
</script>

<style scoped>

.modal-fundo {
    display: none;
    background-color: #0008;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}

.modal-conteudo {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;

    animation-name: modal;
    animation-duration: 700ms;

}

.btn-fechar {
    position: absolute;
    top: 0%;
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 2.1em;
    height: 2.1em;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transform: translate(35%, -35%);
}

.btn-fechar:hover {
    box-shadow: 0 0 1em #0004;
    transition: 200ms;
}

@keyframes modal {
    from {
        top: 30%;
        opacity: 0;
    }
    to {
        top: 20%;
        opacity: 1;
    }
}


</style>