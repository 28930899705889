<template>
    <nav class="navbar navbar-expand-lg bg-brd" id="layout-header">
        <div class="container-fluid">
            <div class="navbar-toggle" id="toggle-sidebard">
                <button @click="toggleSidebar" class="navbar-toggler text-white">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
            <!-- <a class="navbar-brand" href="#">Navbar w/ text</a> -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" @click="toggleNavbar">
                <span class="navbar-toggler-bar navbar-kebab"></span>
                <span class="navbar-toggler-bar navbar-kebab"></span>
                <span class="navbar-toggler-bar navbar-kebab"></span>
            </button>
            <div class="navbar-collapse collapse justify-content-end" id="navbarText">
                <ul class="navbar-nav">
                    <li class="nav-item d-flex align-items-center justify-content-end">
                        <button v-if="$store.state.logado" @click.prevent="LogOut" style="cursor: pointer;" class="m-2 btn btn-danger nav-link">LogOut</button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {

    methods: {
        LogOut: function() {
            // requisição para fazer o logout no sistema
            this.$httpApi.get('auth/logout', {
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(() => {
                // chamada do método logout e mensagem de sucesso
                this.$store.dispatch('logout');
                this.$store.dispatch('criarMensagem',[
				'Você foi desconectado com sucesso',
				'success'
			])
            }).catch(res => {
                // Filtro dos possíveis erros
                switch (res.response.status) {
                    case 401:
                        // Mensagem de falha e chamada do método logout
                        this.$store.dispatch('logout');
                        this.$store.dispatch('criarMensagem',[
                            'A sessão já expirou',
                            'danger'
                        ]);
                        break;

                    default:
                        // Mensagem de falha para erros padrões e chamada do método logout
                        this.$store.dispatch('logout');
                        this.$store.dispatch('criarMensagem',[
                            'Erro '+res.response.status+': Contate um Administrador',
                            'danger'
                        ]);
                        break;
                }
            });
        },
        logado: function() {
            // Método que verifica se o usuário está logado
            if (sessionStorage.getItem('Auth_Token')) {
                return true
            } else {
                return false
            }
        },
        toggleSidebar: function() {
            var html = document.querySelector('html');
            if (html.classList.contains('nav-open')) {
                html.classList.remove('nav-open');
            } else {
                html.classList.add('nav-open');
            }
        },
        toggleNavbar: function() {
            var navbar = document.getElementById('navbarText');
            if (navbar.classList.contains('show')) {
                setTimeout( function() {
                    navbar.classList.remove('border-top');
                    navbar.classList.remove('border-light');
                    navbar.classList.remove('mt-3');
                },200)
            } else {
                navbar.classList.add('border-top');
                navbar.classList.add('border-light');
                navbar.classList.add('mt-3');
            }
        }
    }
}
</script>

<style scoped>

#layout-header {
    height: 6vh;
}

@media all and (max-width: 600px) {
    #toggle-sidebard {
        display: none !important;
    }

    .container-fluid {
        justify-content: end;
    }
}

@media all and (max-width: 991px) {
    #layout-header {
        height: unset;
    }
}

</style>