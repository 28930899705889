var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border",attrs:{"id":"basic-sidebar"}},[_c('div',{staticClass:"sidebar",attrs:{"data-color":"brd"}},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{name: 'Home'}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":this.$domain + 'logo/' + _vm.$store.state.config.logo,"alt":""}})])],1),_c('div',{staticClass:"sidebar-wrapper",attrs:{"id":"sidebar-wrapper"}},[_c('div',{staticClass:"account2"},[_c('p',[_vm._v("Seja Bem Vindo: ")]),_c('h5',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-user"}),_vm._v(" "+_vm._s(_vm.$store.state.user_name))])]),_c('ul',{staticClass:"nav"},[(_vm.$canIf('users.allindex'))?_c('li',{staticClass:"menu-dropdown",attrs:{"id":"menu-admin"}},[_c('a',{staticClass:"btn-dropdown",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown('menu-admin')}}},[_c('i',{staticClass:"fas fa-users design_app"}),_vm._v("Administrador"),_c('i',{staticClass:"fas fa-caret-down float-right"})]),_c('ul',{class:'sub-menu-sidebar '+
                    (_vm.$store.state.rotaAtual.split('.')[0] == 'Admin'
                    ? 'showDropDown' : '')},[_c('li',[_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Admin' ? 'ativo' : '',attrs:{"to":{ name:'Admin.Index' }}},[_c('i',{staticClass:"fas fa-id-card design_app"}),_vm._v("Usuarios")])],1)])]):_vm._e(),(_vm.$canIf('users.index') || _vm.$canIf('roles.index') || _vm.$canIf('config.index'))?_c('li',{staticClass:"menu-dropdown principal",attrs:{"id":"menu-config"}},[_c('a',{staticClass:"btn-dropdown",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown('menu-config')}}},[_c('i',{staticClass:"fas fa-cog design_app"}),_vm._v("Configurações"),_c('i',{staticClass:"fas fa-caret-down float-right"})]),_c('ul',{class:'sub-menu-sidebar '+
                    (_vm.$store.state.rotaAtual.split('.')[0] == 'Users' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Roles' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Config'
                    ? 'showDropDown' : '')},[_c('li',[(_vm.$canIf('users.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Users' ? 'ativo' : '',attrs:{"to":{ name:'Users.Index' }}},[_c('i',{staticClass:"fas fa-id-card design_app"}),_vm._v("Usuarios")]):_vm._e()],1),_c('li',[(_vm.$canIf('roles.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Roles' ? 'ativo' : '',attrs:{"to":{ name:'Roles.Index' }}},[_c('i',{staticClass:"fas fa-user-tag design_app"}),_vm._v("Cargos")]):_vm._e()],1),_c('li',[(_vm.$canIf('config.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Config' ? 'ativo' : '',attrs:{"to":{ name:'Config.Index' }}},[_c('i',{staticClass:"fas fa-cogs design_app"}),_vm._v("Configurações do Sistema")]):_vm._e()],1)])]):_vm._e(),(_vm.$canIf('customers.index') || _vm.$canIf('customergroups.index'))?_c('li',{staticClass:"menu-dropdown principal",attrs:{"id":"menu-clients"}},[_c('a',{staticClass:"btn-dropdown",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown('menu-clients')}}},[_c('i',{staticClass:"fas fa-user"}),_vm._v("Gerenciar Clientes"),_c('i',{staticClass:"fas fa-caret-down float-right"})]),_c('ul',{class:'sub-menu-sidebar '+
                    (_vm.$store.state.rotaAtual.split('.')[0] == 'Customers' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'CustomerGroups' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Subscribers'
                    ? 'showDropDown' : '')},[_c('li',[(_vm.$canIf('customers.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Customers' || _vm.$store.state.rotaAtual.split('.')[0] == 'Subscribers' ? 'ativo' : '',attrs:{"to":{ name:'Customers.Index' }}},[_c('i',{staticClass:"fas fa-user"}),_vm._v("Clientes")]):_vm._e()],1),_c('li',[(_vm.$canIf('customergroups.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'CustomerGroups' ? 'ativo' : '',attrs:{"to":{ name:'CustomerGroups.Index' }}},[_c('i',{staticClass:"fas fa-users design_app"}),_vm._v("Grupos de Clientes")]):_vm._e()],1)])]):_vm._e(),(_vm.$canIf('operadoras.index') || _vm.$canIf('groupcostintl.index') || _vm.$canIf('trunk.index'))?_c('li',{staticClass:"menu-dropdown principal",attrs:{"id":"menu-costs"}},[_c('a',{staticClass:"btn-dropdown",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown('menu-costs')}}},[_c('i',{staticClass:"fas fa-dollar-sign"}),_vm._v("Planos de Custos"),_c('i',{staticClass:"fas fa-caret-down float-right"})]),_c('ul',{class:'sub-menu-sidebar '+
                    (_vm.$store.state.rotaAtual.split('.')[0] == 'Carriers' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Trunks' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'IntTrunks' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'IntCostGroups' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'IntCostCosts' 
                    ? 'showDropDown' : '')},[_c('li',[(_vm.$canIf('operadoras.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Carriers' ? 'ativo' : '',attrs:{"to":{ name:'Carriers.Index' }}},[_c('i',{staticClass:"fas fa-phone design_app"}),_vm._v("Operadoras")]):_vm._e()],1),_c('li',[(_vm.$canIf('groupcostintl.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'IntCostGroups' || _vm.$store.state.rotaAtual.split('.')[0] == 'IntCostCosts' ? 'ativo' : '',attrs:{"to":{ name:'IntCostGroups.Index' }}},[_c('i',{staticClass:"fas fa-receipt"}),_vm._v("Tarifas Internacionais")]):_vm._e()],1),_c('li',[(_vm.$canIf('trunk.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Trunks' ? 'ativo' : '',attrs:{"to":{ name:'Trunks.Index' }}},[_c('i',{staticClass:"fas fa-server"}),_vm._v("Troncos")]):_vm._e()],1)])]):_vm._e(),(_vm.$canIf('nplans.index') || _vm.$canIf('iplans.index'))?_c('li',{staticClass:"menu-dropdown principal",attrs:{"id":"menu-sells"}},[_c('a',{staticClass:"btn-dropdown",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown('menu-sells')}}},[_c('i',{staticClass:"fas fa-hand-holding-usd"}),_vm._v("Planos de Vendas"),_c('i',{staticClass:"fas fa-caret-down float-right"})]),_c('ul',{class:'sub-menu-sidebar '+
                    (_vm.$store.state.rotaAtual.split('.')[0] == 'Routes' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'IntRoutes' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'RatePlans' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'IntRatePlans'
                    ? 'showDropDown' : '')},[_c('li',[(_vm.$canIf('nplans.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'RatePlans' ? 'ativo' : '' || _vm.$store.state.rotaAtual.split('.')[0] == 'Trunks'  ? 'ativo' : '',attrs:{"to":{ name:'RatePlans.Index' }}},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v("Planos nacionais")]):_vm._e()],1),_c('li',[(_vm.$canIf('iplans.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'IntRoutes' || _vm.$store.state.rotaAtual.split('.')[0] == 'IntRatePlans' || _vm.$store.state.rotaAtual.split('.')[0] == 'IntTrunks'  ? 'ativo' : '',attrs:{"to":{ name:'IntRoutes.Index' }}},[_c('i',{staticClass:"fas fa-plane"}),_vm._v("Planos Internacionais")]):_vm._e()],1)])]):_vm._e(),(_vm.$canIf('dids.index') || _vm.$canIf('reports.cdr') || _vm.$canIf('reports.cdrdetails'))?_c('li',{staticClass:"menu-dropdown principal",attrs:{"id":"menu-services"}},[_c('a',{staticClass:"btn-dropdown",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown('menu-services')}}},[_c('i',{staticClass:"fas fa-network-wired"}),_vm._v("Serviços"),_c('i',{staticClass:"fas fa-caret-down float-right"})]),_c('ul',{class:'sub-menu-sidebar '+
                    (_vm.$store.state.rotaAtual.split('.')[0] == 'Dids' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Reports' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Relatorio' ||
                    _vm.$store.state.rotaAtual.split('.')[0] == 'Call'
                    ? 'showDropDown' : '')},[_c('li',[(_vm.$canIf('dids.index'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Dids' ? 'ativo' : '',attrs:{"to":{ name:'Dids.Index' }}},[_c('i',{staticClass:"fas fa-phone design_app"}),_vm._v("Dids")]):_vm._e()],1),_c('li',[(_vm.$canIf('reports.cdr'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Reports' ? 'ativo' : '',attrs:{"to":{ name:'Reports.CDR' }}},[_c('i',{staticClass:"fas fa-list design_app"}),_vm._v("Relatório por Cliente")]):_vm._e()],1),_c('li',[(_vm.$canIf('reports.cdrByOperadora'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Relatorio' ? 'ativo' : '',attrs:{"to":{ name:'Relatorio.CDRByOperadora' }}},[_c('i',{staticClass:"fas fa-list design_app"}),_vm._v("Relatório Por Operadora")]):_vm._e()],1),_c('li',[(_vm.$canIf('services.callSimulation'))?_c('router-link',{class:_vm.$store.state.rotaAtual.split('.')[0] == 'Call' ? 'ativo' : '',attrs:{"to":{ name: 'Call.Simulator' }}},[_c('i',{staticClass:"fas fa-phone-square"}),_vm._v("Simulador de Chamadas")]):_vm._e()],1)])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }