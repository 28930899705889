<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex justify-content-between">
                <h3>Operadoras</h3>
            </div>
        </div>
        <div class="card-body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>