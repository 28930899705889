<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex justify-content-between align-items-start">
                <h3>Clientes</h3>
                <router-link v-if="$store.state.rotaAtual.split('.')[0] != 'Customers'" :to="{ name: 'Customers.Index' }" class="btn bg-secondary btn-sm">Voltar para Clientes</router-link>
            </div>
        </div>
        <div class="card-body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>