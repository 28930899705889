<template>
    <div class="card" v-if="$canIf('roles.create')">
        <form action="" method="post" v-on:submit.prevent='createRole' id="role-form">
            <div class="card-header">
                <h4>Criar Cargo</h4>
            </div>
            <div class="card-body">
                <roles-form
                :errors="errors"
                :role="role"
                ></roles-form>
            </div>
            <div class="card-footer">
                <input class="btn btn-success mr-2" type="submit" value="Cadastrar">
                <a @click="$router.go(-1)" class="btn bg-danger text-white">Voltar</a>
            </div>
        </form>
    </div>
</template>

<script>
import RolesForm from './RolesForm.vue'
export default {
    components: {
        'roles-form': RolesForm
    },

    data(){
        return {
            errors: {},
            role: {},
        }
    },

    methods: {
        createRole() {
            var dados = new FormData(document.getElementById('role-form'));
            
            this.$httpApi.post('role/store', dados,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem('Auth_Token'),
                },
            }).then(res => {
                this.$router.push({ name: 'Roles.Index' })
                this.$store.dispatch('criarMensagem',[
                    res.data.message,
                    'success'
                ])
            }).catch(res => {
                if(res.response.data.errors){
                    this.errors = res.response.data.errors
                }
                this.$store.dispatch('exibeErro', [
                    res.response
                ])
            })
        },
    }
}
</script>

<style>

</style>